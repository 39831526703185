<template>
  <li class="nav-item tab" :class="{ disabled: tab.disabled }">
    <router-link
      v-if="!edition"
      :key="tabNumber"
      :to="
        !tab.disabled
          ? { name: 'TrainingPreRegistration', params: { tab: tab.id } }
          : {}
      "
      class="nav-link tab__link"
      :class="{
        active: current === tabNumber,
        isWaiting: !tab.valid && !tab.progress,
        isValid: tab.valid,
        isProgress: tab.progress,
      }"
    >
      <FontAwesomeIcon
        v-if="tab.icon"
        :icon="'fa-light fa-' + tab.icon"
        class="mb-2"
      />
      <span>{{ tab.label }}</span>
    </router-link>
    <button
      v-else
      type="button"
      class="nav-link tab__link"
      :class="{
        active: current.id === tab.id,
      }"
      @click="goToTab()"
    >
      <div class="d-flex">
        <button type="button" class="tab__grip">
          <FontAwesomeIcon icon="fa-light fa-grip-lines-vertical" />
        </button>
        <button type="button" class="tab__edit" @click="edit()">
          <FontAwesomeIcon icon="fa-light fa-pen" />
        </button>
      </div>
      <FontAwesomeIcon
        v-if="tab.icon"
        :icon="'fa-light fa-' + tab.icon"
        class="mb-2"
      />
      <span :class="{ 'text-decoration-line-through': !tab.visible }">{{
        tab.name
      }}</span>
    </button>
  </li>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "RegistrationTabComponent",
  components: { FontAwesomeIcon },
  props: {
    tab: {
      type: Object,
      required: true,
    },
    index: {
      type: [Number, String],
      required: true,
    },
    edition: {
      type: Boolean,
      required: false,
      default: false,
    },
    editCurrent: {
      type: [Number, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      current: this.edition
        ? this.editCurrent
        : parseInt(this.$route.params.tab),
      tabNumber: null,
    };
  },
  watch: {
    editCurrent() {
      this.current = this.editCurrent;
    },
    $route() {
      if (!this.edition) {
        this.current = parseInt(this.$route.params.tab);
      }
    },
  },
  emits: ["goToTab", "edit"],
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setTabNumber();
    },

    setTabNumber() {
      this.tabNumber = this.edition ? this.index + 1 : this.tab.id;
    },

    goToTab() {
      this.$emit("goToTab", this.tab.id);
    },

    edit() {
      this.$emit("edit", this.tab.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  align-items: center;
  flex: 3;
  position: relative;

  &.disabled {
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__grip,
  &__edit {
    position: absolute;
    background: none;
    border: none;
    cursor: grab;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--secondary-color);

    svg {
      height: 14px;
    }
  }

  &__grip {
    left: 15px;
    right: initial;
  }

  &__edit {
    cursor: pointer;
  }

  &__link {
    width: 100%;
    border: 2px solid #f1f2f3 !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0 5px 10px;
    border-radius: 25px !important;

    &.active {
      border-color: $white !important;

      .tab__edit,
      .tab__grip {
        color: $white !important;
      }
    }

    &.isValid {
      border-color: var(--registration-success) !important;
      color: var(--registration-success) !important;

      .tab__edit,
      .tab__grip {
        color: var(--registration-success) !important;
      }

      &.active {
        background: var(--registration-success) !important;
      }
    }

    &.isProgress {
      border-color: var(--registration-progress) !important;
      color: var(--registration-progress) !important;

      .tab__edit,
      .tab__grip {
        color: var(--registration-progress) !important;
      }

      &.active {
        background: var(--registration-progress) !important;
      }
    }

    &.isWaiting {
      border-color: var(--registration-waiting) !important;
      color: var(--registration-waiting) !important;

      .tab__edit,
      .tab__grip {
        color: var(--registration-waiting) !important;
      }

      &.active {
        background: var(--registration-waiting) !important;
        color: $white !important;
      }
    }
  }

  &__icon {
    margin-left: 10px;
    font-size: 20px;
  }

  &__chevron {
    color: $m-color_4;
    margin-left: 15px;
    font-size: 20px;
  }
}

.separator {
  flex: 0.5;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    border: 1px dashed $m_color_4;
    height: 1px;
    width: 100%;
  }
}
</style>
