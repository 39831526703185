<template>
  <div class="row">
    <div class="mb-4 col-12" v-if="tab.label">
      <div class="summary-list h-100 blockStyle">
        <div class="summery-info">
          <div class="w-summary-details">
            <div class="w-summary-info">
              <h4
                class="fw-bold d-flex justify-content-between align-items-center w-100 mb-0"
              >
                <span>{{ tab.label }}</span>
                <span class="summary-count"
                  >{{ tab.value }}{{ tab.unit ? tab.unit : "" }}</span
                >
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-for="indicator in tab.sub_indicators"
      :key="indicator.label"
      :class="
        'mb-4 ' +
        (indicator.columns
          ? indicator.columns
          : 'col-xl-3 col-lg-4 col-md-3 col-sm-6')
      "
    >
      <div class="summary-list h-100 blockStyle">
        <div class="summery-info">
          <div class="w-summary-details">
            <div class="w-summary-info">
              <h6>
                {{ indicator.label }}<br />
                <span class="summary-count"
                  >{{ indicator.value
                  }}{{ indicator.unit ? indicator.unit : "" }}</span
                >
              </h6>
              <!-- (TEMPORARY SOLUTION) Delete second condition when back will be modified -->
              <button
                v-if="
                  indicator.download ||
                  indicator.label ===
                    'Taux de remplissage questionnaires par promotion et par profil'
                "
                class="btn-icon -white"
                @click="exportStatistic(indicator)"
              >
                <FontAwesomeIcon icon="fa-light fa-download" />
              </button>
              <button
                v-if="indicator.indicators.length"
                class="btn-icon -white ms-2"
                @click="show(indicator)"
              >
                <FontAwesomeIcon icon="fa-light fa-eye" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NumberCardChart
      :stats="tab.indicators"
      v-if="tab.indicators && Object.keys(tab.indicators).length"
    />
  </div>
</template>

<script>
import NumberCardChart from "@/components/charts/NumberCardChart.vue";

export default {
  name: "AdminNumberCardChart",
  components: { NumberCardChart },
  props: {
    tab: {
      type: Object,
      required: true,
    },
  },
  methods: {
    show(indicator) {
      this.$emit("show", indicator);
    },

    exportStatistic(indicator) {
      this.$emit("exportStatistic", indicator);
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-list {
  position: relative;
  padding: 15px;
  border-radius: 6px;

  h6 {
    font-size: 12px !important;
  }

  span {
    font-size: 20px !important;
  }

  h6,
  span {
    color: $white !important;
  }

  .connected {
    margin-top: 10px;
    color: $white;
    font-size: 12px;
    text-align: right;
  }
}
</style>
