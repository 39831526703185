<template>
  <div class="item" v-if="field">
    <span class="fw-bold">{{ $t("registration." + trad) }} : </span>
    <template v-if="type === 'simple'"> {{ field }} {{ unit }} </template>
    <template v-else-if="type === 'gender'">
      {{ field === 1 ? $t("registration.female") : $t("registration.male") }}
    </template>
    <template v-else-if="type === 'date'">
      {{ dateFormatter().format(field, "short") }}
    </template>
    <template v-else-if="type === 'cerfaParam'">
      {{ field?.codeApprenticeship }}
      -
      {{ field?.name }} {{ unit }}
    </template>
    <template v-else-if="type === 'department'">
      {{ field.name }} {{ unit }}
    </template>
    <template v-else-if="type === 'idcc'">
      {{ field.title }} {{ unit }}
    </template>
    <template v-else-if="type === 'trainingCourse'">
      {{ field.startYear }} - {{ field.endYear }}
    </template>
    <template v-else-if="type === 'address'">
      <br />
      {{ field?.address ? field?.address : field?.address1
      }}{{ field?.addressComplement ? " - " + field?.addressComplement : ""
      }}<br />
      {{ field?.addressCity?.postCode ? field?.addressCity?.postCode : "" }}
      {{ field?.addressCity?.name ? field?.addressCity?.name : "" }}
    </template>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "RegistrationListFieldComponent",
  methods: {
    dateFormatter() {
      return dateFormatter;
    },
  },
  components: {},
  props: {
    field: {
      type: [String, Number, Object, null],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    trad: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px dashed $m_color_5;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 13px;

  &:last-child {
    border: none;
  }
}
</style>
