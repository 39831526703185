<template>
  <div class="widget">
    <div class="widget-content">
      <ul class="ps-0 mb-0" id="icon-pills-tab" role="tablist">
        <VueDraggableNext
          class="nav nav-pills tabs"
          :animation="200"
          :delay="200"
          :delay-on-touch-only="true"
          :list="tabs"
          :disabled="!edition"
          handle=".tab__grip"
          @change="sort"
        >
          <template v-for="(tab, index) in tabs" :key="tab.position">
            <div class="separator" v-if="parseInt(index) && !edition">
              <div></div>
            </div>
            <RegistrationTabComponent
              v-if="tab"
              :index="edition ? tab.code : index"
              :tab="tab"
              :edition="edition"
              :editCurrent="current"
              @goToTab="goToTab"
              @edit="edit"
            />
          </template>
        </VueDraggableNext>
      </ul>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import RegistrationTabComponent from "@/components/registration/RegistrationTabComponent.vue";

export default {
  name: "RegistrationTabsComponent",
  components: { RegistrationTabComponent, VueDraggableNext },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    edition: {
      type: Boolean,
      required: false,
      default: false,
    },
    current: {
      type: [Object, Number],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      tabsList: this.tabs,
    };
  },
  emits: ["goToTab", "sort", "edit"],
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {},

    sort(event) {
      this.$emit("sort", { type: "tab", event: event });
    },

    goToTab(code) {
      this.$emit("goToTab", code);
    },

    edit(id) {
      this.$emit("edit", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  justify-content: space-between;
}

.separator {
  flex: 0.5;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    border: 1px dashed $m_color_4;
    height: 1px;
    width: 100%;
  }
}
</style>
