<template>
  <div class="widget" v-if="Object.keys(tutorials).length">
    <div class="widget-heading">
      <h2 class="textStyle">
        {{ $tc("admin.tutorial.title", 2) }}
      </h2>
      <div
        class="d-flex align-items-center"
        v-tooltip="{ title: $t('admin.activate_notification') }"
      >
        <FontAwesomeIcon class="iconStyle fs-5" icon="fa-light fa-bell" />
        <SwitchTypeComponent @update="notification" :model="notifModel" />
      </div>
    </div>
    <div class="widget-content">
      <div class="tutorial">
        <div
          class="tutorial__item"
          v-for="tutorial in tutorials"
          :key="tutorial.id"
        >
          <div class="tutorial__profiles">
            <div
              :class="'badge ' + badgeColor(profile)"
              v-for="profile in tutorial.profiles"
              :key="profile"
            >
              {{ badgeLetter(profile) }}
            </div>
          </div>
          <a
            :target="tutorial.type === 1 ? '_blank' : ''"
            :href="src(tutorial)"
            class="tutorial__name"
          >
            <div>{{ tutorial.name }} ({{ category(tutorial.category) }})</div>
            <div class="btn-icon">
              <FontAwesomeIcon
                :icon="
                  'fa-light fa-' + (tutorial.type === 1 ? 'link' : 'download')
                "
              />
            </div>
          </a>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading"></BlockLoader>
  </div>
</template>

<script>
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { fileManager } from "@/services/file/file-manager";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import { i18n } from "@/i18n";

export default {
  name: "TutorialBlockComponent",
  components: {
    FontAwesomeIcon,
    SwitchTypeComponent,
    BlockLoader,
  },
  data() {
    return {
      loading: false,
      tutorials: [],
      notifModel: {
        vars: {
          name: "notif",
          id: "notif",
          block_prefixes: ["", "switch"],
        },
      },
    };
  },
  computed: {
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.notifModel.vars.value = this.user.tutorialNotifications;
      this.initTutorials();
    },

    initTutorials() {
      adminManager.list("tutorial", false).then((res) => {
        this.tutorials = res;
      });
    },

    badgeColor(profile) {
      switch (profile) {
        case 1:
          return "-apprentice";
        case 2:
          return "-tutor";
        case 3:
          return "-apprenticeMaster";
        case 4:
          return "-studeaManager";
      }
    },

    badgeLetter(profile) {
      switch (profile) {
        case 1:
          return "A";
        case 2:
          return "T";
        case 3:
          return "M";
        case 4:
          return "CL";
      }
    },

    category(profile) {
      switch (profile) {
        case 1:
          return i18n.global.t("admin.tutorial.admin");
        case 2:
          return i18n.global.t("admin.tutorial.studea_manager");
        case 3:
          return i18n.global.t("admin.tutorial.user");
      }
    },

    src(tutorial) {
      if (tutorial.type === 1) {
        return tutorial.url;
      } else {
        return fileManager.getDownloadSrc(tutorial.file);
      }
    },

    notification() {
      adminManager.notification("tutorial", this.user.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tutorial {
  &__item {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;

    &:hover,
    &:focus {
      background: $m_color_1;
    }
  }

  &__profiles {
    display: flex;
    align-items: center;
    min-width: 85px;
    max-width: 85px;
  }

  &__item + &__item {
    border-top: 1px dashed $m_color_5;
  }

  &__name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }
}

.badge {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  font-size: 10px;
  background: $orange;

  &.-apprentice {
    background: $green;
  }

  &.-tutor {
    background: $purple;
  }

  &.-apprenticeMaster {
    background: $red;
  }
}

.badge + .badge {
  margin-left: -8px;
}
</style>
