<template>
  <div class="widget">
    <div
      ref="accordionHead"
      aria-controls="collapse-filters"
      :aria-expanded="open"
      class="widget-heading"
      :class="{ collapsed: !open }"
      data-bs-target="#collapse-filters"
      data-bs-toggle="collapse"
    >
      <h2 class="textStyle">{{ title ? title : $t("page.search") }}</h2>
      <FontAwesomeIcon class="me-2 chevron" icon="fa-light fa-chevron-down" />
    </div>
    <div
      id="collapse-filters"
      ref="accordionContent"
      aria-labelledby="collapse-filters"
      class="widget-content collapse"
      :class="{ show: open }"
    >
      <FormComponent :models="models" :hideSubmit="true" />
      <div class="d-flex align-items-center justify-content-center">
        <SubmitWithLoaderComponent
          @submit="search"
          text="Rechercher"
          :loading="loading"
          :cfaStyle="true"
          itemClass="mx-2"
        />
        <SubmitWithLoaderComponent
          @submit="init"
          text="Réinitialiser"
          :loading="loading"
          :cfaStyle="true"
          itemClass="mx-2"
          color="danger"
        />
      </div>
      <BlockLoader :loading="loading" />
    </div>
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { formValidator } from "@/services/form/form-validator";
import { formManager } from "@/services/form/form-manager";
import SubmitWithLoaderComponent from "@/components/utilities/SubmitWithLoaderComponent.vue";

export default {
  name: "FilterBlockComponent",
  components: {
    SubmitWithLoaderComponent,
    BlockLoader,
    FormComponent,
  },
  props: {
    models: {
      default: () => [],
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      filters: this.models,
    };
  },
  methods: {
    search() {
      this.send();
    },
    init() {
      Object.values(this.filters).forEach(function (filter) {
        filter.vars.value = "";
      });

      this.send(true);
    },
    send(reset = false) {
      const errors = formValidator.validForm(this.models);

      if (!errors.length) {
        const res = formManager.processForm(this.models);
        this.$emit(reset ? "reset" : "submit", res);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}
</style>
