<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10">
        <div class="widget">
          <div class="widget-heading -list">
            <h1 class="textStyle">
              {{ $t("page.training_list") }}
              {{
                isFiltered
                  ? "(" +
                    (maxResult ? maxResult : Object.keys(datas).length) +
                    " résultat" +
                    ((maxResult ? maxResult : Object.keys(datas).length) > 1
                      ? "s"
                      : "") +
                    " filtré" +
                    ((maxResult ? maxResult : Object.keys(datas).length)
                      ? "s"
                      : "") +
                    ")"
                  : ""
              }}
            </h1>
            <div>
              <button
                v-if="isFiltered"
                class="btn-icon me-1 btnReset"
                v-tooltip="{ title: $t('init_search') }"
                type="button"
                @click="resetSearch"
              >
                <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
              </button>
              <button
                class="btn-icon btnOpen"
                v-tooltip="{ title: $t('search_motor') }"
                type="button"
                @click="openSearch"
              >
                <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
              </button>
            </div>
          </div>
          <div class="widget-content">
            <SortTableComponent
              :actions="listActions"
              :apiFilters="true"
              :columns="columns"
              :datas="datas"
              :idKey="7"
              :loading="loading"
              :maxResult="maxResult"
              :isSearchAvailable="true"
              :defaultLimit="10"
              type="front-training"
              ref="sortTable"
              path="studeaManagerLayout"
              @refresh="refresh"
              @openSearch="openSearch"
              @showTriadList="showTriadList"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <ActionsBlock :actions="actions" />
      </div>
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :title="modalTitle"
    :storageKey="sessionStorageKey"
    @search="search"
  />
  <TriadListModalComponent
    :opened="triadListOpen"
    :trainingCourse="trainingCourse"
    @hidden="triadListModalHidden"
  />
</template>

<script>
import { trainingManager } from "@/services/training/training-manager";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { triadManager } from "@/services/triad/triad-manager";
import TriadListModalComponent from "@/components/triad/TriadListModalComponent.vue";

export default {
  name: "TrainingListView",
  components: {
    TriadListModalComponent,
    ModalFormComponent,
    SortTableComponent,
    ActionsBlock,
  },
  data() {
    return {
      trainings: {},
      columns: [],
      filterModels: [],
      modalTitle: "",
      loading: false,
      isFiltered: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      sessionStorageKey: "studea-front-training",
      maxResult: 0,
      triadListOpen: false,
      trainingCourse: {},
      actions: [
        {
          actionType: "button",
          icon: "plus",
          type: "primary",
          action: "test",
          translation: "Créer une formation",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "plus",
          type: "primary",
          action: "test",
          translation: "Créer une formation test",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "upload",
          type: "primary",
          action: "test",
          translation: "Importer",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "download",
          type: "primary",
          action: "test",
          translation: "Exporter",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "download",
          type: "primary",
          action: "test",
          translation: "Export des inscriptions",
          cfaStyle: true,
        },
      ],
      listActions: [
        {
          icon: "pen",
          type: "primary",
          actionType: "router",
          action: "TrainingDashboard",
          translation: "Modifier la formation",
          cfaStyle: true,
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.setParams();
      this.getDatas();
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          sortByField: 0,
          sortOrder: "ASC",
          limit: 50,
          offset: 0,
        };
      }
    },

    search(params) {
      this.loading = true;
      this.$refs.sortTable.activePage = 1;
      this.listParams["offset"] = 0;
      this.listParams["search"] = params;
      this.getDatas();
      this.isFiltered = true;
    },

    refresh(params) {
      this.listParams = params;
      this.init();
    },

    getFilters() {
      this.filterModels = [];
      this.modalTitle = this.$tc("global.search.title", 2, {
        name: this.$tc("admin.training.title", 2).toLowerCase(),
      });
      trainingManager.form("search").then((data) => {
        this.filterModels = data;
      });
    },

    openSearch() {
      this.getFilters();
      this.isSearchOpen = true;
    },

    showTriadList(payload) {
      triadManager.loadTriadList(payload.id, false).then((res) => {
        this.trainingCourse["crews"] = res;
        this.triadListOpen = true;
      });
    },

    triadListModalHidden() {
      this.trainingCourse = {};
      this.triadListOpen = false;
    },

    resetSearch() {
      this.listParams = [];
      this.$refs.sortTable.activePage = 1;
      this.datas = [];
      this.columns = [];
      this.isFiltered = false;
      window.sessionStorage.removeItem(this.sessionStorageKey);
      this.init();
    },

    getDatas() {
      if (window.sessionStorage.getItem(this.sessionStorageKey)) {
        this.listParams["search"] = JSON.parse(
          window.sessionStorage.getItem(this.sessionStorageKey),
        );
        this.isFiltered = true;
      }
      if (this.isFiltered) {
        this.listParams["search"] = formManager.getQuery(
          this.listParams["search"],
        );
      }
      trainingManager.list(this.listParams).then((data) => {
        this.columns = sortableTableManager.getEntriesColumns(
          data.columns,
          this.columns,
        );
        if (this.columns && this.columns[5]) {
          this.columns[5].sortable = false;
        }
        this.datas = data.data;
        this.maxResult = data.count;
        this.loading = false;
        this.setAppLoading(false);
      });
    },

    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__dot {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: auto;
  }

  &__col {
    display: flex;
    align-items: center;
  }

  &__head {
    padding: 0 1rem;
    color: var(--primary-color);
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__link {
    font-weight: 500;
    text-decoration: underline;
    color: var(--primary-color);
    display: block;
  }
}
</style>
