<template>
  <input
    :type="showPassword ? 'text' : 'password'"
    v-model="passwordModel"
    :placeholder="
      itemModel ? itemModel?.vars?.attr?.placeholder : $t('password')
    "
    :class="inputClass"
    @input="change"
  />
  <span
    class="handle__visibility"
    :class="{ model: !!model }"
    @click="handleShowPassword"
  >
    <FontAwesomeIcon
      :icon="'fa-light fa-' + (showPassword ? 'eye-slash' : 'eye')"
      class="password-icon"
    />
  </span>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PasswordTypeComponent",
  data() {
    return {
      showPassword: false,
      passwordModel: "",
      itemModel: this.model,
      inputClass: "form-control",
    };
  },
  watch: {
    "itemModel.vars.error"() {
      if (this.itemModel.vars.error) {
        this.inputClass = "form-control is-invalid";
      } else {
        this.inputClass = "form-control is-valid";
      }
    },
  },
  props: {
    password: {
      type: String,
      required: true,
    },
    model: {
      type: [Object, null],
      required: false,
      default: null,
    },
    itemClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["checkInput", "changePassword"],
  methods: {
    change() {
      if (this.itemModel) {
        this.itemModel.vars.value = this.passwordModel;
      }
      this.$emit("changePassword", this.passwordModel);
      this.$emit("checkInput");
    },

    handleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
  components: { FontAwesomeIcon },
};
</script>

<style lang="scss" scoped>
.password-icon {
  cursor: pointer;
  position: absolute;
  top: 25px !important;
  left: unset !important;
  right: 10px;
}

.handle__visibility {
  &.model {
    svg {
      top: 50% !important;
      transform: translateY(-50%);
    }
  }
}

input.is-invalid,
input.is-valid {
  background-position: right calc(1.7em + 0.1875rem) center;
}
</style>
