import { i18n } from "@/i18n";
import { formManager } from "@/services/form/form-manager";
import { tabsManager } from "@/services/utilities/tabs-manager";

const EmailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const FloatRegex = /^[+-]?([0-9]*[.|,])?[0-9]+$/;
const PasswordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z\d]).{12,}$/;

class FormValidator {
  validEmail(email) {
    return EmailRegex.test(email);
  }

  validNumberOrFloat(value) {
    return FloatRegex.test(value);
  }

  validPassword(value) {
    return PasswordRegex.test(value);
  }

  validCheckbox(content) {
    let valid = false;
    Object.values(content).forEach((value) => {
      if (value.choices) {
        Object.values(value.choices).forEach((choice) => {
          if (choice.checked && !valid) {
            valid = true;
          }
        });
      } else {
        if (value.checked && !valid) {
          valid = true;
        }
      }
    });
    return valid;
  }

  isVisible(model) {
    const el = document.getElementById(model.vars.id);
    if (el) {
      if (el.offsetHeight || el.offsetHeight || el.getClientRects().length) {
        model.vars.ignoreValidation = false;
      }
    }
  }

  validForm(models, object = false, scrollAnimation = true) {
    const $this = this;
    let errors = [];
    Object.values(models).forEach(function (model) {
      $this.isVisible(model);
      if (model.vars.required && !model.vars.ignoreValidation) {
        errors = errors.concat($this.validModel(model));
      }
    });

    if (scrollAnimation) {
      this.focusErrorInput(errors);
    }
    if (object) {
      return {
        errors: errors,
        models: models,
      };
    }
    return errors;
  }

  validModel(model, language = "") {
    const errorMessage = i18n.global.t("requiredField");
    let error = "";
    let errors = [];

    let content = model.translatable
      ? model.vars.value[language]
      : model.vars.value;

    if (
      model.vars.row &&
      model.vars.required &&
      typeof model.vars.value === "object"
    ) {
      const errorArray = this.validClusterRow(model, errorMessage);
      if (errorArray.length) {
        error = errorArray;
      } else {
        delete model.vars.error;
      }
    } else if (
      model.vars.required &&
      (formManager.isTextType(model) ||
        formManager.isTextareaType(model) ||
        formManager.isPhoneType(model) ||
        formManager.isEditorType(model)) &&
      (!content || !content.length)
    ) {
      error = errorMessage;
    } else if (
      model.vars.required &&
      formManager.isRadioType(model) &&
      ((!content && content !== 0) || !content.toString().length)
    ) {
      error = errorMessage;
    } else if (
      model.vars.required &&
      formManager.isDateType(model) &&
      (!content || (content && !content.length))
    ) {
      error = errorMessage;
    } else if (formManager.isNumberType(model)) {
      if (model.vars.required && (content === "" || content === null)) {
        error = errorMessage;
      } else if (content && !this.validNumberOrFloat(content)) {
        error = i18n.global.t("requiredNumberOfFloat");
      } else {
        delete model.vars.error;
      }
    } else if (
      model.vars.required &&
      formManager.isFileType(model) &&
      (!content || (content && !("id" in content)))
    ) {
      error = errorMessage;
    } else if (
      model.vars.required &&
      formManager.isCheckboxType(model) &&
      !this.validCheckbox(content)
    ) {
      error = errorMessage;
    } else if (
      model.vars.required &&
      formManager.isSelectType(model) &&
      (!content || content === "")
    ) {
      error = errorMessage;
    } else if (
      formManager.isMultiple(model) &&
      formManager.isSelectType(model)
    ) {
      if (!Object.keys(content).length && model.vars.required) {
        error = errorMessage;
      } else if (
        model.vars.attr &&
        model.vars.attr["data-min-tag"] &&
        Object.keys(content).length &&
        Object.keys(content).length < model.vars.attr["data-min-tag"]
      ) {
        error =
          i18n.global.t("minTagError") +
          " (" +
          model.vars.attr["data-min-tag"] +
          ")";
      } else if (
        model.vars.attr &&
        model.vars.attr["data-max-tag"] &&
        Object.keys(content).length &&
        Object.keys(content).length > model.vars.attr["data-max-tag"]
      ) {
        error =
          i18n.global.t("maxTagError") +
          " (" +
          model.vars.attr["data-max-tag"] +
          ")";
      } else {
        delete model.vars.error;
      }
    } else if (formManager.isEmailType(model)) {
      if (model.vars.required && !content.length) {
        error = errorMessage;
      } else if (content && content.length && !this.validEmail(content)) {
        error = i18n.global.t("emailError");
      } else {
        delete model.vars.error;
      }
    } else if (formManager.isPasswordType(model)) {
      if (model.vars.required && !content.length) {
        error = errorMessage;
      } else if (
        content &&
        content.length &&
        model?.vars?.attr?.check &&
        !this.validPassword(content)
      ) {
        error = i18n.global.t("password_not_valid");
      } else {
        delete model.vars.error;
      }
    } else if (formManager.isPhoneType(model) && model.vars.error) {
      error = i18n.global.t("phoneError");
    } else {
      if (model.translatable && model.vars.error) {
        delete model.vars.error[language];
      } else {
        delete model.vars.error;
      }
    }

    let rowIsValid = true;
    if (model.vars.row && error && typeof error === "object" && error.length) {
      Object.values(error).forEach((item) => {
        if (rowIsValid && item !== null) {
          rowIsValid = false;
        }
      });
    }
    if (error) {
      if (model.translatable) {
        model["vars"]["error"][language] = error;
      } else {
        model["vars"]["error"] = error;
      }
      if (!model.vars.row || (model.vars.row && !rowIsValid)) {
        errors.push(model.vars.id);
      }
    }
    return errors;
  }

  validClusterRow(model, errorMessage) {
    let rowErrors = [];
    let index = 0;
    let answered = 0;
    Object.values(model.vars.value).forEach((value) => {
      if (!value.length) {
        rowErrors[index] = errorMessage;
      } else {
        rowErrors[index] = null;
        answered++;
      }
      index++;
    });

    if (formManager.isRadioType(model) && answered) {
      rowErrors = [];
    }
    return rowErrors;
  }

  validRepeatedPassword(password, repeatedPassword, errors) {
    if (
      password &&
      repeatedPassword &&
      password?.vars?.value !== repeatedPassword?.vars?.value
    ) {
      repeatedPassword.vars.error = i18n.global.t("second_password_not_valid");
      errors.push(repeatedPassword.vars.id);
    }
    return errors;
  }

  processResponses(responses, models) {
    let errors = [];
    if (responses.length) {
      responses.forEach((error) => {
        const field = Object.values(models).find(
          (item) => item.vars.name === error.field,
        );
        if (field) {
          field.vars.error = error.message;
          errors = errors.concat(field.vars.id);
        }
      });
    }
    this.focusErrorInput(errors);
    return errors;
  }

  focusErrorInput(errors) {
    if (errors.length) {
      const invalidInput = document.getElementById(errors[0]);
      if (invalidInput) {
        const offset = window.innerWidth < 768 ? 300 : 250;
        window.scrollTo({
          behavior: "smooth",
          top:
            invalidInput.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        });
      }
    }
  }

  validTabs(tabs) {
    let errors = [];
    let valid = false;
    let invalidTab = {};
    Object.values(tabs).forEach(function (tab) {
      if (!errors.length && !tab.hidden && !tab.disabled) {
        errors = errors.concat(formValidator.validForm(tab.models));
        if (errors.length) {
          invalidTab = tab;
        }
      }
    });

    if (Object.keys(invalidTab).length) {
      tabsManager.displayTab(invalidTab);
    } else {
      valid = true;
    }

    return valid;
  }
}

export const formValidator = new FormValidator();
