<template>
  <div class="contract">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-3">
              <ContractContactBlockComponent :registration="registration" />
            </div>

            <div class="col-lg-9">
              <div class="row">
                <div class="col-lg-6">
                  <ContractApprenticeBlockComponent
                    :registration="registration"
                  />
                </div>

                <div class="col-lg-6">
                  <ContractCompanyBlockComponent :registration="registration" />

                  <ContractTrainingBlockComponent
                    :registration="registration"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <ActionsBlock :actions="actions" />
          <RegistrationStepsComponent :steps="steps" />
          <ContractCommentBlockComponent />
          <ContractHistoryBlockComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContractContactBlockComponent from "@/components/registration/contract/ContractContactBlockComponent.vue";
import ContractApprenticeBlockComponent from "@/components/registration/contract/ContractApprenticeBlockComponent.vue";
import ContractCompanyBlockComponent from "@/components/registration/contract/ContractCompanyBlockComponent.vue";
import ContractTrainingBlockComponent from "@/components/registration/contract/ContractTrainingBlockComponent.vue";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import ContractHistoryBlockComponent from "@/components/registration/contract/ContractHistoryBlockComponent.vue";
import ContractCommentBlockComponent from "@/components/registration/contract/ContractCommentBlockComponent.vue";
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";
import { registrationManager } from "@/services/registration/registration-manager";
import RegistrationStepsComponent from "@/components/registration/RegistrationStepsComponent.vue";

export default {
  name: "ContractSheetView",
  components: {
    RegistrationStepsComponent,
    ContractCommentBlockComponent,
    ContractHistoryBlockComponent,
    ActionsBlock,
    ContractTrainingBlockComponent,
    ContractCompanyBlockComponent,
    ContractApprenticeBlockComponent,
    ContractContactBlockComponent,
  },
  data() {
    return {
      registration: {},
      steps: [
        {
          name: "Validation de l'inscription",
          validatedAt: "2024-01-15",
          validatedBy: "michel@effetb.com",
          valid: true,
          icon: "file-pen",
        },
        {
          name: "Cerfa",
          validatedAt: "2024-01-15",
          validatedBy: "michel@effetb.com",
          valid: true,
          icon: "calendar",
        },
        {
          name: "Convention",
          validatedAt: "2024-01-15",
          validatedBy: "michel@effetb.com",
          valid: true,
          icon: "file-pen",
        },
        {
          name: "Prise en charge",
          validatedAt: "2024-01-15",
          validatedBy: "michel@effetb.com",
          icon: "file-arrow-down",
        },
        {
          name: "Avenant code 31",
          validatedAt: "2024-01-15",
          validatedBy: "michel@effetb.com",
          progress: true,
          icon: "calendar",
        },
        {
          name: "Rupture",
          validatedAt: "2024-01-15",
          validatedBy: "michel@effetb.com",
          error: true,
          icon: "medal",
        },
      ],
      actions: [
        {
          actionType: "button",
          icon: "check ",
          type: "primary",
          action: "registrationValidation",
          translation: "Valider l'inscription",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "triangle-exclamation",
          type: "warning",
          action: "declareEvent",
          translation: "Déclarer un évènement",
          cfaStyle: false,
        },
        {
          actionType: "button",
          icon: "upload",
          type: "danger",
          action: "cancel",
          translation: "Annuler",
          cfaStyle: false,
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setAppLoading(false);
      this.getRegistration();
    },

    getRegistration() {
      registrationManager.getRegistration(this.$route.params.id).then((res) => {
        this.registration = res;
      });
    },

    ...mapActions(useMainStore, {
      setAppLoading: "setAppLoading",
    }),
  },
};
</script>

<style lang="scss" scoped>
.history {
  font-size: 13px;
}
</style>
