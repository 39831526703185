<template>
  <div class="row">
    <div class="col-lg-10 order-lg-0 order-1">
      <div class="list">
        <div v-for="data in filteredDatas" :key="data.id">
          <div class="widget">
            <div class="widget-heading -institution">
              <h2 class="textStyle d-flex align-items-center">
                {{ data.name }}{{ data.code ? " - " + data.code : "" }}
                <span
                  v-if="data.archived"
                  class="badge bg-danger ms-2 text-capitalize"
                  >{{ $t("male_archived") }}</span
                >
              </h2>
              <div class="list__actions">
                <button
                  v-tooltip="{
                    title: $tc('global.export.title', 1, {
                      name: this.$tc(
                        'admin.institution.title',
                        2,
                      ).toLowerCase(),
                    }),
                  }"
                  class="list__button exportInstitutionBtn"
                  @click="exportInstitution(data.id)"
                >
                  <FontAwesomeIcon icon="fa-light fa-download" />
                </button>
                <router-link
                  :to="{
                    name: 'adminEdit',
                    params: {
                      id: data.id,
                      alias: 'institution',
                    },
                    query: { backPath: 'adminInstitutions' },
                  }"
                  v-tooltip="{
                    title: $tc('global.edit.title', 1, {
                      name: this.$tc(
                        'admin.institution.title',
                        1,
                      ).toLowerCase(),
                    }),
                  }"
                  v-access:[permissions]="accessManager().EDIT"
                  class="list__button"
                >
                  <FontAwesomeIcon icon="fa-light fa-edit" />
                </router-link>
                <router-link
                  :to="{
                    name: 'adminAdd',
                    params: { alias: 'component' },
                    query: {
                      backPath: 'adminInstitutions',
                      set: JSON.stringify([
                        {
                          name: 'institutionId',
                          value: data.id,
                        },
                      ]),
                    },
                  }"
                  v-access:[permissions]="{ access: accessManager().EDIT }"
                  class="list__button"
                  v-tooltip="{
                    title: $tc('global.add.title', 2, {
                      name: this.$tc('admin.component.title', 1).toLowerCase(),
                    }),
                  }"
                >
                  <FontAwesomeIcon icon="fa-light fa-circle-plus" />
                </router-link>
                <button
                  v-tooltip="{
                    title: $tc('global.delete.title', 1, {
                      name: this.$tc(
                        'admin.institution.title',
                        1,
                      ).toLowerCase(),
                    }),
                  }"
                  v-access:[permissions]="accessManager().DELETE"
                  class="list__button removeInstitutionBtn"
                  @click="remove(data.id, 'institution', 1)"
                >
                  <FontAwesomeIcon icon="fa-light fa-trash" />
                </button>
              </div>
            </div>
            <div class="widget-content">
              <div
                v-for="component in data.components"
                :key="component.id"
                class="list__component"
              >
                <div class="list__title">{{ component.name }}</div>
                <div class="list__action">
                  <button
                    v-tooltip="{
                      title: $tc('global.export.title', 2, {
                        name: this.$tc(
                          'admin.component.title',
                          2,
                        ).toLowerCase(),
                      }),
                    }"
                    class="list__button -component exportComponentBtn"
                    @click="exportComponent(component.id)"
                  >
                    <FontAwesomeIcon icon="fa-light fa-download" />
                  </button>
                  <router-link
                    :to="{
                      name: 'adminEdit',
                      params: {
                        id: component.id,
                        alias: 'component',
                      },
                      query: { backPath: 'adminInstitutions' },
                    }"
                    v-tooltip="{
                      title: $tc('global.edit.title', 2, {
                        name: this.$tc(
                          'admin.component.title',
                          1,
                        ).toLowerCase(),
                      }),
                    }"
                    v-access:[permissions]="{ access: accessManager().EDIT }"
                    class="list__button -component"
                  >
                    <FontAwesomeIcon icon="fa-light fa-edit" />
                  </router-link>
                  <button
                    v-tooltip="{
                      title: $tc('global.delete.title', 2, {
                        name: this.$tc(
                          'admin.component.title',
                          1,
                        ).toLowerCase(),
                      }),
                    }"
                    v-access:[permissions]="{ access: accessManager().DELETE }"
                    class="list__button -component removeComponentBtn"
                    @click="remove(component.id, 'component', 2)"
                  >
                    <FontAwesomeIcon icon="fa-light fa-trash" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 accordion order-lg-1 order-0" id="accordionAction">
      <div class="sticky">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingAction">
            <button
              :disabled="windowWidth > 991"
              :class="
                'accordion-button ' + (windowWidth < 991 ? 'collapsed' : '')
              "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseAction"
              aria-expanded="true"
              aria-controls="collapseAction"
            >
              {{ $t("actions") }}
            </button>
          </h2>
          <div
            id="collapseAction"
            :class="
              'accordion-collapse collapse ' + (windowWidth > 991 ? 'show' : '')
            "
            aria-labelledby="headingAction"
            data-bs-parent="#accordionAction"
          >
            <div class="accordion-body">
              <router-link
                v-tooltip="{
                  title: $tc('global.add.title', 1, {
                    name: this.$tc('admin.institution.title', 1).toLowerCase(),
                  }),
                }"
                :to="{
                  name: 'adminAdd',
                  params: {
                    alias: 'institution',
                  },
                  query: {
                    backPath: 'adminInstitutions',
                  },
                }"
                v-access:[permissions]="accessManager().EDIT"
                class="btn btn-primary mb-2 me-2 btnStyle w-100"
              >
                <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
                {{
                  $tc("global.add.title", 1, {
                    name: this.$tc("admin.institution.title", 1).toLowerCase(),
                  })
                }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="accordion-item mt-3">
          <h2 class="accordion-header" id="filterAction">
            <button
              :disabled="windowWidth > 991"
              :class="
                'accordion-button ' + (windowWidth < 991 ? 'collapsed' : '')
              "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseAction"
              aria-expanded="true"
              aria-controls="collapseAction"
            >
              {{ $t("filter") }}
            </button>
          </h2>
          <div
            id="collapseAction"
            :class="
              'accordion-collapse collapse ' + (windowWidth > 991 ? 'show' : '')
            "
            aria-labelledby="filterAction"
            data-bs-parent="#accordionAction"
          >
            <div class="accordion-body">
              <ModelComponent :model="archivedModel" @update="update" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { accessManager } from "@/services/security/access-manager";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import ModelComponent from "@/components/form/ModelComponent.vue";
import { stringManager } from "@/services/utilities/string-manager";

export default {
  name: "InstitutionsListView",
  components: { ModelComponent, BlockLoader },
  data() {
    return {
      loading: false,
      datas: [],
      windowWidth: window.innerWidth,
      filteredDatas: [],
      archivedModel: {
        vars: {
          block_prefixes: ["", "choice"],
          expanded: true,
          multiple: false,
          id: "archived",
          label: stringManager.capitalize(this.$t("male_archived")),
          name: "archived",
          value: "no",
          choices: [
            {
              label: this.$t("All"),
              data: "all",
              value: "all",
            },
            {
              label: this.$t("yes"),
              data: "yes",
              value: "yes",
            },
            {
              label: this.$t("no"),
              data: "no",
              value: "no",
            },
          ],
        },
      },
    };
  },
  watch: {
    windowWidth() {
      this.updateWindowWidth();
    },
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    remove(id, type, gender = 1) {
      adminManager.remove(type, id, gender).then(() => {
        this.init();
      });
    },

    init() {
      this.loading = true;
      this.retrieveFilters();
      this.getDatas();
    },

    getDatas() {
      adminManager.list("institution").then((data) => {
        this.datas = data;
        this.filter();
        this.loading = false;
      });
    },

    update(value) {
      sessionStorage.setItem("studea-admin-institution", value);
      this.filter();
    },

    filter() {
      let archived = this.archivedModel.vars.value;
      if (archived === "all") {
        this.filteredDatas = this.datas;
      } else {
        this.filteredDatas = this.datas.filter(
          (institution) => institution.archived === (archived === "yes"),
        );
      }
    },

    retrieveFilters() {
      let archived = this.archivedModel.vars.value;
      if (sessionStorage.getItem("studea-admin-institution")) {
        archived = sessionStorage.getItem("studea-admin-institution");
        this.archivedModel.vars.value = archived;
      }
    },

    exportInstitution(id) {
      console.log("Export instition " + id);
    },

    exportComponent(id) {
      console.log("Export component " + id);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__component {
    background: $admin-color;
    color: $white;
    padding: 6px 20px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include up-to-md {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  &__component + &__component {
    margin-top: 5px;
  }

  &__title {
    @include up-to-md {
      width: 100%;
      text-align: left;
    }
  }

  &__button {
    background: none;
    border: none;
    color: $admin-color;
    font-size: 16px;
    position: relative;

    &.-component {
      color: $white;
    }
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__actions {
    min-width: 55px;
    text-align: right;
    @include mobile {
      margin-top: 10px;
    }
  }
}

.-institution {
  @include mobile {
    display: block;
  }
}
</style>
