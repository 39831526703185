export default {
  registration: {
    name: "Name",
    institution: "Institution",
    component: "Component",
    trainingCourse: "Promotion",
    group: "Group",
    pedagogicContact: "Pedagogical contact",
    pedagogicInformations: "Educational information",
    fundingInformations: "Financial information",
    dates: "Dates",
    from_to: "from {from} to {to}",
    hourlyVolume: "Hourly volume",
    degreePrepared: "Degree prepared",
    level: "Level",
    titleRncp: "RNCP title",
    degreeCode: "Degree code",
    calendar: "Calendar",
    program: "Program",
    apprenticeShipContract: "Apprenticeship contract",
    proContract: "Professionalization contract",
    rate: "Rate",
    publicAnnualRate: "Annual cost for public-sector employers",
    hourlyRate: "Hourly rate",
    followUp: "Follow-up",
    devices: "Devices",
    createdAt: "created at {date}",
    help: "Help",
    follow: "Follow",
    inProgress: "In progress",
    validatedAt: "Validated at {date} by {validator}",
    steps: "Steps",
    statistics: "Statistics",
  },
};
