<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">Formation {{ training.name }}</h2>
    </div>
    <div class="widget-content position-relative">
      <div class="panel-body simple-pills tabs">
        <ul id="trainingTab" class="nav nav-pills" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              id="tab-info-tab"
              ref="ref-info"
              aria-controls="tab-info"
              :aria-selected="currentTabCode && currentTabCode === 'info'"
              :class="
                'nav-link mb-2 text-center bgStyle h-100' +
                (currentTabCode && currentTabCode === 'info' ? ' active' : '')
              "
              href="#tab-info"
              data-bs-toggle="pill"
              role="tab"
              @click="toggleTab('info')"
            >
              Informations générales
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              id="tab-rncp-tab"
              ref="ref-rncp"
              aria-controls="tab-rncp"
              :aria-selected="currentTabCode && currentTabCode === 'rncp'"
              :class="
                'nav-link mb-2 text-center bgStyle h-100' +
                (currentTabCode && currentTabCode === 'rncp' ? ' active' : '')
              "
              href="#tab-rncp"
              data-bs-toggle="pill"
              role="tab"
              @click="toggleTab('rncp')"
            >
              Fiche RNCP
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              id="tab-settings-tab"
              ref="ref-settings"
              aria-controls="tab-settings"
              :aria-selected="currentTabCode && currentTabCode === 'settings'"
              :class="
                'nav-link mb-2 text-center bgStyle h-100' +
                (currentTabCode && currentTabCode === 'settings'
                  ? ' active'
                  : '')
              "
              href="#tab-settings"
              data-bs-toggle="pill"
              role="tab"
              @click="toggleTab('settings')"
            >
              Paramétrage Inscription
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              id="tab-npec-tab"
              ref="ref-npec"
              aria-controls="tab-npec"
              :aria-selected="currentTabCode && currentTabCode === 'npec'"
              :class="
                'nav-link mb-2 text-center bgStyle h-100' +
                (currentTabCode && currentTabCode === 'npec' ? ' active' : '')
              "
              href="#tab-npec"
              data-bs-toggle="pill"
              role="tab"
              @click="toggleTab('npec')"
            >
              NPEC
            </a>
          </li>
        </ul>
        <div id="trainingTabContent" class="tab-content">
          <div
            id="tab-info"
            aria-labelledby="tab-info-tab"
            :class="
              'tab-pane fade' +
              (currentTabCode && currentTabCode === 'info'
                ? ' show active'
                : '')
            "
            role="tabpanel"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="tabs__row">
                  <div class="field">Nom de l'établissement</div>
                  <div class="text">
                    {{ training.institution ? training.institution.name : "" }}
                  </div>
                </div>
                <div class="tabs__row">
                  <div class="field">Référence</div>
                  <div class="text">{{ training.reference }}</div>
                </div>
                <div class="tabs__row">
                  <div class="field">Numéro RNCP</div>
                  <div class="text">
                    {{ training.titleRncp ? training.titleRncp.name : "" }}
                  </div>
                </div>
                <div class="tabs__row">
                  <div class="field">Nom officiel</div>
                  <div class="text">
                    {{ training.name }}
                  </div>
                </div>
                <div class="tabs__row">
                  <div class="field">Type de diplôme</div>
                  <div class="text">
                    {{ training.degree ? training.degree.shortName : "" }}
                  </div>
                </div>
                <div class="tabs__row">
                  <div class="field">Nom/mention</div>
                  <div class="text">
                    {{ training.name }}
                  </div>
                </div>
                <div class="tabs__row">
                  <div class="field">Parcours</div>
                  <div class="text"></div>
                </div>
                <div class="tabs__row">
                  <div class="field">Code diplôme</div>
                  <div class="text"></div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="tabs__row">
                  <div class="field">Statut</div>
                  <div class="text">
                    <span
                      class="badge"
                      :class="{
                        'bg-success': !training.archive,
                        'bg-danger': training.archive,
                      }"
                      >{{ training.archive ? "Archivé" : "Active" }}</span
                    >
                  </div>
                </div>
                <div class="tabs__row">
                  <div class="field">Logo</div>
                  <div class="text">
                    <img :src="logo" v-if="logo" :alt="training.name" />
                  </div>
                </div>
                <div class="tabs__row">
                  <div class="field">URL du site partenaire</div>
                  <div class="text">
                    <a target="_blank" href="#">Lien</a>
                  </div>
                </div>
                <div class="tabs__row">
                  <div class="field">Durée en alternance</div>
                  <div class="text">{{ training.duration }} mois</div>
                </div>
                <div class="tabs__row">
                  <div class="field">Durée total du cycle</div>
                  <div class="text">{{ training.duration }} mois</div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="tab-rncp"
            aria-labelledby="tab-rncp-tab"
            :class="
              'tab-pane fade' +
              (currentTabCode && currentTabCode === 'rncp'
                ? ' show active'
                : '')
            "
            role="tabpanel"
          ></div>

          <div
            id="tab-settings"
            aria-labelledby="tab-settings-tab"
            :class="
              'tab-pane fade' +
              (currentTabCode && currentTabCode === 'settings'
                ? ' show active'
                : '')
            "
            role="tabpanel"
          ></div>

          <div
            id="tab-npec"
            aria-labelledby="tab-npec-tab"
            :class="
              'tab-pane fade' +
              (currentTabCode && currentTabCode === 'npec'
                ? ' show active'
                : '')
            "
            role="tabpanel"
          ></div>
        </div>
      </div>
      <BlockLoader :loading="loading" />
    </div>
  </div>
</template>

<script>
import BlockLoader from "@/components/blocks/BlockLoader.vue";

export default {
  name: "TrainingInfosComponent",
  props: {
    training: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    logo: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      currentTabCode: "info",
    };
  },
  mounted() {},
  computed: {},
  methods: {
    toggleTab(code) {
      this.setCurrentTabCode(code);
    },

    setCurrentTabCode(code) {
      this.currentTabCode = code;
      window.location.hash = this.currentTabCode;
    },
  },
  components: { BlockLoader },
};
</script>

<style lang="scss" scoped>
.tab-content {
  .tabs {
    &__row {
      padding: 8px 0;
      display: flex;
      font-size: 14px;
      align-items: center;
      min-height: 40px;

      .field {
        width: 35%;
        font-weight: 600;
      }

      .text {
        width: 65%;
      }

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    &__triads {
      margin-top: 1rem;
      border: 1px dashed $m-color_6;
      border-radius: $radius;
      padding: 1rem;
      max-height: 400px;
      overflow: auto;
      height: 100%;

      .triad {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
      }

      .triad + .triad {
        border-top: 1px dashed $m-color_6;
      }
    }
  }

  .tabs__row + .tabs__row {
    border-top: 1px dashed $m-color_6;
  }
}
</style>
