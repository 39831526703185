<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">{{ $t("registration.company") }}</h2>
    </div>
    <div class="widget-content position-relative">
      <div class="panel-body simple-pills tabs">
        <ul
          id="companyTab"
          class="nav nav-pills"
          role="tablist"
          v-if="registration?.hostCompany && registration?.administratorCompany"
        >
          <li class="nav-item" role="presentation">
            <a
              id="tab-host-company-tab"
              ref="ref-host-company"
              aria-controls="tab-host-company"
              :aria-selected="true"
              class="nav-link mb-2 text-center bgStyle h-100 active"
              href="#tab-host-company"
              data-bs-toggle="pill"
              role="tab"
            >
              {{ $t("registration.hostCompany") }}
            </a>
          </li>

          <li class="nav-item" role="presentation">
            <a
              id="tab-administrator-company-tab"
              ref="ref-administrator-company"
              aria-controls="tab-administrator-company"
              :aria-selected="false"
              class="nav-link mb-2 text-center bgStyle h-100"
              href="#tab-administrator-company"
              data-bs-toggle="pill"
              role="tab"
            >
              {{ $t("registration.administratorCompany") }}
            </a>
          </li>
        </ul>

        <div id="companyTabContent" class="tab-content">
          <div
            v-if="registration?.hostCompany"
            id="tab-host-company"
            aria-labelledby="tab-host-company-tab"
            class="tab-pane fade show active"
            :class="{ 'pt-0': !registration?.administratorCompany }"
            role="tabpanel"
          >
            <ContractCompanyContentComponent
              :company="registration?.hostCompany"
            />
          </div>

          <div
            v-if="registration?.administratorCompany"
            id="tab-administrator-company"
            aria-labelledby="tab-administrator-company-tab"
            class="tab-pane fade"
            role="tabpanel"
          >
            <ContractCompanyContentComponent
              :company="registration?.administratorCompany"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContractCompanyContentComponent from "@/components/registration/contract/ContractCompanyContentComponent.vue";

export default {
  name: "ContractCompanyBlockComponent",
  components: {
    ContractCompanyContentComponent,
  },
  props: {
    registration: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
