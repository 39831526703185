export default {
  chooseFile: "Seleccione un archivo",
  browse: "Examinar",
  clearFile: "Eliminar el archivo",
  requiredField: "El campo es obligatorio",
  requiredNumberOrFloat: "Introduzca una cifra entera o decimal",
  emailError: "Introduzca un correo electrónico conforme",
  phoneError: "Introduzca un número de teléfono conforme",
  lastName: "Apellido",
  firstName: "Nombre",
  email: "Correo electrónico",
  address: "Dirección",
  complement: "Complemento",
  postCode: "Código postal",
  city: "Ciudad",
  phone: "Teléfono",
  mobile: "Móvil",
  actions: "Acciones",
  rights: "Derechos",
  send: "Enviar",
  errors: "Se han detectado errores",
  form_validation: "Los campos siguientes son obligatorios:",
  entitled: "Título",
  title: "Título",
  selectValue: "Seleccionar un valor",
  uploaded_on: "Publicado el",
  error_file: "Parece que el archivo no está disponible",
  download_success: "Archivo descargado correctamente",
  file_downloading: "Descarga en curso...",
  download_file: "Descargar el archivo",
  remove_file: "Eliminar el archivo",
  signed_error: "Vaya, se ha producido un error en el almacenamiento",
  saving: "Almacenamiento en curso",
  confirm: "Confirmar",
  import: "Importar",
  error_nofile: "Debe añadir un archivo",
  error_file_format: "Añada un archivo en el formato correcto",
  error_date:
    "Introduzca una fecha de inicio anterior a la fecha de finalización",
  link: "Abrir el enlace",
  dateChoice: "Haga clic para elegir una fecha",
  next: "Siguiente",
  pleaseWait: "Espere...",
  select: "Seleccionar",
  cancel: "Cancelar",
  ok: "Aceptar",
  edit: "Modificar",
  no: "No",
  yes: "Sí",
  warning: "Atención",
  iConfirm: "Confirmo",
  Save: "Almacenamiento",
  saveFinish: "Almacenamiento finalizado",
  fileFormatError: "Seleccione un archivo en el formato correcto",
  display: "Mostrar",
  elements: "elementos",
  lines: "Líneas",
  pagination: {
    to: "para",
    on: "en",
  },
  submit: "Enviar",
  back: "Volver",
  remove: "Eliminar",
  typeYourSearch: "Escriba su búsqueda...",
  sortBy: "Ordenar por",
  order: "Orden",
  desc: "Descendente",
  asc: "Ascendente",
  reset: "Reiniciar",
  filter: "Filtrar",
  Search: "Buscar",
  "No data available in the table": "Ningún dato disponible en la tabla",
  "No data available": "Ningún dato disponible",
  "Please enter or modify your search": "Introduzca o modifique su búsqueda",
  "Please click on the add button": "Haga clic en el botón para añadir",
  search: "Buscar",
  All: "Todos",
  None: "Ninguno",
  "PDF export": "Exportar a PDF",
  "Excel export": "Exportar a Excel",
  "PNG export": "Exportar a PNG",
  "SVG export": "Exportar a SVG",
  "See statistics": "Ver las estadísticas",
  multiselect: {
    press_enter: "",
    press_enter_group: "",
    selected: "",
    press_enter_remove: "",
    press_enter_remove_group: "",
    press_enter_create_tag: "Añadir un valor",
    select_option: "",
    no_result: "Ningún resultado coincide con la búsqueda",
    no_options:
      "Escriba por lo menos {nb} caracteres para buscar resultados...",
  },
  init_search: "Reinicializar la búsqueda",
  search_motor: "Motor de búsqueda",
  file_size_error: "Seleccione un archivo de menos de ",
  maxTagError: "Respete el número máximo de valores",
  minTagError: "Respete el número mínimo de valores",
  see_more_fields: "Ver más campos",
  justificative_piece: "Justificantes",
  skillRecap: "Resumen de competencias",
  valid: "Valide",
  unlock: "Desbloquear",
};
