import { i18n } from "@/i18n";
import LayoutView from "@/views/Training/LayoutView.vue";
import PreRegistrationSheetView from "@/views/Registration/PreRegistrationSheetView.vue";

export default {
  path: "/registration",
  name: "Registration",
  component: LayoutView,
  redirect: { name: "TrainingList" },
  meta: {
    breadcrumb: true,
    authRequired: false,
    title: i18n.global.t("page.training_list"),
  },
  children: [
    {
      path: ":group/show/:tab/:registration?",
      name: "TrainingPreRegistration",
      component: PreRegistrationSheetView,
      meta: {
        title: i18n.global.t("page.pre_registration"),
        breadcrumb: true,
      },
    },
  ],
};
