<template>
  <div class="widget">
    <div class="widget-heading">
      <h2>{{ $t("registration.training") }}</h2>
    </div>
    <div class="widget-content">
      <div class="item">
        <span class="item__title">{{ $t("registration.name") }} :</span><br />
        {{ registration?.group?.trainingCourse?.training?.name }}
      </div>
      <div class="item">
        <span class="item__title">{{ $t("registration.institution") }} :</span
        ><br />
        {{ registration?.group?.trainingCourse?.training?.institution?.name }}
      </div>
      <div
        class="item"
        v-if="registration?.group?.trainingCourse?.training?.component"
      >
        <span class="item__title">{{ $t("registration.component") }} :</span
        ><br />
        {{ registration?.group?.trainingCourse?.training?.component?.name }}
      </div>
      <div class="item">
        <span class="item__title"
          >{{ $t("registration.trainingCourse") }} :</span
        ><br />
        {{ registration?.group?.startYear }} -
        {{ registration?.group?.endYear }}
      </div>
      <div class="item">
        <span class="item__title">{{ $t("registration.group") }} :</span><br />
        {{ registration?.group?.name }}
      </div>
      <div class="item">
        <span class="item__title"
          >{{ $t("registration.pedagogicContact") }} :</span
        ><br />
        -
      </div>
    </div>
  </div>

  <div class="widget">
    <div class="widget-heading">
      <h2>{{ $t("registration.pedagogicInformations") }}</h2>
    </div>
    <div class="widget-content">
      <div class="item">
        <span class="item__title">{{ $t("registration.dates") }} :</span><br />
        {{
          $tc("registration.from_to", {
            from: registration?.group?.startDate
              ? dateFormatter().format(registration.group.startDate, "short")
              : "",
            to: registration?.group?.endDate
              ? dateFormatter().format(registration.group.endDate, "short")
              : "",
          })
        }}
      </div>
      <div class="item">
        <span class="item__title">{{ $t("registration.hourlyVolume") }} :</span
        ><br />
        {{ registration?.group?.hourlyTrainingVolume }}h
      </div>
      <div v-if="registration?.group?.training?.titleRncp">
        <div class="item">
          <span class="item__title"
            >{{ $t("registration.degreePrepared") }} :</span
          ><br />
          {{ registration?.group?.training?.titleRncp?.name }}
        </div>
        <div class="item">
          <span class="item__title">{{ $t("registration.level") }} :</span
          ><br />
          {{ registration?.group?.training?.titleRncp?.level }}
        </div>
        <div class="item">
          <span class="item__title">{{ $t("registration.titleRncp") }} :</span
          ><br />
          {{ registration?.group?.training?.titleRncp?.code }}
        </div>
      </div>
      <div
        class="item"
        v-if="registration?.group?.trainingCourse?.training?.degreeCode"
      >
        <span class="item__title">{{ $t("registration.degreeCode") }} :</span
        ><br />
        {{ registration?.group?.trainingCourse?.training?.degreeCode }}
      </div>
      <div class="mt-2">
        <a
          v-if="registration?.group?.calendar"
          class="btn btn-success btnStyle d-block w-100"
          :href="getFileSrc(registration?.group?.calendar)"
        >
          <FontAwesomeIcon icon="fa-light fa-calendar" class="me-1" />
          {{ $t("registration.calendar") }}
        </a>
        <a
          v-if="registration?.group?.program"
          class="btn btn-success btnStyle d-block mt-2 w-100"
          :href="getFileSrc(registration?.group?.program)"
        >
          <FontAwesomeIcon icon="fa-light fa-list" class="me-1" />
          {{ $t("registration.program") }}
        </a>
      </div>
    </div>
  </div>

  <div class="widget">
    <div class="widget-heading">
      <h2>{{ $t("registration.fundingInformations") }}</h2>
    </div>
    <div class="widget-content">
      <div
        v-if="
          registration?.group?.contractType &&
          registration?.group?.contractType.includes(1)
        "
      >
        <h6 v-if="registration?.group?.contractType.includes(2)">
          {{ $t("registration.apprenticeShipContract") }}
        </h6>
        <div class="item">
          <span class="item__title">{{ $t("registration.rate") }} :</span><br />
          {{ registration?.group?.totalTrainingCost }}
        </div>
        <div class="item">
          <span class="item__title"
            >{{ $t("registration.publicAnnualRate") }} :</span
          ><br />
          {{ registration?.group?.totalPublicTrainingCost }}
        </div>
      </div>
      <div
        v-if="
          registration?.group?.contractType &&
          registration?.group?.contractType.includes(2)
        "
      >
        <h6 v-if="registration?.group?.contractType.includes(1)" class="mt-4">
          {{ $t("registration.proContract") }}
        </h6>
        <div class="item">
          <span class="item__title">{{ $t("registration.hourlyRate") }} :</span
          ><br />
          {{ registration?.group?.hourlyTrainingRate }}€/h
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";
import { fileManager } from "@/services/file/file-manager";

export default {
  name: "RegistrationInformationsComponent",
  components: {},
  props: {
    registration: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dateFormatter() {
      return dateFormatter;
    },

    getFileSrc(file) {
      return fileManager.getDownloadSrc(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.item + .item {
  border-top: 1px dashed $m_color_6;
  padding-top: 5px;
  margin-top: 5px;
}

.item {
  &__title {
    font-weight: 700;
  }
}
</style>
