<template>
  <div class="widget">
    <div class="widget-heading align-items-center">
      <div>
        <h2 class="textStyle h-auto">{{ $t("registration.training") }}</h2>
      </div>
      <button class="btn-icon">
        <FontAwesomeIcon icon="fa-light fa-pen" />
      </button>
    </div>
    <div class="widget-content">
      <div class="row">
        <div class="col-lg-6">
          <RegistrationListFieldComponent
            trad="startDate"
            type="date"
            :field="registration?.group?.startDate"
          />

          <RegistrationListFieldComponent
            trad="institution"
            type="simple"
            :field="
              registration?.group?.trainingCourse?.training?.institution?.name
            "
          />

          <RegistrationListFieldComponent
            trad="component"
            type="simple"
            :field="
              registration?.group?.trainingCourse?.training?.component?.name
            "
          />

          <RegistrationListFieldComponent
            trad="name"
            type="simple"
            :field="registration?.group?.trainingCourse?.training?.displayName"
          />

          <RegistrationListFieldComponent
            trad="trainingCourse"
            type="trainingCourse"
            :field="registration?.group"
          />

          <RegistrationListFieldComponent
            trad="group"
            type="simple"
            :field="registration?.group?.name"
          />
        </div>

        <div class="col-lg-6">
          <RegistrationListFieldComponent
            trad="endDate"
            type="date"
            :field="registration?.group?.endDate"
          />

          <RegistrationListFieldComponent
            trad="siret"
            type="simple"
            :field="
              registration?.group?.trainingCourse?.training?.institution?.siret
            "
          />

          <RegistrationListFieldComponent
            trad="uaiCode"
            type="simple"
            :field="registration?.group?.trainingCourse?.uaiCode"
          />

          <RegistrationListFieldComponent
            trad="titleRncp"
            type="simple"
            :field="
              registration?.group?.trainingCourse?.training?.titleRncp?.code
            "
          />

          <RegistrationListFieldComponent
            trad="degreeCode"
            type="simple"
            :field="registration?.group?.trainingCourse?.training?.degreeCode"
          />

          <RegistrationListFieldComponent
            trad="hourlyTrainingVolume"
            type="simple"
            :field="registration?.group?.hourlyTrainingVolume"
            unit="heures"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationListFieldComponent from "@/components/registration/RegistrationListFieldComponent.vue";

export default {
  name: "ContractTrainingBlockComponent",
  components: { RegistrationListFieldComponent },
  props: {
    registration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px dashed $m_color_5;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 13px;

  &:last-child {
    border: none;
  }
}
</style>
