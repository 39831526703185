import absence from "@/translations/absence/fr";
import account from "@/translations/account/fr";
import document from "@/translations/document/fr";
import forms from "@/translations/forms/fr";
import questionnaire from "@/translations/questionnaire/fr";
import gradebook from "@/translations/gradebook/fr";
import lesson from "@/translations/lesson/fr";
import login from "@/translations/login/fr";
import chat from "@/translations/chat/fr";
import page from "@/translations/page/fr";
import skills from "@/translations/skills/fr";
import timetable from "@/translations/timetable/fr";
import trainingCourse from "@/translations/training-course/fr";
import triad from "@/translations/triad/fr";
import platform from "@/translations/platform/fr";
import admin from "@/translations/admin/fr";
import studeaManager from "@/translations/studea-manager/fr";
import dashboard from "@/translations/dashboard/fr";
import global from "@/translations/global/fr";
import error from "@/translations/error/fr";
import notepad from "@/translations/notepad/fr";
import registration from "@/translations/registration/fr";

export const fr = {
  ...absence,
  ...account,
  ...document,
  ...forms,
  ...questionnaire,
  ...gradebook,
  ...lesson,
  ...login,
  ...chat,
  ...page,
  ...skills,
  ...timetable,
  ...trainingCourse,
  ...triad,
  ...platform,
  ...admin,
  ...studeaManager,
  ...dashboard,
  ...global,
  ...error,
  ...notepad,
  ...registration,
};
