<template>
  <div
    class="formAlert"
    v-if="
      (model.vars.attr &&
        model.vars.attr.informationTitle &&
        (position === 'form' || model.vars.attr.informationContent)) ||
      (model.vars.attr && model.vars.attr.alertTitle && triggered)
    "
  >
    <label
      :for="model.vars.id"
      class="textStyle formAlert__label"
      v-if="position === 'help'"
    >
      {{ model.vars.label }}
    </label>
    <button
      v-if="
        model.vars.attr &&
        (position === 'form' || model.vars.attr.informationContent)
      "
      type="button"
      class="formAlert__button"
      :disabled="
        model.vars.attr &&
        model.vars.attr.informationTitle &&
        !model.vars.attr.informationContent
      "
      :class="{
        'text-info': model.vars.attr.informationLevel === 1,
        'text-warning': model.vars.attr.informationLevel === 2,
        'text-danger': model.vars.attr.informationLevel === 3,
        form: position === 'form',
      }"
      @click="openAlert('information')"
    >
      <FontAwesomeIcon
        v-if="model.vars.attr.informationLevel"
        class="formAlert__icon"
        :icon="'fa-light fa-' + icon('information')"
      />
      {{ model.vars.attr.informationTitle }}
    </button>
    <button
      v-if="
        model.vars.attr &&
        model.vars.attr.alertLevel &&
        position === 'help' &&
        triggered
      "
      type="button"
      class="formAlert__button"
      :class="{
        'text-info': model.vars.attr.alertLevel === 1,
        'text-warning': model.vars.attr.alertLevel === 2,
        'text-danger': model.vars.attr.alertLevel === 3,
        form: position === 'form',
      }"
      @click="openAlert('alert')"
    >
      <FontAwesomeIcon
        v-if="model.vars.attr.alertLevel"
        class="formAlert__icon"
        :icon="'fa-light fa-' + icon('alert')"
      />
      {{ model.vars.attr.alertTitle }}
    </button>
  </div>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { formManager } from "@/services/form/form-manager";

export default {
  name: "FormInformationAlertComponent",
  props: {
    model: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      required: false,
      default: "form",
    },
  },
  data() {
    return {
      triggered: false,
    };
  },
  watch: {
    "model.vars.value"() {
      this.trigger();
    },
  },
  methods: {
    openAlert(type) {
      notificationManager.showAlert(
        this.type(type),
        this.model.vars.attr[type + "Title"],
        this.model.vars.attr[type + "Content"],
        true,
        false,
        i18n.global.t("close"),
        null,
        null,
        null,
        true,
      );
    },

    type(type) {
      let ret;
      switch (this.model.vars.attr[type + "Level"]) {
        case 1:
          ret = "info";
          break;
        case 2:
          ret = "warning";
          break;
        case 3:
          ret = "error";
          break;
      }
      return ret;
    },

    icon(type) {
      let ret;
      switch (this.model.vars.attr[type + "Level"]) {
        case 1:
          ret = "circle-info";
          break;
        case 2:
          ret = "triangle-exclamation";
          break;
        case 3:
          ret = "circle-xmark";
          break;
      }
      return ret;
    },

    trigger() {
      if (formManager.isTriggerAlert(this.model)) {
        let value = this.model.vars.value;
        if (typeof value === "object") {
          value = value.data;
        }
        const triggerValue = this.model.vars.attr.alertTriggerValue;
        if (value === triggerValue) {
          this.openAlert("alert");
          this.triggered = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formAlert {
  &__button {
    background: none;
    border: none;
    color: var(--primary-color);
    text-align: left;

    &.form {
      font-size: 13px;
    }
  }

  &__label {
    font-size: 14px;
    cursor: pointer;
    display: block;
  }

  &__icon {
    margin-right: 3px;
  }
}

.formAlert + .formAlert {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dashed $m_color_6;
}
</style>
