<template>
  <div id="todo-block" class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">{{ $t("action_to_realize") }}</h2>
    </div>
    <div class="widget-content">
      <div
        v-if="
          (this.toComplete && Object.keys(this.toComplete).length) ||
          sessionToComplete
        "
      >
        <div
          v-if="this.toComplete && Object.keys(this.toComplete).length"
          class="grid"
        >
          <div class="grid__row -heading blockStyle py-2 mb-3">
            <div class="grid__col grid__date text-uppercase">
              {{ $t("due_date") }}
            </div>
            <div class="grid__col grid__title text-uppercase">
              {{ $t("questionnaire") }}
            </div>
          </div>
          <div :class="'grid__content' + (expanded ? ' all' : '')">
            <div
              v-for="questionnaire in toSign"
              :key="questionnaire.id"
              class="grid__row bgStyle"
            >
              <div class="grid__date grid__col">
                {{ formatDate(questionnaire.date) }}<br />
                <div class="d-inline d-md-none">{{ questionnaire.title }}</div>
              </div>
              <div class="grid__title grid__col d-md-inline d-none">
                {{ questionnaire.title }}
              </div>
              <div class="grid__button grid__col">
                <router-link
                  v-if="triad && triad.id && questionnaire && questionnaire.id"
                  :to="{
                    name: 'questionnaire',
                    params: {
                      questionnaireId: questionnaire.id,
                      triadId: triad.id,
                    },
                  }"
                  class="btn btn-sm btnStyle btn-success"
                >
                  {{ $t("sign") }}
                </router-link>
              </div>
            </div>
            <div
              v-for="questionnaire in toComplete"
              :key="questionnaire.id"
              class="grid__row bgStyle"
            >
              <div class="grid__date grid__col">
                {{ formatDate(questionnaire.date) }}<br />
                <div class="d-inline d-md-none">{{ questionnaire.title }}</div>
              </div>
              <div class="grid__title grid__col d-md-inline d-none">
                {{ questionnaire.title }}
              </div>
              <div class="grid__button grid__col">
                <router-link
                  v-if="triad && triad.id && questionnaire && questionnaire.id"
                  :to="{
                    name: 'questionnaire',
                    params: {
                      questionnaireId: questionnaire.id,
                      triadId: triad.id,
                    },
                  }"
                  class="btn btn-sm btnStyle btn-success"
                >
                  {{ $t("complete") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if="sessionToComplete && sessionToComplete.length" class="grid">
          <div class="grid__row -heading blockStyle py-2 mb-3">
            <div class="grid__col grid__date text-uppercase">
              {{ $t("due_date") }}
            </div>
            <div class="grid__col grid__title text-uppercase">
              {{ $t("skill") }}
            </div>
          </div>
          <div
            v-for="session in sessionToComplete"
            :key="session.id"
            class="grid__row bgStyle"
          >
            <div class="grid__date grid__col">
              {{ formatDate(session.endDate) }}<br />
              <div class="d-inline d-md-none">{{ session.name }}</div>
            </div>
            <div class="grid__title grid__col d-md-inline d-none">
              {{ session.name }}
            </div>
            <div class="grid__button grid__col">
              <router-link
                :to="{
                  name: 'MODULE_STUDEA_SKILL',
                  query: { session: session.id },
                }"
                class="btn btn-sm btnStyle btn-success"
              >
                {{ $t("complete") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <FontAwesomeIcon icon="fa-light fa-badge-check" />
        Vous êtes à jour !
      </div>
      <div
        v-if="this.toComplete && Object.keys(this.toComplete).length > 6"
        :class="'widget-plus' + (this.expanded ? ' expanded' : '')"
      >
        <button class="widget-button bgStyle" @click="expand">
          <FontAwesomeIcon icon="fa-light fa-plus" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useUserStore } from "@/store/user/user";
import { useTriadStore } from "@/store/triad/triad";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { sortManager } from "@/services/utilities/sort-manager";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { userManager } from "@/services/security/user-manager";

export default {
  name: "QuestionnaireToDoBlockComponent",
  data() {
    return {
      expanded: false,
      userProfile: null,
      sessionToComplete: [],
    };
  },
  props: {
    skillStatistic: {
      type: Object,
      required: false,
    },
  },
  components: { FontAwesomeIcon },
  computed: {
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useQuestionnaireStore, {
      toSign: (store) => store.toSign,
      questionnaire: (store) => store.questionnaire,
      toComplete: (store) => store.toComplete,
    }),
  },
  watch: {
    user() {
      this.setSessionToComplete();
    },

    skillStatistic() {
      this.setSessionToComplete();
    },

    triad() {
      this.setSessionToComplete();
    },
  },
  mounted() {
    this.setSessionToComplete();
    this.sortQuestionnairesToComplete();
    this.sortQuestionnairesToSign();
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.scrollTo();
      }
    },

    scrollTo() {
      const block = document.getElementById("todo-block");
      const offset = 150;
      setTimeout(function () {
        window.scrollTo({
          behavior: "smooth",
          top:
            block.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        });
      }, 200);
    },

    sortQuestionnairesToComplete() {
      this.setToComplete(sortManager.sort(this.toComplete, "date", "DESC"));
    },

    sortQuestionnairesToSign() {
      this.setToSign(sortManager.sort(this.toSign, "date", "DESC"));
    },

    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    setUserProfile() {
      this.userProfile = userManager.checkUserProfile(this.user, this.triad);
      return userManager.getProfileKey(this.userProfile);
    },

    setSessionToComplete() {
      this.sessionToComplete = [];

      if (this.skillStatistic && Object.keys(this.skillStatistic).length) {
        this.skillStatistic.forEach((session) => {
          if (session.open && session.userHasToComplete) {
            this.sessionToComplete.push(session);
          }
        });
      }
    },

    ...mapActions(useQuestionnaireStore, {
      setToSign: "setToSign",
      setToComplete: "setToComplete",
    }),
  },
};
</script>

<style lang="scss" scoped>
.grid {
  @include dark-theme {
    color: $white;
  }

  &__row {
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    position: relative;
    display: none;

    &:before {
      position: absolute;
      content: "";
      height: 25px;
      width: 4px;
      background: var(--primary-color);
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include up-to-md {
        height: 100%;
      }

      @include dark-theme {
        background: $white;
      }
    }

    &.-heading {
      &:before {
        display: none;
      }
    }

    &.blockStyle {
      &:before {
        @include dark-theme {
          background: var(--primary-color);
        }
      }
    }
  }

  &__row:nth-child(-n + 6) {
    display: flex;
  }

  &__content {
    margin-bottom: 15px;

    &.all {
      .grid__row {
        display: flex;
      }
    }
  }

  &__row + &__row {
    margin-top: 10px;
  }

  &__col {
    flex: 1;
  }

  &__date {
    max-width: 150px;

    @include up-to-md {
      max-width: initial;
    }

    div {
      @include up-to-md {
        font-weight: 700;
      }
    }
  }

  &__button {
    text-align: right;
    max-width: 100px;
  }
}

.widget-plus {
  &.expanded {
    .widget-button {
      transform: rotate(45deg);
    }
  }
}
</style>
