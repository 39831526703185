<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">{{ $t("registration.follow") }}</h2>
    </div>
    <div class="widget-content">
      <ul class="follow">
        <template v-for="step in steps" :key="step.id">
          <li
            class="follow__step"
            :class="{
              isValid: step.valid,
              isProgress: step.progress,
              isError: step.error,
            }"
          >
            <span class="follow__icon">
              <FontAwesomeIcon :icon="'fa-light fa-' + step.icon" />
            </span>
            <span class="w-100">
              <span class="follow__label">
                <span class="d-flex align-items-center">
                  {{ step.name }}
                </span>
                <span class="follow__status">
                  <FontAwesomeIcon :icon="'fa-light fa-' + statusIcon(step)" />
                </span>
              </span>
              <span class="follow__validatedBy" v-if="step.valid">
                {{
                  $tc("registration.validatedAt", {
                    date: step.validatedAt
                      ? dateFormatter().format(step.validatedAt, "long")
                      : "",
                    validator: step.validatedBy,
                  })
                }}</span
              >
              <span class="follow__validatedBy" v-if="step.progress">{{
                $t("registration.inProgress")
              }}</span>
            </span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "RegistrationStepsComponent",
  components: {},
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    dateFormatter() {
      return dateFormatter;
    },

    statusIcon(step) {
      if (step.progress) {
        return "hourglass";
      } else if (step.valid) {
        return "check";
      } else {
        return "xmark";
      }
    },
    init() {},
  },
};
</script>

<style lang="scss" scoped>
.follow {
  list-style: none;
  padding-left: 0;

  &__step {
    display: flex;
    align-items: flex-start;
    --step-color: var(--registration-waiting);
    position: relative;

    &.isValid {
      --step-color: var(--registration-success);

      .follow__label {
        margin-top: 0;
      }
    }

    &.isProgress {
      --step-color: var(--registration-progress);

      .follow__label {
        margin-top: 0;
      }
    }

    &.isError {
      --step-color: #da5164;

      .follow__label {
        margin-top: 0;
      }
    }

    &:before {
      height: 100%;
      width: 3px;
      background: var(--step-color);
      position: absolute;
      content: "";
      left: 15.5px;
      top: 25px;
      z-index: 0;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }
  }

  &__step + &__step {
    margin-top: 25px;
  }

  &__icon {
    background: var(--step-color);
    display: flex;
    border-radius: 50%;
    border: 3px solid $white;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    justify-content: center;
    min-width: 35px;
    width: 35px;
    min-height: 35px;
    height: 35px;
    margin-right: 10px;
    position: relative;
    z-index: 1;

    svg {
      color: $white;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    margin-top: 8px;
    align-items: center;
    justify-content: space-between;

    svg {
      color: var(--step-color);
      font-size: 16px;
    }
  }

  &__validatedBy {
    font-size: 12px;
    color: $m-color_8;
  }

  &__status {
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--step-color);
    border-radius: 50%;
    display: flex;

    svg {
      width: 10px;
      height: 10px;
    }
  }
}
</style>
