<template>
  <div class="session">
    <div class="session__heading">
      <div class="session__name">
        <span>
          <FontAwesomeIcon
            icon="fa-light fa-circle-info"
            v-tooltip="{
              title:
                $t('dashboard.contract.from') +
                ' ' +
                formatDate(session.startDate) +
                ' ' +
                $t('dashboard.contract.to') +
                ' ' +
                formatDate(session.endDate),
            }"
          />
        </span>
        <span class="ms-2 me-2">{{ session.name }}</span>
        <span>
          <FontAwesomeIcon
            :icon="'fa-light fa-' + (session.open ? 'hourglass-start' : 'lock')"
          />
        </span>
      </div>
      <div class="session__triad">
        <div
          v-if="
            (session.skillRepliesByProfile.apprentice ||
              session.skillRepliesByProfile.apprentice === 0) &&
            apprenticeProfileLabel &&
            apprenticeProfileLabel.title &&
            apprenticeProfileLabel.titleAbbreviation &&
            userHasToComplete(1)
          "
          class="session__triad__role"
          v-tooltip="{ title: apprenticeProfileLabel.title }"
        >
          {{ apprenticeProfileLabel.titleAbbreviation }}
        </div>
        <div
          v-if="
            (session.skillRepliesByProfile.tutor ||
              session.skillRepliesByProfile.tutor === 0) &&
            tutorProfileLabel &&
            tutorProfileLabel.title &&
            tutorProfileLabel.titleAbbreviation &&
            userHasToComplete(2)
          "
          :title="tutorProfileLabel.title"
          class="session__triad__role"
          v-tooltip="{ title: tutorProfileLabel.title }"
        >
          {{ tutorProfileLabel.titleAbbreviation }}
        </div>
        <div
          v-if="
            (session.skillRepliesByProfile.apprenticeMaster ||
              session.skillRepliesByProfile.apprenticeMaster === 0) &&
            apprenticeMasterProfileLabel &&
            apprenticeMasterProfileLabel.title &&
            apprenticeMasterProfileLabel.titleAbbreviation &&
            userHasToComplete(3)
          "
          class="session__triad__role"
          v-tooltip="{ title: apprenticeMasterProfileLabel.title }"
        >
          {{ apprenticeMasterProfileLabel.titleAbbreviation }}
        </div>
        <div
          v-if="
            (session.skillRepliesByProfile.studeaManager ||
              session.skillRepliesByProfile.studeaManager === 0) &&
            studeaManagerProfileLabel &&
            studeaManagerProfileLabel.titleAbbreviation &&
            userHasToComplete(4)
          "
          class="session__triad__role"
        >
          {{ studeaManagerProfileLabel.titleAbbreviation }}
        </div>
      </div>
    </div>
    <div class="session__content">
      <div class="session__content__offset"></div>
      <div class="session__replies">
        <div
          class="session__reply"
          v-if="
            session.skillRepliesByProfile.apprentice ||
            (session.skillRepliesByProfile.apprentice === 0 &&
              userHasToComplete(1))
          "
        >
          {{ session.skillRepliesByProfile.apprentice }}/{{ session.nbSkill }}
        </div>
        <div
          class="session__reply"
          v-if="
            session.skillRepliesByProfile.tutor ||
            (session.skillRepliesByProfile.tutor === 0 && userHasToComplete(2))
          "
        >
          {{ session.skillRepliesByProfile.tutor }}/{{ session.nbSkill }}
        </div>
        <div
          class="session__reply"
          v-if="
            session.skillRepliesByProfile.apprenticeMaster ||
            (session.skillRepliesByProfile.apprenticeMaster === 0 &&
              userHasToComplete(3))
          "
        >
          {{ session.skillRepliesByProfile.apprenticeMaster }}/{{
            session.nbSkill
          }}
        </div>
        <div
          class="session__reply"
          v-if="
            session.skillRepliesByProfile.studeaManager ||
            (session.skillRepliesByProfile.studeaManager === 0 &&
              userHasToComplete(4))
          "
        >
          {{ session.skillRepliesByProfile.studeaManager }}/{{
            session.nbSkill
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { usePlatformStore } from "@/store/platform/platform";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { triadManager } from "@/services/triad/triad-manager";
import { useSkillStore } from "@/store/skill/skill";

export default {
  name: "SkillSessionBlockComponent",
  props: {
    session: {
      type: Object,
      required: true,
    },
  },

  emits: [],
  computed: {
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      studeaManagerProfileLabel: (store) => store.studeaManagerProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
    }),
    ...mapState(useSkillStore, {
      skillConfig: (store) => store.skillConfig,
    }),
  },
  methods: {
    triadManager() {
      return triadManager;
    },
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },
    userHasToComplete(profile) {
      return this.skillConfig?.skillRoles?.includes(profile);
    },
  },
  components: { FontAwesomeIcon },
};
</script>

<style scoped lang="scss">
.session {
  &__heading {
    border-radius: 6px;
    text-transform: uppercase;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    color: $white;
  }
  &__name {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--secondary-color);
    width: 80%;
  }
  &__triad {
    display: flex;
    justify-content: space-between;
    width: 25%;
    background-color: var(--primary-color);

    &__role {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-weight: 700;
    }
  }

  &__content {
    display: flex;
    &__offset {
      width: 80%;
    }
  }

  &__replies {
    display: flex;
    width: 25%;
    padding: 10px 0;
    justify-content: space-between;
  }

  &__reply {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }
}
</style>
