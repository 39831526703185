<template>
  <div class="contract__part" v-if="field">
    <h6>{{ $t("registration." + trad) }}</h6>
    <div class="fw-bold">{{ field.firstname }} {{ field.lastname }}</div>
    <div v-if="field.email">{{ field.email }}</div>
    <div v-if="field.Phone || field.Mobile">
      {{ field.Phone }}{{ field.Phone && field.Mobile ? " / " : ""
      }}{{ field.Mobile }}
    </div>
    <div v-if="field.birthday">
      {{ $t("registration.birthDate") }} :
      {{ dateFormatter().format(field.birthday, "short") }}
    </div>
    <div v-if="field.function">
      {{ $t("registration.function") }} : {{ field.function }}
    </div>
    <div v-if="field.degree">
      {{ $t("registration.degree") }} : {{ field.degree.apprenticeCode }} -
      {{ field.degree.name }}
    </div>
    <div v-if="field.degreeName">
      {{ $t("registration.title") }} : {{ field.degreeName }}
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "RegistrationContactFieldComponent",
  methods: {
    dateFormatter() {
      return dateFormatter;
    },
  },
  components: {},
  props: {
    field: {
      type: [Object, null],
      required: true,
    },
    trad: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px dashed $m_color_5;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 13px;

  &:last-child {
    border: none;
  }
}
</style>
