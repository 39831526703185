<template>
  <div v-if="model.vars.line_break" class="col-12"></div>
  <div
    v-if="
      (!model.vars.attr || !model.vars.attr['data-form-sticky']) &&
      !model.vars.hide
    "
    :class="
      'form-group ' +
      (model.vars.row_attr && model.vars.row_attr.class
        ? ' ' + model.vars.row_attr.class
        : '') +
      (model.vars.toggle_class
        ? ' d-none ' + model.vars.toggle_class.replaceAll('\\', '')
        : '')
    "
  >
    <div class="d-flex flex-column justify-content-end h-100 position-relative">
      <ModelComponent
        :disableFocusAnimation="disableFocusAnimation"
        :model="model"
        @ajaxTargetLoad="ajaxTargetLoad"
        @ajaxTargetTrig="ajaxTargetTrig"
        @update="update"
        @toggle="toggle"
        @input="input"
        @draft="draft"
        @uploading="uploading"
        @uploaded="uploaded"
        @dateChanged="dateChanged"
        @resetInputs="resetInputs"
        @selected="selected"
      />
    </div>
  </div>
</template>

<script>
import ModelComponent from "@/components/form/ModelComponent.vue";

export default {
  name: "FormFieldComponent",
  data() {
    return {
      itemModel: this.model,
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    disableFocusAnimation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    "ajaxTargetLoad",
    "ajaxTargetTrig",
    "update",
    "toggle",
    "input",
    "draft",
    "uploading",
    "uploaded",
    "dateChanged",
    "resetInputs",
  ],
  methods: {
    ajaxTargetLoad(payload) {
      this.$emit("ajaxTargetLoad", payload);
    },

    ajaxTargetTrig(payload) {
      this.$emit("ajaxTargetTrig", payload);
    },

    update(value) {
      this.$emit("update", value);
    },

    toggle(model) {
      this.$emit("toggle", model);
    },

    input(value) {
      this.$emit("input", value);
    },

    draft(value) {
      this.$emit("draft", value);
    },

    uploading() {
      this.$emit("uploading");
    },

    uploaded(data) {
      this.$emit("uploaded", data);
    },

    dateChanged(model) {
      this.$emit("dateChanged", model);
    },

    resetInputs(payload) {
      this.$emit("resetInputs", payload);
    },

    selected(payload) {
      this.$emit("selected", payload);
    },
  },
  components: { ModelComponent },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
</style>
