<template>
  <FormTabsBlockComponent
    :loading="loading"
    :tabs="tabs"
    :invalidTab="invalidTab"
    :keepHideValue="true"
    :customButtons="buttons"
    :hideSubmit="hideSubmit"
    @formCancel="cancel"
    @formSubmit="submit"
    @draft="refresh"
    @valid="valid"
    @save="save"
    @unlock="unlock"
  >
    <template
      #groupFunding="{ toggleField, draftField }"
      v-if="
        tabs &&
        Object.keys(tabs).length &&
        Object.keys(tabs).length > 1 &&
        tabs[1].models
      "
    >
      <div class="contractType-1">
        <FormFieldComponent :model="model(1, 'contractTypeApprentice')" />
        <div class="row" v-if="model(1, 'privateSectorAndSimilar')">
          <div class="col-lg-6">
            <div class="form-column first">
              <div class="row">
                <FormFieldComponent
                  :model="model(1, 'privateSectorAndSimilar')"
                />
                <FormFieldComponent :model="model(1, 'pedagogicRate')" />
                <FormFieldComponent
                  :model="model(1, 'pricePolicy')"
                  @update="setPricePolicyInfo"
                />
                <div class="form-group">
                  <div class="alert bg-light-info" v-if="pricePolicyInfo">
                    {{ pricePolicyInfo }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-column last">
              <div class="row">
                <FormFieldComponent :model="model(1, 'publicSector')" />
                <FormFieldComponent :model="model(1, 'pedagogicRate')" />
                <FormFieldComponent
                  :model="model(1, 'cnfptFundingEligibility')"
                />
                <FormFieldComponent :model="model(1, 'cnfptSpecificPrice')" />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group-row" v-if="model(1, 'trainingCost12month')">
          <div class="col-lg-6">
            <div class="form-column first">
              <div class="row pt-3">
                <FormFieldComponent
                  :model="model(1, 'trainingCost12month')"
                  @draft="draftField"
                />
                <FormFieldComponent :model="model(1, 'totalTrainingCost')" />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-column last">
              <div class="row pt-3">
                <FormFieldComponent
                  :model="model(1, 'publicTrainingCost12month')"
                  @draft="draftField"
                />
                <FormFieldComponent
                  :model="model(1, 'totalPublicTrainingCost')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group-row" v-if="model(1, 'additionalExpenses')">
          <div class="col-lg-6">
            <div class="form-column first">
              <div class="row pt-3">
                <FormFieldComponent :model="model(1, 'additionalExpenses')" />
                <FormFieldComponent
                  :model="model(1, 'firstEquipment')"
                  @toggle="toggleField"
                />
                <FormFieldComponent :model="model(1, 'firstEquipmentAmount')" />
                <FormFieldComponent
                  :model="model(1, 'internationalMobility')"
                />
                <FormFieldComponent
                  :model="model(1, 'catering')"
                  @toggle="toggleField"
                />
                <FormFieldComponent :model="model(1, 'mealsNumber')" />
                <FormFieldComponent
                  :model="model(1, 'hosting')"
                  @toggle="toggleField"
                />
                <FormFieldComponent :model="model(1, 'nightsNumber')" />
              </div>
            </div>
          </div>
          <div class="col-lg-6"></div>
        </div>
        <div
          class="row form-group-row mb-3"
          v-if="model(1, 'advancedSettings')"
        >
          <div class="col-lg-6">
            <div class="form-column first">
              <div class="row pt-3">
                <FormFieldComponent :model="model(1, 'advancedSettings')" />
                <FormFieldComponent
                  :model="model(1, 'proratizationTrainingSuperior')"
                  @draft="draftField"
                  @toggle="toggleField"
                />
                <FormFieldComponent
                  :model="model(1, 'proratizationRemainingExpenses')"
                  @draft="draftField"
                />
                <FormFieldComponent
                  :model="model(1, 'proratizationTrainingLower')"
                  @toggle="toggleField"
                  @draft="draftField"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-column last">
              <div class="row pt-3">
                <FormFieldComponent :model="model(1, 'advancedSettings')" />
                <FormFieldComponent
                  :model="model(1, 'publicProratizationTrainingSuperior')"
                  @draft="draftField"
                  @toggle="toggleField"
                />
                <FormFieldComponent
                  :model="model(1, 'publicProratizationRemainingExpenses')"
                />
                <FormFieldComponent
                  :model="model(1, 'publicProratizationTrainingLower')"
                  @toggle="toggleField"
                  @draft="draftField"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <FormFieldComponent :model="model(1, 'contractTypePro')" />
        <div class="row" v-if="model(1, 'hourlyTrainingRate')">
          <div class="row pt-3">
            <FormFieldComponent
              :model="model(1, 'hourlyTrainingRate')"
              @draft="draftField"
            />
            <FormFieldComponent
              :model="model(1, 'billingType')"
              @draft="draftField"
            />
            <FormFieldComponent :model="model(1, 'totalHoursWorked')" />
            <FormFieldComponent :model="model(1, 'globalTrainingRate')" />
            <FormFieldComponent
              v-if="model(1, 'numberOfHoursYear1')"
              :model="model(1, 'numberOfHoursYear1')"
            />
            <FormFieldComponent
              v-if="model(1, 'numberOfHoursYear2')"
              :model="model(1, 'numberOfHoursYear2')"
            />
          </div>
        </div>
      </div>
    </template>
  </FormTabsBlockComponent>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { formValidator } from "@/services/form/form-validator";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";
import FormFieldComponent from "@/components/form/FormFieldComponent.vue";

export default {
  name: "DesignEditView",
  data() {
    return {
      tabs: [],
      group: {},
      invalidTab: null,
      loading: false,
      id: null,
      pricePolicyInfo: "",
      buttons: [],
      hideSubmit: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.id = this.$route.params.groupId;
      studeaManagerManager.form("group", null, this.id).then((data) => {
        this.tabs = data.tabs;
        this.group = data.object;
        this.initButtons();
        this.setPricePolicyInfo();
        this.loading = false;
        this.setAppLoading(false);
      });
    },

    initButtons() {
      switch (this.group?.status) {
        case 1:
          this.hideSubmit = false;
          this.buttons = [];
          break;
        case 2:
          this.hideSubmit = true;
          this.buttons = [
            {
              label: this.$t("save"),
              action: "save",
              valid: false,
              color: "primary",
            },
            {
              label: this.$t("valid"),
              action: "valid",
              valid: true,
              color: "success",
            },
          ];
          break;
        case 3:
          this.hideSubmit = true;
          this.buttons = [
            {
              label: this.$t("unlock"),
              action: "unlock",
              valid: false,
              color: "warning",
            },
          ];
          break;
      }
    },

    setPricePolicyInfo(val = null) {
      let label = "";
      const field = this.tabs[1].models["pricePolicy"];
      if (!val && field?.vars?.data) {
        val = field.vars.data;
      }
      if (val) {
        switch (parseInt(val)) {
          case 1:
            label = this.$t("registration.pricePolicyInfo1");
            break;
          case 2:
            label = this.$t("registration.pricePolicyInfo2");
            break;
          case 3:
            label = this.$t("registration.pricePolicyInfo3");
            break;
        }
      }
      this.pricePolicyInfo = label;
    },

    refresh() {
      this.put(1, true).then((res) => {
        this.updateFieldValues(res);
      });
    },

    updateFieldValues(res) {
      const fieldsValueToUpdate = [
        "globalTrainingRate",
        "totalHoursWorked",
        "totalTrainingCost",
        "totalPublicTrainingCost",
      ];
      const fieldsInformationTitleToUpdate = [
        "totalTrainingCost",
        "totalPublicTrainingCost",
      ];
      if (this.tabs && Object.keys(this.tabs).length) {
        Object.values(this.tabs).forEach((tab) => {
          if (tab.models && Object.keys(tab.models).length) {
            Object.values(tab.models).forEach((model) => {
              if (model && model.vars && model.vars.name) {
                if (fieldsValueToUpdate.includes(model.vars.name)) {
                  model.vars.value = res[model.vars.name];
                }
                if (fieldsInformationTitleToUpdate.includes(model.vars.name)) {
                  model.vars.attr["informationTitle"] =
                    res["totalTrainingCostInformationTitle"];
                }
              }
            });
          }
        });
      }
    },

    model(tabIndex, fieldName) {
      return this.tabs[tabIndex].models[fieldName];
    },

    checkToggleTarget(models) {
      if (models && Object.keys(models).length) {
        Object.values(models).forEach((model) => {
          formManager.checkToggleTarget(models, model, true);
        });
      }
    },

    submit(status = 2) {
      this.loading = true;
      this.invalidTab = null;

      this.put(status).then((data) => {
        if (data && !data.hasError) {
          this.init();
          notificationManager.showNotification(
            "success",
            this.$tc("global.edit.success", 1, {
              name: this.$t("studea_manager.group.title"),
            }),
          );
        } else if (data.responses && data.responses[0].validationErrors) {
          this.loading = false;
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
          Object.entries(this.tabs).forEach((tab) => {
            const errors = formValidator.processResponses(
              data.responses[0].validationErrors,
              tab[1].models,
            );
            if (errors.length) {
              this.invalidTab = this.id ? tab[1] : tab[0];
            }
          });
        }
      });
    },

    async put(status, calculationMode = false) {
      let response = [];
      Object.values(this.tabs).forEach((tab) => {
        if (!tab.hidden) {
          response.push({
            form: tab.models ? formManager.processForm(tab.models, false) : [],
            code: tab.code,
          });
        }
      });

      if (status && response[0]) {
        response[0].form["status"] = status;
      }

      const urlCall =
        "/app/studea-manager/group/edit/" +
        this.id +
        (calculationMode ? "?calculationMode=1" : "");
      return apiConnection.put(urlCall, response).then((data) => data);
    },

    validTab() {
      this.invalidTab = null;
    },

    valid() {
      const valid = formValidator.validTabs(this.tabs);
      if (valid) {
        this.submit(3);
      }
    },

    save() {
      this.submit(2);
    },

    unlock() {
      this.submit(2);
    },

    cancel() {
      this.$router.push({
        name: "TrainingDashboard",
        params: { id: this.$route.params.id },
      });
    },

    ...mapActions(useMainStore, ["setAppLoading"]),
  },
  components: {
    FormFieldComponent,
    FormTabsBlockComponent,
  },
};
</script>

<style lang="scss" scoped></style>
