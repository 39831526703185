import { apiConnection } from "@/services/api-connection";

const URL = "/app/studea-manager/training/list";
const URL_ONE = "/app/studea-manager/training/get/";

class TrainingManager {
  list = (params = {}) => {
    return apiConnection.get(URL, params, true).then((response) => response);
  };

  form = (type, id = null) => {
    return apiConnection
      .get(
        "/app/studea-manager/training/" + type + "/form" + (id ? "/" + id : ""),
      )
      .then((response) => response);
  };

  get = (id) => {
    return apiConnection.get(URL_ONE + id).then((response) => response);
  };
}

export const trainingManager = new TrainingManager();
