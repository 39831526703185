<template>
  <div class="form-group mt-3 text-center" :class="{ '-sticky': !standAlone }">
    <div class="buttons">
      <button
        v-if="!hideSubmit"
        :class="
          'btn btn-' +
          (customButtons && Object.keys(customButtons).length
            ? 'info'
            : 'success') +
          (defaultColors || (customButtons && Object.keys(customButtons).length)
            ? ''
            : ' btnStyle') +
          (itemModel && itemModel.stacked ? ' w-100 mb-2' : ' mx-2')
        "
        :disabled="loading"
        @click="submit($event)"
      >
        {{ getSubmitText() }}
      </button>
      <template v-if="customButtons && Object.keys(customButtons).length">
        <button
          type="button"
          v-for="button in customButtons"
          :key="button.label"
          :disabled="loading"
          :class="
            'btn btn-' +
            (button.color ? button.color : '') +
            (defaultColors || button.color ? '' : ' btnStyle') +
            (itemModel && itemModel.stacked ? ' w-100 mb-2' : ' mx-2')
          "
          @click="custom($event, button)"
        >
          {{ button.label }}
        </button>
      </template>
      <button
        v-if="
          !itemModel ||
          !Object.keys(itemModel).length ||
          (itemModel && itemModel.vars && !itemModel.vars.hideCancel)
        "
        :class="
          'btn btn-danger' +
          (itemModel && itemModel.stacked ? ' w-100' : ' mx-2')
        "
        :disabled="loading"
        @click="cancel($event)"
      >
        {{
          itemModel && itemModel.cancelText
            ? itemModel.cancelText
            : $t("cancel")
        }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubmitTypeComponent",
  props: {
    model: {
      type: Object,
      required: false,
    },
    defaultColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    standAlone: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitText: {
      type: String,
      required: false,
      defautl: null,
    },
    customButtons: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      itemModel: this.model,
    };
  },
  components: {},
  methods: {
    submit(e) {
      e.preventDefault();
      this.$emit("submitForm");
    },

    cancel(e) {
      e.preventDefault();
      this.$emit("cancelForm");
    },

    custom(e, button) {
      e.preventDefault();
      this.$emit("customForm", button);
    },

    getSubmitText() {
      if (this.submitText) {
        return this.submitText;
      } else {
        return this.itemModel && this.itemModel.submitText
          ? this.itemModel.submitText
          : this.$t("save");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}

.widget {
  @include dark-theme {
    margin-top: 20px;
  }
}

.form-group {
  &.-sticky {
    position: sticky;
    bottom: 40px;
    left: 0;
    margin: 0;
    z-index: 49;

    .buttons {
      display: inline-block;
      background: rgba(255, 255, 255, 0.8);
      padding: 0.8rem 1rem;
      border: 1px solid $m-color_3;
      border-radius: $radius;

      @include dark-theme {
        background: rgba(0, 0, 0, 0);
        border: 0px;
      }
    }
  }
}
</style>
