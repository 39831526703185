import { i18n } from "@/i18n";
import LayoutView from "@/views/Training/LayoutView.vue";
import TrainingListView from "@/views/Training/TrainingListView.vue";
import TrainingDashboardView from "@/views/Training/TrainingDashboardView.vue";
import ContractSheetView from "@/views/Registration/ContractSheetView.vue";
import TrainingGroupAddEditView from "@/views/Training/TrainingGroupAddEditView.vue";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";
import AddEditView from "@/views/StudeaManager/AddEditView.vue";
import RegistrationsListView from "@/views/Registration/RegistrationsListView.vue";

export default {
  path: "/training",
  name: "Training",
  component: LayoutView,
  redirect: { name: "TrainingList" },
  meta: {
    breadcrumb: true,
    authRequired: true,
    title: i18n.global.t("page.training_list"),
  },
  children: [
    {
      path: "list",
      name: "TrainingList",
      component: TrainingListView,
      meta: {
        title: i18n.global.t("page.training_list"),
        breadcrumb: false,
      },
    },
    {
      path: ":id",
      name: "TrainingDashboard",
      component: GlobalLayoutView,
      meta: {
        title: i18n.global.t("page.training_dashboard"),
        breadcrumb: true,
      },
      redirect: { name: "TrainingDashboard" },
      children: [
        {
          path: "dashboard",
          name: "TrainingDashboard",
          component: TrainingDashboardView,
          meta: {
            title: i18n.global.t("page.training_dashboard"),
            breadcrumb: false,
          },
        },
        {
          path: "group/edit/:groupId",
          name: "TrainingGroupAddEdit",
          component: TrainingGroupAddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.group.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
          },
        },
        {
          path: ":alias(training)/edit",
          name: "TrainingEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.training.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            notGeneric: true,
            backPath: "TrainingDashboard",
          },
        },
      ],
    },
    {
      path: "registration/list",
      name: "RegistrationList",
      component: RegistrationsListView,
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global
            .tc("studea_manager.registration.title", 1)
            .toLowerCase(),
        }),
        breadcrumb: true,
      },
    },
    {
      path: "contract/:id",
      name: "TrainingContract",
      component: ContractSheetView,
      meta: {
        title: i18n.global.t("page.contract"),
        breadcrumb: true,
      },
    },
  ],
};
