export default {
  global: {
    list: {
      title: "List of {name}",
    },
    view: {
      title: "Data for {name}",
    },
    add: {
      title: ({ plural, named }) =>
        plural([`Add a ${named("name")}`, `Add a ${named("name")}`]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} added successfully!`,
          `${named("name")} added successfully!`,
        ]),
    },
    edit: {
      title: ({ plural, named }) =>
        plural([
          `Edit ${named("name")}`,
          `Edit a ${named("name")}`,
          `Edit a ${named("name")}`,
        ]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} edited successfully!`,
          `${named("name")} edited successfully!`,
        ]),
    },
    active: {
      title: ({ plural, named }) =>
        plural([
          `Activate ${named("name")}`,
          `Activate a ${named("name")}`,
          `Activate a ${named("name")}`,
        ]),
      sub_title: ({ plural, named }) =>
        plural([
          `Are you sure you want to activate this ${named("name")}?`,
          `Are you sure you want to activate this ${named("name")}?`,
          `Are you sure you want to activate ${named("name")}?`,
        ]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} activated successfully !`,
          `${named("name")} activated successfully !`,
        ]),
    },
    import: {
      errors:
        "Your file contains errors. Please correct them and restart the import.",
      title: "Import {name}",
      error: "Error in line {line} / column {column}:",
      success: ({ plural, named }) =>
        plural([
          `${named("name")} imported successfully!`,
          `${named("name")} imported successfully!`,
        ]),
    },
    export: {
      title: "Export {name}",
      title_alone: "Export",
      success: ({ plural, named }) =>
        plural([
          `${named("name")} exported successfully!`,
          `${named("name")} exported successfully!`,
        ]),
    },
    update: {
      title: "Complete your {name}",
      success: "{name} completed successfully",
    },
    search: {
      title: "Search for {name}",
    },
    sort: {
      success: "Order of {name} updated successfully!",
    },
    download: {
      success: "{name} downloaded successfully!",
      title: "Download {name}",
    },
    move: {
      success: ({ plural, named }) =>
        plural([
          `${named("name")} moved successfully!`,
          `${named("name")} moved successfully!`,
        ]),
    },
    copy: {
      success: ({ plural, named }) =>
        plural([
          `${named("name")} duplicated successfully!`,
          `${named("name")} duplicated successfully!`,
        ]),
      title: ({ plural, named }) =>
        plural([
          `Duplicate this ${named("name")}`,
          `Duplicate this ${named("name")}`,
        ]),
      sub_title: ({ plural, named }) =>
        plural([
          `Are you sure you want to duplicate this ${named("name")}?`,
          `Are you sure you want to duplicate this ${named("name")}?`,
        ]),
    },
    empty: {
      title: ({ plural, named }) =>
        plural([
          `There is no ${named("name1")} in this ${named("name2")}, please click ‘+’ to add one`,
          `There is no ${named("name1")} in this ${named("name2")}, please click ‘+’ to add one`,
          `No ${named("name1")} found in this ${named("name2")}, please click ‘+’ to add one`,
        ]),
      dashboard: "No {name} found",
      no_data_available: "No data available",
    },
    delete: {
      title: ({ plural, named }) =>
        plural([
          `Delete ${named("name")}`,
          `Delete a ${named("name")}`,
          `Delete a ${named("name")}`,
        ]),
      sub_title: ({ plural, named }) =>
        plural([
          `Are you sure you want to delete a ${named("name")}?`,
          `Are you sure you want to delete a ${named("name")}?`,
          `Are you sure you want to delete ${named("name")}?`,
        ]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} deleted successfully!`,
          `${named("name")} deleted successfully!`,
          `${named("name")} deleted successfully!`,
          `${named("name")} deleted successfully!`,
        ]),
    },

    label: {
      name: "Surname",
      date: "Date",
      triads: "Three-party groups",
    },
    actions: {
      title: "Actions",
      empty: "No action currently available",
    },
    see_more: "See more...",
    close: "Close",
    show_hide_actions: "Display/Hide actions",
    visible: "Visible",
    hidden: "Hidden",
    send: "Send",
    stats: "Statistics",
    setting: "Settings",
    module: "Modules",
    control: "Control",
    light: "light ",
    dark: "Dark",
  },
};
