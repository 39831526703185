<template>
  <div class="widget-heading">
    <h2 class="textStyle">
      {{ $t("skills") }}
    </h2>
  </div>
  <div class="widget-content position-relative">
    <BlockLoader :loading="loading" />
    <div v-for="session in skillStatistic" :key="session.id">
      <SkillSessionBlockComponent :session="session" />
    </div>
  </div>
</template>

<script>
import SkillSessionBlockComponent from "@/components/skills/SkillSessionBlockComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";

export default {
  name: "SkillSessionListBlockComponent",
  props: {
    skillStatistic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  emits: [],
  computed: {},
  mounted() {},
  methods: {},
  components: { BlockLoader, SkillSessionBlockComponent },
};
</script>

<style scoped lang="scss"></style>
