<template>
  <div v-if="checkable" class="list__column list__checkable">
    <div class="checkbox-primary custom-control custom-checkbox">
      <input
        :id="'item-' + idKey ? row[idKey] : row.id ? row.id : id"
        v-model="listCheck"
        :checked="checkAllRows"
        :value="idKey ? row[idKey] : row.id ? row.id : id"
        class="custom-control-input"
        name="listCheck"
        type="checkbox"
        @change="changeListCheck"
      />
      <label
        :for="'item-' + idKey ? row[idKey] : row.id ? row.id : id"
        class="custom-control-label"
      ></label>
    </div>
  </div>
  <FontAwesomeIcon
    v-if="row[4] === 'Non'"
    class="me-1"
    icon="fa-light fa-triangle-exclamation"
    style="color: orange; margin-top: 10px"
  />
  <FontAwesomeIcon
    v-if="row[4] === 'Oui'"
    class="me-1 text-success"
    icon="fa-light fa-check"
    style="margin-top: 10px"
  />
  <div
    v-for="column in columnsItem"
    :key="column.name"
    :class="
      (column.textOverflowHidden ? 'text-overflow-hidden ' : '') +
      'list__column width w-' +
      nbColumns +
      (type === 'training' && column.name === 'Formation'
        ? ' training-col'
        : '')
    "
  >
    <div
      v-if="
        typeof (row.keyName
          ? row[keyName][column.name]
          : row[column.index ? column.index : column.name]) === 'boolean' &&
        (!type || (type && type !== 'absence'))
      "
      class="d-flex align-items-center"
    >
      <div class="d-lg-none col-5">
        <SortTableMobileFirstColumnComponent
          :nbRows="nbColumns"
          :rows="column"
        />
      </div>
      <span
        :class="
          'col-lg-12 col-8 font-size list__dot bg-' +
          ((
            row.keyName
              ? row[keyName][column.name]
              : row[column.index ? column.index : column.name]
          )
            ? 'success'
            : 'danger')
        "
      >
      </span>
    </div>
    <span v-else-if="type === 'mail' && column.index === '4'">
      <span
        class="list__dot"
        :class="{
          'bg-success': row[column.index] === 1,
          'bg-warning': row[column.index] === 0,
          'bg-danger': row[column.index] === 2,
        }"
      ></span>
    </span>
    <span
      v-else-if="
        typeof row[column.index ? column.index : column.name] === 'boolean'
      "
    >
      <div class="d-flex align-items-center" v-if="type && type === 'absence'">
        <div class="d-lg-none col-5">
          <SortTableMobileFirstColumnComponent
            :nbRows="nbColumns"
            :rows="column"
          />
        </div>
        <div class="col-lg-12 col-8 font-size">
          {{
            row[column.index ? column.index : column.name] === true
              ? "Oui"
              : "Non"
          }}
          <a
            :href="url"
            class="icon-link"
            v-if="
              row[nbColumns] &&
              row[column.index ? column.index : column.name] === true
            "
            >{{ row[column.index ? column.index : column.name][nbColumns] }}
            <FontAwesomeIcon icon="fa-light fa-download" />
          </a>
        </div>
      </div>
    </span>
    <span
      v-else-if="
        typeof row[column.index ? column.index : column.name] === 'object' &&
        row[column.index ? column.index : column.name]
      "
      class="d-flex align-items-start"
    >
      <div class="d-lg-none col-5">
        <SortTableMobileFirstColumnComponent
          :nbRows="nbColumns"
          :rows="column"
        />
      </div>
      <div class="col-7 col-lg-12">
        <div
          v-if="type && type === 'institution'"
          class="col-lg-12 col-8 font-size"
        >
          <SortTableInstitutionCellComponent
            :institution="row[column.index ? column.index : column.name]"
            :column="column"
            :row="row"
            @initModel="initModel"
          />
        </div>
        <div
          v-else-if="type && (type === 'training' || type === 'front-training')"
          v-for="item in sortPromotionByStartDate(
            row[column.index ? column.index : column.name],
          )"
          :key="item"
          class="d-flex align-items-center justify-content-lg-start justify-content-between -institution institution__actions"
        >
          <router-link
            :to="{
              name: path,
              params:
                type === 'training'
                  ? { id: item.id }
                  : { trainingCourseId: item.id },
              query:
                type === 'training'
                  ? {
                      breadcrumbName:
                        row[0] + '<br>' + row[1] + ' | ' + item.label,
                    }
                  : {},
            }"
            :class="'list__link ' + (item.archive ? ' -red' : ' text-info')"
            v-access:[permissions]="accessManager().EDIT"
          >
            {{ item.label }}
            <span class="nbTriad">({{ item.nbTriad }})</span>
          </router-link>
          <div
            class="ms-2 d-flex align-items-center"
            v-if="type === 'training'"
          >
            <router-link
              v-tooltip="{
                title: this.initSettingsTooltip(),
              }"
              :to="{
                name: 'studeaManagerHome',
                params: { trainingCourseId: item.id },
              }"
              class="btn-icon"
            >
              <FontAwesomeIcon class="icon" icon="fa-light fa-gear" />
            </router-link>
            <button
              class="btn-icon btn-group"
              v-tooltip="{
                title: $t('admin.trainingCourse.duplicate_on_next_year'),
              }"
              v-access:[permissions]="accessManager().EDIT"
              type="button"
              @click="duplicateTrainingCourseOnNextYear(item.id)"
            >
              <FontAwesomeIcon icon="fa-light fa-plus" />
            </button>
            <button
              class="btn-icon btn-group"
              v-tooltip="{ title: $t('admin.trainingCourse.duplicate') }"
              type="button"
              v-access:[permissions]="accessManager().EDIT"
              @click="duplicateTrainingCourse(item.id)"
            >
              <FontAwesomeIcon icon="fa-light fa-copy" />
            </button>
            <button
              class="btn-icon btn-group"
              v-tooltip="{ title: $t('admin.trainingCourse.delete') }"
              type="button"
              v-access:[permissions]="accessManager().DELETE"
              @click="removeTrainingCourse(item.id)"
            >
              <FontAwesomeIcon icon="fa-light fa-trash" />
            </button>
          </div>
          <div
            v-else-if="type === 'front-training'"
            class="ms-2 d-flex align-items-center"
          >
            <button
              class="btn-icon btn-group"
              v-tooltip="{ title: $t('dashboard.training.triad_list') }"
              type="button"
              @click="showTriadList(item.id)"
            >
              <FontAwesomeIcon icon="fa-light fa-users" />
            </button>
          </div>
        </div>
      </div>
    </span>
    <span
      v-else-if="type === 'incident_sheet' && column.name === 'Status'"
      class="d-flex align-items-start"
      >{{
        row[column.index] === 1
          ? $t("studea_manager.incident_sheet.in_progress")
          : $t("studea_manager.incident_sheet.closed")
      }}</span
    >
    <span v-else class="d-flex align-items-start">
      <div class="d-lg-none col-5">
        <SortTableMobileFirstColumnComponent
          :nbRows="nbColumns"
          :rows="column"
        />
      </div>
      <div class="col-7 col-lg-12 font-size d-flex align-items-center">
        <span
          :class="
            column.name === 'e-mail' ? 'd-block sortable__cell__text' : 'w-100'
          "
          v-tooltip="{
            title: column.name === 'e-mail' ? row[column.index] : '',
          }"
        >
          {{
            /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?(?:Z|[+-][0-2]\d:[0-5]\d)?/.test(
              row.keyName
                ? row[keyName][column.name]
                : row[column.index ? column.index : column.name],
            )
              ? formatDate(
                  row.keyName
                    ? row[keyName][column.name]
                    : row[column.index ? column.index : column.name],
                )
              : row.keyName
                ? row[keyName][column.name]
                : row[column.index ? column.index : column.name]
                  ? row[column.index ? column.index : column.name]
                  : "--"
          }}
        </span>
        <span
          v-if="
            type === 'institution' &&
            column.index === '0' &&
            row[row.length - 1] !== null &&
            typeof row[row.length - 1] === 'string'
          "
          v-tooltip="{
            title:
              $t('admin.user.last_connection') +
              formatDate(row[row.length - 1]),
          }"
          class="pellet"
        ></span>
      </div>
    </span>
  </div>
  <div
    v-if="Object.keys(actions).length"
    :class="'list__action -row ' + (actions.length <= 2 ? 'small' : '')"
  >
    <ListActionsBlockComponent
      :id="
        idKey ||
        (idKey !== undefined && typeof idKey === 'number' && row[idKey])
          ? row[idKey]
          : row.id
            ? row.id
            : id
      "
      :actions="actionsItem"
      :row="row"
      :hideAction="setHideAction(row, columns)"
      @click="clickAction"
    />
  </div>
</template>

<script>
import ListActionsBlockComponent from "@/components/blocks/ListActionsBlockComponent.vue";
import SortTableInstitutionCellComponent from "@/components/table/sortTable/SortTableInstitutionCellComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fileManager } from "@/services/file/file-manager";
import SortTableMobileFirstColumnComponent from "@/components/table/sortTable/SortTableMobileFirstColumnComponent.vue";
import { sortManager } from "@/services/utilities/sort-manager";
import { accessManager } from "@/services/security/access-manager";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";
import { driverLicenceManager } from "@/services/driver-licence/driver-licence-manager";

export default {
  name: "SortTableRowComponent",
  components: {
    SortTableMobileFirstColumnComponent,
    FontAwesomeIcon,
    SortTableInstitutionCellComponent,
    ListActionsBlockComponent,
  },
  props: {
    checkable: {
      default: false,
      type: Boolean,
      required: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      required: false,
    },
    idKey: {
      default: 0,
      type: Number,
      required: false,
    },
    row: {
      type: Array,
      required: true,
    },
    nbColumns: {
      type: Number,
      required: true,
    },
    id: {
      type: [Number, String],
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    path: {
      default: "",
      type: String,
      required: false,
    },
    checkAllRows: {
      type: [Boolean, Number],
      required: false,
    },
    dateFormat: {
      default: "short",
      type: String,
      required: false,
    },
  },
  data() {
    return {
      settingsTooltipTitle: "",
      listCheck: [],
      url: this.row[this.row.length - 1]
        ? fileManager.getDownloadSrc(this.row[this.row.length - 1])
        : "",
      columnsItem: [],
      actionsItem: [],
    };
  },
  emits: ["initModel", "clickAction"],
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
    }),
  },
  mounted() {
    this.getColumnsItem();
    this.setActionsItem();
  },
  watch: {
    columns() {
      this.getColumnsItem();
    },
  },
  methods: {
    initSettingsTooltip() {
      if (this.design && this.design.label) {
        return (this.settingsTooltipTitle =
          this.$tc(
            "studea_manager.home.title",
            this.design.label === "Groupe" ? 2 : 1,
          ) + this.$t(this.design.label).toLowerCase());
      }
    },
    accessManager() {
      return accessManager;
    },
    formatDate(date) {
      return dateFormatter.format(date, this.dateFormat);
    },

    changeListCheck() {
      this.$emit("changeListCheck", this.listCheck);
    },

    initModel(row, columnIndex, columnName) {
      this.$emit("initModel", row, columnIndex, columnName);
    },

    duplicateTrainingCourseOnNextYear(id) {
      this.$emit("clickAction", {
        action: { action: "duplicateTrainingCourseOnNextYear" },
        id: id,
      });
    },

    duplicateTrainingCourse(id) {
      this.$emit("clickAction", {
        action: { action: "duplicateTrainingCourse" },
        id: id,
      });
    },

    removeTrainingCourse(id) {
      this.$emit("clickAction", {
        action: { action: "removeTrainingCourse" },
        id: id,
      });
    },

    showTriadList(id) {
      this.$emit("clickAction", {
        action: { action: "showTriadList" },
        id: id,
      });
    },

    clickAction(action) {
      this.$emit("clickAction", action);
    },

    sortPromotionByStartDate(column) {
      return sortManager.sort(column, "startYear", "DESC");
    },

    setHideAction(row, columns) {
      if (this.type === "driver_licence") {
        return !(
          columns[8].name === "Statut" &&
          row[8] === driverLicenceManager.DRIVER_LICENCE_REQUEST_STATUS_PAYED &&
          typeof row[7] !== "string"
        );
      }
    },

    getColumnsItem() {
      if (this.type === "driver_licence") {
        this.columnsItem = this.columns.filter(
          (item) => item.name !== "Statut",
        );
      } else {
        this.columnsItem = this.columns;
      }
    },

    setActionsItem() {
      if (this.type === "driver_licence" && typeof this.row[7] === "string") {
        this.actionsItem = [
          {
            translation: "overview",
            icon: "eye",
            type: "success",
            action: "overviewDriverLicenceRequest",
            cfaStyle: false,
            access: accessManager.EDIT,
          },
          {
            translation: "download",
            icon: "download",
            type: "success",
            action: "downloadDriverLicenceFile",
            cfaStyle: false,
            access: accessManager.EDIT,
          },
        ];
      } else {
        this.actionsItem = this.actions;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__link {
    font-weight: 700;

    &.-red {
      color: $red;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__column {
    padding-right: 15px;
    padding-top: 8px;
    @include mobile {
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }

    @include up-to-lg {
      padding: 5px;
    }

    &:nth-child(2n + 1) {
      @include up-to-lg {
        background-color: $lighter-grey;
      }
    }

    &.list__checkable {
      @include up-to-lg {
        background-color: transparent;
      }
    }

    &.-heading {
      text-transform: capitalize;
    }

    &.text-overflow-hidden {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.w-9 {
      width: calc(83% / 9);
    }

    &.w-8 {
      width: calc(83% / 8);
    }

    &.w-7 {
      width: calc(83% / 7);
    }

    &.w-6 {
      width: calc(83% / 6);
    }

    &.w-5 {
      width: calc(83% / 5);
    }

    &.w-4 {
      width: calc(83% / 4);
    }

    &.w-3 {
      width: calc(83% / 3);
    }

    &.w-2 {
      width: calc(83% / 2);
    }

    &.w-1 {
      width: 83%;
    }

    @include up-to-lg {
      &.w-1,
      &.w-2,
      &.w-3,
      &.w-4,
      &.w-5,
      &.w-6,
      &.w-7 {
        width: 100%;
      }

      &:nth-child(1) {
        font-weight: 700;
        font-size: 16px;
      }
    }

    .nbTriad {
      color: dimgray;
      font-size: 10px;
    }

    .btn-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-size: 14px;

      &:hover,
      &:focus {
        border: 1px solid $blue;
      }
    }

    .btn-group {
      font-size: 11px;
    }

    .settings {
      width: 35px;
      height: 30px;
      position: relative;

      &:hover {
        font-size: 20px;
        color: $green;
        border: none;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 12px;
        width: 1px;
        background: var(--primary-color);

        @include up-to-md {
          content: initial;
        }
        @include mobile {
          content: "";
        }
      }
    }

    .icon-link {
      cursor: pointer;
      margin-left: 10px;
      font-size: 16px;
      width: 22px;
      height: 22px;
      padding: 0;
      color: $primary;
    }
  }

  &__checkable {
    width: 30px;
    flex: initial;
    padding: 0;
    position: relative;
    top: 5px;

    .custom-control-label {
      position: initial;

      &:before,
      &:after {
        left: 0;
      }
    }

    @include up-to-lg {
      position: absolute;
      right: unset;
      left: 5px;
      top: 3px;
    }
  }

  &__dot {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  &__action {
    width: 15%;

    &.-row {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    &.small {
      width: 7%;
      @include up-to-lg {
        width: 100%;
      }
    }

    @include up-to-lg {
      width: 100%;
      margin: 8px 0;
    }
  }
}

.-institution:not(:last-child) {
  @include mobile {
    margin-bottom: 10px;
  }
}

.font-size {
  @include up-to-lg {
    font-size: 12px;
  }
}

.width {
  @include up-to-lg {
    width: 100% !important;
  }
}

.institution__actions {
  &:not(:first-child) {
    margin-top: 5px;
  }
}

.training-col {
  width: 22% !important;

  @include up-to-lg {
    width: 100% !important;
  }
}

.driver_licence_list {
  display: flex;
  margin-bottom: 0;
  list-style: none;
  gap: 5px;
  flex-wrap: wrap;
  padding-left: 0;
}

.driver_licence_item {
  padding: 0 5px;
  border-radius: 10px;
  border: 1px solid $black;

  &.active {
    background-color: $green;
    border-color: $green;
    color: $white;
  }
}

.pellet {
  margin-left: 10px;
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
  background-color: $success;
}

.sortable__cell__text {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  text-wrap: nowrap;
}
</style>
