<template>
  <h2
    v-if="model && model.vars && model.vars.label"
    class="titleType"
    :class="{ bordered: model.vars.separator }"
  >
    {{ model.vars.label }}
    <span
      v-if="model.vars.help && !isMobile()"
      class="form-info ms-1"
      v-tooltip="{ title: model.vars.help }"
    >
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
    </span>
  </h2>
</template>

<script>
export default {
  name: "TitleTypeComponent",
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isMobile() {
      return window.innerWidth < 992;
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  &.bordered {
    border-top: 1px dashed $m-color_6;
    margin-top: 15px;
    padding: 15px 0 5px;
  }
}
</style>
