export default {
  admin: {
    help_settings: "Help settings",
    help_settings_success: "Help settings edited successfully!",
    lastEmail: "Link to last e-mail sent",
    generatePassword: "Regenerate and send password",
    switchUser: "Log in as {name}",
    switchAdmin: "You are logged in as",
    modify: "Edit {title}",
    delete: "Delete {title}",
    add: "Add",
    name: "Surname",
    title: "Title",
    year: "Year",
    trainingOpeningStart: "Start of course opening",
    trainingOpeningEnd: "End of course opening",
    level: "Level",
    registrationDate: "Registration date",
    active: "Active",
    management_of: "Management of",
    contents: "content",
    users: "Users",
    trainings: "Courses",
    modules: "Modules",
    module: "Module",
    code: "Code",
    error: "Error. Please try again later!",
    lastName: "Surname",
    firstName: "First name",
    profile: "Profile",
    embody: "Log in as",
    visible: "Visible",
    popup: "Popup",
    visibleHomeLea: "Visible",
    endDate: "End date",
    startDate: "Start date",
    contractTypeColor: "Contract colour",
    none_duplicated_user: "No user to merge",
    skill: {
      title: "Skill",
    },
    skillCategory: {
      title: "Category",
    },
    skill_session: {
      generate: "Generate periods",
      empty:
        "Please enter the number of periods to be created in the module configuration before applying changes",
      generation_success: "Periods generated successfully",
      existing_session:
        "You are about to delete existing periods. Are you sure you want to continue?",
    },
    skillReferential: {
      title: "Skills reference documentation",
      edit: "Edit reference documentation",
    },
    navigation: {
      users: "Users",
      trainings: "Courses",
      modules: "Modules",
      settings: "Settings",
      general: "General",
      steering: "Steering",
    },
    home: {
      title: "General configuration",
      title2: "Overview",
      title3: "Automatic sync",
      title4: "General statistics",
      active: "Activated",
      unique_connection: "unique connection",
      apprentices_mentor: "Apprentices and mentors",
    },
    log: {
      title: ({ plural }) => plural(["Log", "Logs"]),
      view: "See modified data",
      modify_field: "Field modified",
      old_data: "Old data",
      data: "New data",
    },
    mail: {
      title: ({ plural }) => plural(["Mail", "Mails"]),
      view: "See mail content",
      recipient: "Recipient:",
      status: "Sending status:",
      sent_on: "Sent on:",
      open_on: "Opened on:",
    },
    registrationField: {
      title: ({ plural }) => plural(["Field", "Fields"]),
    },
    registrationBlock: {
      title: ({ plural }) => plural(["Block", "Blocks"]),
    },
    registrationTab: {
      title: ({ plural }) => plural(["Tab", "Tabs"]),
    },
    registrationStep: {
      title: ({ plural }) => plural(["Step", "Steps"]),
    },
    registrationDevice: {
      title: ({ plural }) => plural(["Device", "Devices"]),
    },
    registrationDataConfiguration: "Modify version settings",
    cerfaParam: {
      title: ({ plural }) => plural(["Cerfa settings", "Cerfa settings"]),
    },
    role: {
      title: ({ plural }) => plural(["Role", "Roles"]),
    },
    triad: {
      title: ({ plural }) =>
        plural(["Three-party group", "Three-party groups"]),
    },
    studeaUser: {
      title: ({ plural }) => plural(["User", "Users"]),
    },
    user: {
      title: ({ plural }) => plural(["User", "Users"]),
      actions: {
        keep_user: "Retain",
        delete_user: "Delete",
        unselect_user: "Deselect these users",
        reset_password: {
          success:
            "A password reset link has been sent to the user successfully!",
        },
      },
      search_user_by_name_or_mail:
        "Search for a user by name or by e-mail address",
      import_users: "Import users",
      profile_config: "Profile configuration",
      merge: "Merge users",
      duplicated_user_count: "duplicated users to be merged",
      merge_duplicated_user_confirmation:
        "Are you sure you want to merge these users?",
      merge_success: "Merge completed successfully",
      no_double_user: "No duplicate found",
      mail_address: "E-mail address",
      already_connected: "Already logged in to the booklet",
      yes: "Yes",
      no: "No",
      row: "Line",
      reference_user: "User reference",
      duplicated_user: "Duplicates",
      reset_choices: "Reset selection",
      options: "Options",
      account_creation: "Account creation",
      username: "Username",
      external_id: "External ID",
      birth_date: "Date of birth",
      send_credential_back_to_user: "Send log-in details to the relevant user",
      reference_user_is_not_duplicate: "The user reference is not a duplicate",
      keep: "Retain",
      delete: "Delete",
      export: "Export",
      send: "Save",
      profile_title: "User profile titles",
      users_profile_title_list: "List of user profile titles",
      modify_profile: "Edit title",
      modification_succes: "Profile edited successfully!",
      no_mail_title: "No link",
      no_mail: "This user has not yet received a password reset link!",
      profile_user_info:
        "Create different booklet supervisor profiles with specific rights that can subsequently be attached to users. Example: Attendance manager, teaching manager, etc.",
      role: {
        NONE: "No",
        ALL: "Yes",
        VIEW: "View",
        EDIT: "Change",
        DELETE: "Delete",
      },
    },
    profile_label: {
      titleAbbreviation: "Abbreviation",
      titlePlural: "Title in the plural",
      titleWithArticle: "Title with article",
    },
    training: {
      title: ({ plural }) => plural(["Training", "Courses"]),
      ufa_add_test: "Create a test training course",
      genere_triad_test: "Generate test three-party groups",
      generate_triad_test_confirmation:
        "Are you sure you want to create test three-party groups for all training courses? ",
      genere_triad_test_success:
        "Test three-party groups created successfully!",
      genere_training_test_success:
        "Test training course created successfully!",
      import_triad: "Import profiles of the three-party group",
    },
    registration: {
      title: ({ plural }) => plural(["Registration", "Registrations"]),
    },
    trainingCourse: {
      title: ({ plural }) => plural(["Promotion", "Promotions"]),
      duplicate_on_next_year: "Duplicate the year group for the following year",
      duplicate: "Duplicate the year group",
      delete: "Delete the year group",
    },
    studeaManager: {
      title: ({ plural }) =>
        plural(["Digital Booklet supervisor", "Digital booklet supervisors"]),
    },
    design: {
      title: "Layout",
    },
    dataAgreement: {
      title: ({ plural }) => plural(["Data policy", "Data policy"]),
    },
    institution: {
      title: ({ plural }) => plural(["Establishment", "Institutions"]),
      list: {
        title: "Active institutions",
      },
      export: {
        title: "Export the responses to the previous year's questionnaires",
      },
    },
    document: {
      title: ({ plural }) => plural(["Public document", "Public documents"]),
    },
    documentCategory: {
      title: ({ plural }) =>
        plural(["Document category", "Document categories"]),
    },
    helpDocument: {
      title: ({ plural }) => plural(["Online help", "Online help files"]),
    },
    component: {
      title: ({ plural }) => plural(["Component", "Components"]),
      export: {
        title: "Export the responses to the previous year's questionnaires",
      },
    },
    degree: {
      title: ({ plural }) =>
        plural(["Level of training", "Training course levels"]),
    },
    contractType: {
      title: ({ plural }) => plural(["Type of contract", "Types of contract"]),
    },
    planContract: {
      title: ({ plural }) => plural(["Year of training", "Years of training"]),
      actions: {
        activate: {
          title: "Activate",
          success: "Year of training activated successfully!",
        },
        activate_recruitment: {
          title: "Activate for recruitment",
          success: "Activated for recruitment successfully!",
        },
      },
    },
    titleRncp: {
      title: ({ plural }) => plural(["RNCP title", "RNCP titles"]),
    },
    modulet: {
      title: ({ plural }) => plural(["Module", "Modules"]),
      list: {
        title: "Module management",
      },
      trainingCourse: {
        edit: {
          title: "Edit the training courses linked to the module",
          success: "Training courses linked to the module edited successfully!",
        },
        displayActive: "Only display the training courses linked to the module",
      },
      config: {
        title: "Module configuration",
      },
      skill_session: {
        title: "Generate periods",
      },
      registration: {
        title: "Registration dashboard",
      },
      skill_referential: {
        title: "Skills reference documentation",
        list: "Reference documentation",
        edit: "Edit reference documentation",
      },
    },
    template: {
      title: ({ plural }) => plural(["Template", "Templates"]),
    },
    absenceReason: {
      title: ({ plural }) =>
        plural(["Reason for absence", "Reasons for absence"]),
    },
    questionnaireConfig: {
      title: "Questionnaire module configuration",
    },
    ypareoConfig: {
      title: "Ypareo module configuration",
    },
    studeaManagerProfile: {
      title: ({ plural }) =>
        plural(["Booklet supervisor profile", "Booklet supervisor profiles"]),
      all_access: "Enable all permissions",
    },
    historic: {
      title: "History",
    },
    statistic: {
      title: "Statistics",
      general: "General",
      questionnaires: "Questionnaires",
      absence: "Absences",
      course: "Lesson follow-up",
      updated_on: "updated on",
      indicators_generated: "Additional indicators being generated",
    },
    export: {
      title: "Exports",
      year: "Year groups",
      available_exports: "Available exports",
    },
    duplicate: {
      title: "Duplicates",
    },
    archive: {
      title: "Archive",
    },
    news: {
      title: ({ plural }) => plural(["News", "News"]),
      news_list: "List of news",
      list: {
        title: "News",
        uploaded_date: "Uploaded on",
        see_more: "See more",
        see_all_news: "See all news",
        go_back_newslist: "Back to the list of news items",
      },
    },
    import: {
      modele: "File template download",
      triad_new_file:
        "Please note - a new template came online on 18/09/2023.\n" +
        "          Please download this updated version for use to populate your three-party groups.",
      triad_update:
        "Please note. Only the missing members of a three-party group will be updated. No existing user data (last name, first name or e-mail address) will be updated.",
      thanks: "Please do not edit and/or delete the template columns",
      contract_type_id: "ID types de contrat (colonne AM)",
    },
    text: "Text",
    dateToDisplay: "Post date",
    emailContent: "E-mail content",
    picture: "Image",
    subTitle: "Subtitle",
    tag: "Tag",
    video: "Video",
    activate_notification: "Activate notifications",
    activate_notification_success: "Update notification preference",
    tutorial: {
      title: ({ plural }) => plural(["Tutorial", "Tutorials"]),
      admin: "Admin",
      studea_manager: "Booklet manager",
      user: "User",
    },
  },
};
