export default {
  registration: {
    name: "Nombre",
    institution: "Establecimiento",
    component: "Componente",
    trainingCourse: "Promoción",
    group: "Grupo",
    pedagogicContact: "Contacto educativo",
    pedagogicInformations: "Información educativa",
    fundingInformations: "Información financiera",
    dates: "Fechas",
    from_to: "de {from} al {to}",
    hourlyVolume: "Volumen horario",
    degreePrepared: "Diploma preparado",
    level: "Nivel",
    titleRncp: "Cualificación RNCP",
    degreeCode: "Código del diploma",
    calendar: "Calendario",
    program: "Programa",
    apprenticeShipContract: "Contrato de aprendizaje",
    proContract: "Contrato de profesionalización",
    rate: "Tarifa",
    publicAnnualRate: "Coste anual para los empleadores del sector público",
    hourlyRate: "Precio por hora",
    followUp: "Seguimiento",
    devices: "Dispositivos",
    createdAt: "crea el {date}",
    help: "Ayuda",
    follow: "Seguimiento",
    inProgress: "En curso",
    validatedAt: "Validado el {date} pro {validator}",
    steps: "Las etapas",
    statistics: "Estadísticas",
  },
};
