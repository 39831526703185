<template>
  <div v-if="isPlatformIos()" class="status-bar"></div>
  <div class="main" :class="{ admin: globalAdminPage() }" v-if="!maintenance">
    <div v-if="!isInternet" :class="offlineClass()">Mode hors-ligne</div>
    <RefreshComponent v-if="isPlatformMobile()" />
    <div id="header">
      <HeaderComponent v-if="headerEnabled()" :isAdminPage="isAdminPage()" />
    </div>
    <div id="content" :class="contentClass()">
      <router-view></router-view>
    </div>
    <TabsComponent v-if="tabsEnabled()" />
    <LoaderComponent size="small" />
    <TriadSelectComponent v-if="triadSelectEnabled()" />
    <ChatBotComponent
      v-if="
        design && design.activateChatbot && headerEnabled() && !isMobileSize()
      "
    />
    <FooterComponent v-if="footerEnabled()" />
    <NotificationListModalComponent :opened="listOpened" />
    <HelpDocumentModalComponent
      v-if="Object.keys(helpDocuments).length"
      :opened="showHelpDocuments"
      :helpDocuments="helpDocuments"
      @hidden="closeHelpDocuments"
    />
    <div id="tooltip-container"></div>
    <div id="backdrop" class="modal-backdrop fade"></div>
    <div id="preload" class="d-none"></div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script lang="js">
import { mapActions, mapState } from "pinia";
import LoaderComponent from "@/components/utilities/LoaderComponent.vue";
import RefreshComponent from "@/components/utilities/RefreshComponent.vue";
import { App } from "@capacitor/app";
import "@/assets/sass/app.scss";
import HeaderComponent from "@/components/HeaderComponent.vue";
import { Capacitor } from "@capacitor/core";
import TabsComponent from "@/components/TabsComponent.vue";
import TriadSelectComponent from "@/components/TriadSelectComponent.vue";
import { useMeta } from "@/composables/use-meta";
import ChatBotComponent from "@/components/chatbot/ChatBotComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import HelpDocumentModalComponent from "@/components/HelpDocumentModalComponent.vue";
import { useMainStore } from "@/store/main/main";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";
import { storageManager } from "@/services/storage/storage-manager";
import NotificationListModalComponent from "@/components/notifications/NotificationListModalComponent.vue";
import { useNotificationStore } from "@/store/notifications/notification";
import { apiConnection } from "@/services/api-connection";
import { liveUpdateManager } from "@/services/live-update/live-update-manager";

export default {
  name: "App",
  components: {
    NotificationListModalComponent,
    HelpDocumentModalComponent,
    FooterComponent,
    ChatBotComponent,
    TabsComponent,
    HeaderComponent,
    RefreshComponent,
    LoaderComponent,
    TriadSelectComponent,
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.activateChatbot =
      localStorage.getItem("studea-activateChatbot") === "1";
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
    storageManager.removeListener();
  },
  created() {
    this.setMobileApp(
      Capacitor.getPlatform() !== "web" || window.innerWidth < 768,
    );
    this.initApp();
    useMeta({ title: this.$router.currentRoute.value.meta.title });
  },
  updated() {
    useMeta({ title: this.$router.currentRoute.value.meta.title });
  },
  watch: {
    $route(from) {
      const backdrop = document.getElementById("backdrop");
      if (backdrop) {
        backdrop.classList.remove("show");
      }
      if (from.path !== "/account") {
        if (!this.design || !Object.keys(this.design).length) {
          this.fetchDesign({
            disableLoading: false,
          });
        }
        if (!Object.keys(this.dataAgreement).length) {
          this.fetchDataAgreement();
        }
      }
    },
    appLoading() {
      this.checkLoading();
    },
  },
  computed: {
    ...mapState(useMainStore, {
      isInternet: (store) => store.isInternet,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      dataAgreement: (store) => store.dataAgreement,
    }),
    ...mapState(useMainStore, {
      appLoading: (store) => store.appLoading,
      maintenance: (store) => store.maintenance,
      sideBarOpened: (store) => store.sideBarOpened,
      helpDocuments: (store) => store.helpDocuments,
      showHelpDocuments: (store) => store.showHelpDocuments,
    }),
    ...mapState(useNotificationStore, {
      listOpened: (store) => store.listOpened,
    }),
  },
  methods: {
    async initApp() {
      if (this.isPlatformMobile()) {
        await liveUpdateManager.initialize();
      } else {
        this.checkFrontHostName();
      }

      this.setAppLoading(true);
      this.checkLoading();
      if (Capacitor.getPlatform() === "web" && this.isNotLogin()) {
        this.fetchDesign({
          disableLoading:
            this.$router.currentRoute.value.name === "Error" ||
            this.$router.currentRoute.value.name === "profile",
        });
      }
      if (this.isPlatformMobile()) {
        if (this.isNotLogin()) {
          this.fetchDesign({
            disableLoading:
              this.$router.currentRoute.value.name === "Error" ||
              this.$router.currentRoute.value.name === "profile",
          });
        }
        document.addEventListener("backbutton", this.backListener, false);
        window.addEventListener("online", this.updateOnlineStatus);
        window.addEventListener("offline", this.updateOnlineStatus);
      }
      storageManager.initListener();
    },

    closeHelpDocuments() {
      this.setShowHelpDocuments(false);
    },

    checkFrontHostName() {
      const protocol = window.location.protocol;
      const host = window.location.host;
      let href = window.location.href;
      const parts = host.split(".");
      if (parts.length === 2 && parts[0] !== "www") {
        href = href.replace(protocol + "//", protocol + "//www.");
      } else if (parts.length > 3 && parts[0] === "www") {
        href = href.replace("www.", "");
      }

      if (href !== window.location.href) {
        window.location.href = href;
      }
    },

    isPlatformMobile() {
      return Capacitor.getPlatform() !== "web";
    },

    isPlatformIos() {
      return Capacitor.getPlatform() === "ios";
    },

    checkLoading() {
      if (this.appLoading) {
        document.querySelector("body").classList.add("backdrop");
      } else {
        document.querySelector("body").classList.remove("backdrop");
      }
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.setInternet(type === "online");
    },
    contentClass() {
      let contentClass = "main-content";

      if (!this.headerEnabled()) {
        contentClass = contentClass.concat(" ", "-no-padding");
      }

      if (!this.sideBarOpened) {
        contentClass = contentClass.concat(" ", "-sidebar-closed");
      }

      return contentClass;
    },
    offlineClass() {
      let offlineClass = "offline";

      if (!this.headerEnabled()) {
        offlineClass = offlineClass.concat(" ", "-login");
      }

      return offlineClass;
    },
    headerEnabled() {
      return !this.$router.currentRoute.value.matched.find(
        (item) => item.path === "/login",
      );
    },
    tabsEnabled() {
      return (
        this.$router.currentRoute.value.name !== "Error" &&
        this.$router.currentRoute.value.name !== "profile" &&
        this.$router.currentRoute.value.name !== "TrainingCourseList" &&
        this.$router.currentRoute.value.name !== "TrainingPreRegistration" &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/studea-manager/shared-document",
        ) &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/studea-manager/indent-sheet",
        ) &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/studea-manager/duplication",
        ) &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/studea-manager/driver-licence",
        ) &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/admin",
        ) &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/login",
        ) &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/training",
        ) &&
        (!this.$router.currentRoute.value.matched.find(
          (item) => item.name === "studeaManagerLayout",
        ) ||
          window.innerWidth < 768) &&
        apiConnection.getMagicLinkToken() === null
      );
    },

    footerEnabled() {
      return (
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/login",
        ) && window.innerWidth > 768
      );
    },
    isAdminPage() {
      return !!(
        this.$router.currentRoute.value.matched.find(
          (item) => item.name === "studeaManagerLayout",
        ) ||
        this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/admin",
        )
      );
    },
    triadSelectEnabled() {
      return (
        this.$router.currentRoute.value.name !== "Error" &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/admin",
        ) &&
        !this.$router.currentRoute.value.matched.find(
          (item) => item.path === "/login",
        ) &&
        !apiConnection.getMagicLinkToken()
      );
    },
    backListener() {
      if (
        this.$router.currentRoute.value.name !== "Login" &&
        this.$router.currentRoute.value.name !== "Dashboard"
      ) {
        this.$router.back();
      } else {
        App.exitApp();
      }
    },
    isNotLogin() {
      return !this.$router.currentRoute.value.matched.find(
        (item) => item.path === "/login",
      );
    },
    globalAdminPage() {
      return this.$router.currentRoute.value.matched.find(
        (item) => item.path === "/admin",
      );
    },

    onResize() {
      this.setWindowWidth(window.innerWidth);
      if (window.innerWidth < 992) {
        this.setMobileApp(true);
        this.closeSideBar();
      } else {
        this.setMobileApp(false);
      }
    },

    isMobileSize() {
      return window.innerWidth < 992;
    },

    ...mapActions(useMainStore, {
      setAppLoading: "setAppLoading",
      setMobileApp: "setMobileApp",
      setShowHelpDocuments: "setShowHelpDocuments",
      setInternet: "setInternet",
      setWindowWidth: "setWindowWidth",
      closeSideBar: "closeSideBar",
    }),
    ...mapActions(usePlatformStore, {
      fetchDesign: "fetchDesign",
      fetchDataAgreement: "fetchDataAgreement",
    }),
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}

.modal-backdrop {
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease-in-out;

  &.show {
    visibility: visible;
    opacity: 0.5;
  }
}

.offline {
  position: fixed;
  top: 89px;
  width: 100%;
  background: $red;
  color: $white;
  text-align: center;
  font-weight: 700;
  z-index: 1030;

  &.-login {
    top: 0;
  }

  &.-sub-header {
    top: 54px;
  }
}

.status-bar {
  position: fixed;
  top: 0;
  background: $m_color_1;
  width: 100%;
  left: 0;
  height: env(safe-area-inset-top);
  display: block;
  right: 0;
  z-index: 1031;
}
</style>
