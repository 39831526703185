<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">{{ $t("registration.contacts") }}</h2>
    </div>
    <div class="widget-content">
      <RegistrationContactFieldComponent
        trad="apprenticeMaster"
        :field="registration?.apprenticeMaster"
      />

      <RegistrationContactFieldComponent
        trad="apprenticeMaster2"
        :field="registration?.apprenticeMaster2"
      />

      <RegistrationContactFieldComponent
        trad="hostCompanyContact"
        :field="registration?.hostCompany?.director"
      />

      <RegistrationContactFieldComponent
        trad="administratorCompanyContact"
        :field="registration?.administratorCompany?.director"
      />
    </div>
  </div>
</template>

<script>
import RegistrationContactFieldComponent from "@/components/registration/RegistrationContactFieldComponent.vue";

export default {
  name: "ContractContactBlockComponent",
  components: { RegistrationContactFieldComponent },
  props: {
    registration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.contract {
  h6 {
    font-size: 15px;
  }

  &__part {
    border-bottom: 1px dashed $m_color_6;
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-size: 13px;

    &:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
</style>
