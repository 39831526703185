import { triadManager } from "@/services/triad/triad-manager";
import { skillManager } from "@/services/skill/skill-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/main";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { mainStore } from "@/main";
import * as Sentry from "@sentry/vue";
import router from "@/router";

export const useTriadStore = defineStore({
  id: "Triad",
  state: () => {
    return {
      triad: {},
      triads: {},
      contract: {},
      skills: {},
      subjects: {},
      webLinks: {},
      timelines: {},
      pedagogicDocuments: {},
      generalDocuments: {},
      documentCategories: {},
      documents: {},
      notepads: {},
      triadLoaded: false,
      sessions: {},
      currentSession: {},
      currentSessionSkillReply: {},
      currentReferential: {},
    };
  },

  actions: {
    changeTriad(payload, design) {
      if (!payload.disableLoading) {
        useMainStore().setAppLoading(true);
      }
      if (payload.trainingCourseId) {
        useTrainingCourseStore().toggleTrainingCourse(
          payload.trainingCourseId,
          design,
        );
      }
      window.localStorage.setItem("studea-oldTriad", payload.triadId);
      if (mainStore.isInternet) {
        return triadManager.loadOne(payload.triadId).then((triad) => {
          if (triad && typeof triad === "object") {
            this.initTriadDatas(triad);
          }
        });
      }
    },

    goDashboard(triadId, trainingCourseId) {
      return new Promise((resolve) => {
        const oldTriad = window.localStorage.getItem("studea-oldTriad")
          ? parseInt(window.localStorage.getItem("studea-oldTriad"))
          : null;
        if (!this.triad.id || triadId !== oldTriad) {
          this.changeTriad({
            triadId: triadId,
            trainingCourseId: trainingCourseId,
          }).then(() => {
            resolve(true);
            router.push({ name: "Dashboard" });
          });
        } else {
          resolve(true);
          router.push({
            name: "Dashboard",
          });
        }
      });
    },

    setNotepads(list) {
      this.notepads = list;
    },

    initTriadDatas(triad) {
      Sentry.setTag("triad", triad.id);
      this.triad = triad;
      this.skills =
        triad.trainingCourse && triad.trainingCourse.skillCategories
          ? triad.trainingCourse.skillCategories
          : {};
      this.subjects =
        triad.trainingCourse && triad.trainingCourse.subjects
          ? triad.trainingCourse.subjects
          : {};
      this.webLinks =
        triad.trainingCourse && triad.trainingCourse.webLinks
          ? triad.trainingCourse.webLinks
          : {};
      if (Object.keys(triad.contracts).length) {
        this.contract = triad.contracts.find(
          (contract) => contract.activeContract === true,
        );
      }
      this.timelines = triad.timelines;
      this.notepads = triad.notepads;
      this.sessions = triad.sessions;
      this.setDocuments(triad);

      useQuestionnaireStore().setQuestionnaireToDoAndProgress(triad);
      useTrainingCourseStore().setTrainingCourseInfos(triad);

      setTimeout(function () {
        useMainStore().setAppLoading(false);
      }, 500);
    },

    sendSkills(skills, currentSessionId) {
      useMainStore().savingRequest();
      return skillManager
        .postSkills(skills, currentSessionId)
        .then((success) => {
          notificationManager.showNotification(
            "success",
            i18n.global.t("Skills saved successfully"),
          );
          useMainStore().savingSuccess();
          return success;
        });
    },

    fetchSkills() {
      return skillManager.getTriadSkills().then((skills) => {
        this.skills = skills.skillCategories;
        this.triad.lastSkillReply = skills.lastSkillReply;
        if (this.currentSession && this.currentSession.id) {
          const newCurrentSession = skills.skillSessions.find(
            (session) => session.id === this.currentSession.id,
          );
          this.setCurrentSession(newCurrentSession);
        }
        return skills;
      });
    },

    setDocuments(triad) {
      this.documents = triad.documents;
      const pedagogicDocuments = triad.documents.find(
        (item) => item.code === "pedagogic",
      );
      if (pedagogicDocuments) {
        this.pedagogicDocuments = pedagogicDocuments.documents;
      }

      const generalDocuments = triad.documents.find(
        (item) => item.code === "general",
      );
      if (generalDocuments) {
        this.generalDocuments = generalDocuments.documents;
      }

      const documentCategories = triad.documents.filter(
        (item) => !["pedagogic", "general"].includes(item.code),
      );
      if (documentCategories) {
        this.documentCategories = documentCategories;
      }
    },

    setTriad(triad) {
      this.triad = triad;
      this.triadLoaded = true;
    },

    setCurrentSession(currentSession) {
      this.currentSession = currentSession;
    },

    setCurrentSessionSkillReply(currentSessionSkillReply) {
      this.currentSessionSkillReply = currentSessionSkillReply;
    },

    setCurrentReferential(currentReferential) {
      this.currentReferential = currentReferential;
    },

    init() {
      this.triad = {};
      this.triads = {};
      this.contract = {};
      this.skills = {};
      this.subjects = {};
      this.webLinks = {};
      this.timelines = {};
      this.pedagogicDocuments = {};
      this.generalDocuments = {};
      this.documentCategories = {};
      this.documents = {};
      this.notepads = {};
      this.triadLoaded = false;
      this.sessions = {};
      this.currentSession = {};
      this.currentSessionSkillReply = {};
      this.currentReferential = {};
    },
  },
});
