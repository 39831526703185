import absence from "@/translations/absence/es";
import account from "@/translations/account/es";
import document from "@/translations/document/es";
import forms from "@/translations/forms/es";
import questionnaire from "@/translations/questionnaire/es";
import gradebook from "@/translations/gradebook/es";
import lesson from "@/translations/lesson/es";
import login from "@/translations/login/es";
import chat from "@/translations/chat/es";
import page from "@/translations/page/es";
import skills from "@/translations/skills/es";
import timetable from "@/translations/timetable/es";
import trainingCourse from "@/translations/training-course/es";
import triad from "@/translations/triad/es";
import website from "@/translations/platform/es";
import admin from "@/translations/admin/es";
import studeaManager from "@/translations/studea-manager/es";
import dashboard from "@/translations/dashboard/es";
import global from "@/translations/global/es";
import error from "@/translations/error/es";
import notepad from "@/translations/notepad/es";
import registration from "@/translations/registration/es";

export const es = {
  ...absence,
  ...account,
  ...document,
  ...forms,
  ...questionnaire,
  ...gradebook,
  ...lesson,
  ...login,
  ...chat,
  ...page,
  ...skills,
  ...timetable,
  ...trainingCourse,
  ...triad,
  ...website,
  ...admin,
  ...studeaManager,
  ...dashboard,
  ...global,
  ...error,
  ...notepad,
  ...registration,
};
