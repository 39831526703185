<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5">
        <RegistrationDashboardDeviceTabsComponent
          :loading="loading"
          :devices="devices"
          :deviceId="deviceId"
          @remove="remove"
          @removeDevice="removeDevice"
          @addEdit="addEdit"
          @addEditDevice="addEditDevice"
          @active="active"
          @toggleTab="toggleTab"
        />
      </div>

      <div class="col-lg-7">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">{{ $t("registration.statistics") }}</h2>
          </div>
          <div class="widget-content"></div>
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent
    :models="models"
    :title="modalTitle"
    @submit="submit"
    @cancel="killModal"
    @hidden="killModal"
  />
</template>

<script>
import { registrationManager } from "@/services/registration/registration-manager";
import { adminManager } from "@/services/admin/admin-manager";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import RegistrationDashboardDeviceTabsComponent from "@/components/registration/dashboard/RegistrationDashboardDeviceTabsComponent.vue";

export default {
  name: "RegistrationDashboardView",
  components: {
    RegistrationDashboardDeviceTabsComponent,
    ModalFormComponent,
  },
  data() {
    return {
      models: [],
      modalTitle: "",
      modalAlias: null,
      editingId: null,
      devices: [],
      loading: false,
      deviceId: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getDevices();
    },

    getDevices() {
      adminManager.list("registration-device", false).then((devices) => {
        this.devices = devices;
        if (!this.deviceId && Object.keys(devices).length) {
          this.setDeviceId(devices[0].id);
        }
        this.loading = false;
      });
    },

    active(id) {
      notificationManager
        .showAlert(
          "warning",
          this.$tc("global.active.title", 2, {
            name: this.$tc(
              "admin.registrationDataVersion.title",
              1,
            ).toLowerCase(),
          }),
          this.$tc("global.active.sub_title", 1, {
            name: this.$tc(
              "admin.registrationDataVersion.title",
              1,
            ).toLowerCase(),
          }),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;
            registrationManager.active(id).then(() => {
              this.init();
            });
          }
        });
    },

    toggleTab(id) {
      this.setDeviceId(id);
    },

    setDeviceId(id) {
      this.deviceId = id;
      window.location.hash = this.deviceId;
    },

    addEditDevice(id = null) {
      this.modalTitle = this.$tc("global.add.title", 2, {
        name: this.$tc("admin.registrationDataVersion.title", 1).toLowerCase(),
      });
      this.editId = id;
      this.modalAlias = "registration-device";
      adminManager.form("registration-device", id, {}, true).then((models) => {
        this.models = models;
      });
    },

    addEdit(id) {
      this.modalTitle = this.$tc("global.add.title", 2, {
        name: this.$tc("admin.registrationDataVersion.title", 1).toLowerCase(),
      });
      this.editId = id;
      this.modalAlias = "registration-data-version";
      adminManager
        .form("registration-data-version", this.editId, {}, true)
        .then((models) => {
          this.models = models;
        });
    },

    remove(id) {
      adminManager
        .remove("registration-data-version", id, 2, false)
        .then(() => {
          this.init();
        });
    },

    removeDevice(id) {
      adminManager.remove("registration-device", id, 2, true).then(() => {
        this.init();
      });
    },

    submit() {
      this.loading = true;
      const res = formManager.processForm(this.models, false, this.editId);
      if (this.modalAlias === "registration-data-version") {
        res["dataType"] =
          registrationManager.REGISTRATION_DATA_VERSION_FIELD_CONFIG;
        res["registrationDevice"] = { id: this.deviceId };
        registrationManager.version(res, this.editId).then(() => {
          this.init();
        });
      } else if (this.modalAlias === "registration-device") {
        res["code"] = encodeURI(res["name"]);
        res["position"] = this.devices.length;
        adminManager.put(this.modalAlias, true, res, this.editId).then(() => {
          this.init();
        });
      }
    },

    killModal() {
      this.models = [];
      this.editId = null;
      this.modalTitle = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
