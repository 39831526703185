export default {
  header: {
    dark: "Thème sombre",
    light: "Thème clair",
    system: "Thème du navigateur",
    notification: {
      empty: "Aucun traitement en cours...",
      waiting: "En attente d'informations sur le traitement",
      list: "Voir tout l'historique",
      historic: "Historique des traitements",
      download: "Télécharger",
      file: "Votre fichier",
    },
  },
  menu: "Menu",
  dashboard: {
    timeline: "Timeline",
    indicator: "Indicateurs",
    indicators: {
      see_details: "Voir le détail des taux de remplissage",
      see_details_complete_triad: "Taux de trinômes complets",
      fill_rate: "Taux de remplissage",
    },
    shared_docs: "Documents partagés",
    registration: "Inscriptions",
    welcome_to_studea: "Bienvenue sur votre portail Studea !",
    your_actually_are: "Vous êtes actuellement",
    welcome_message: ({ named }) =>
      `Bienvenue <strong>${named("name")}</strong> sur votre portail Studea !`,
    no_training_course_archived: "Aucune promotion archivé",
    no_training_course_actived: "Aucune promotion active",
    no_triad: "Aucun trinôme",
    triad_search_placeholder: "Rechercher un utilisateur",
    triad_members: "Membres du trinôme",
    contract: {
      type: "Type de contrat :",
      company: "Employeur :",
      dates: "Dates du contrat :",
      rupture_date: "Date de rupture",
      siret: "Siret :",
      address: "Adresse :",
      from: "Du",
      to: "au",
      secondary: "secondaire",
    },
    training: {
      triad_list: "Voir la liste des trinômes",
    },
    archived_training_course: ({ plural, named }) =>
      plural([
        `Voir les ${named("label")}s archivées`,
        `Voir les ${named("label")}s archivés`,
      ]),
    unarchived_training_course: ({ plural, named }) =>
      plural([
        `Voir les ${named("label")}s actives`,
        `Voir les ${named("label")}s actifs`,
      ]),
    Download_the_booklet: "Télécharger le livret",
    Download_key_dates: "Télécharger les dates clés",
    session_lock: "Cette session n'est pas encore ouverte à la saisie.",
    skill_lock:
      "Vous ne pouvez pas modifier \ncette compétence qui est \ncalculée par vos réponses aux \nquestionnaires ou qui ne vous \nest pas affectée",
    driverLicence: {
      config: "Gestion des aides permis B",
      confirmation_send:
        "Le chargé de livret rattaché à votre dossier a été notifié de votre demande.",
      progress: {
        paymentDate: "Date de paiement",
        requestDate: "Date de la demande",
        transmissionDate: "Date de transmission au service financier",
        validationDate: "Date de validation",
      },
    },
  },
};
