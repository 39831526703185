<template>
  <div class="widget" v-if="alerts && Object.keys(alerts).length">
    <div class="widget-heading">
      <h2 class="textStyle">{{ $t("registration.help") }}</h2>
    </div>
    <div class="widget-content">
      <template v-for="model in alerts" :key="model.vars.id">
        <FormInformationAlertComponent :model="model" position="help" />
      </template>
    </div>
  </div>
</template>

<script>
import { formManager } from "@/services/form/form-manager";
import FormInformationAlertComponent from "@/components/form/FormInformationAlertComponent.vue";

export default {
  name: "RegistrationHelpComponent",
  components: { FormInformationAlertComponent },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
  watch: {
    tabs() {
      this.setAlerts();
    },
    current() {
      this.setAlerts();
    },
  },
  data() {
    return {
      alerts: [],
    };
  },
  computed: {},
  mounted() {
    this.setAlerts();
  },
  methods: {
    setAlerts() {
      this.alerts = [];
      const tab = this.tabs[this.current - 1];
      if (tab && tab.blocks && Object.keys(tab.blocks).length) {
        Object.values(tab.blocks).forEach((block) => {
          if (block.models && Object.keys(block.models).length) {
            Object.values(block.models).forEach((model) => {
              if (
                (formManager.isDisplayInformation(model) &&
                  model.vars.attr.informationContent) ||
                formManager.isTriggerAlert(model)
              ) {
                this.alerts.push(model);
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  background: none;
  border: none;
}
</style>
