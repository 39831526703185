<template>
  <div
    id="registration-login"
    ref="modal"
    aria-hidden="true"
    aria-labelledby="registration-login"
    class="modal show fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="{ 'modal-md': size === 'md', 'modal-xl': size === 'xl' }"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title textStyle">
            {{ title }}
          </h5>
        </div>
        <div class="modal-body">
          <div class="alert alert-light-info">
            {{ $t("registration.loginInfo") }}
          </div>
          <div
            class="d-flex align-items-center justify-content-center mt-4 mb-3"
          >
            <button class="btn mx-2 btnLogin" @click="login">
              {{ $t("registration.noNeedAccount") }}
            </button>
            <button class="btn mx-2 btnProfile" @click="chooseProfile">
              {{ $t("registration.needAccount") }}
            </button>
          </div>
          <div
            :class="'alert alert-light-' + messageType"
            v-if="message && messageType"
            v-html="message"
          ></div>
          <div
            class="mt-3 mb-4 profile"
            v-if="form && form !== 'login' && form !== 'forget-password'"
          >
            <div class="text-center textStyle fw-bold fs-6 mb-2">
              {{ $t("registration.iAm") }}
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <button
                class="btn mx-2 btnApprentice"
                :class="{ unactived: isCompany || isCompany === null }"
                @click="signIn(false)"
              >
                <FontAwesomeIcon icon="fa-light fa-user" class="me-2" />
                {{ $t("registration.anApprentice") }}
              </button>
              <button
                class="btn mx-2 btnCompany"
                :class="{ unactived: !isCompany }"
                @click="signIn(true)"
              >
                <FontAwesomeIcon icon="fa-light fa-building" class="me-2" />
                {{ $t("registration.aCompany") }}
              </button>
            </div>
          </div>
          <div
            class="position-relative formContainer"
            v-if="form && form !== 'choose-profile'"
          >
            <FormComponent
              v-if="Object.keys(models).length"
              :disableFocusAnimation="true"
              :models="models"
              :keepHideValue="true"
              :hideSubmit="true"
              @draft="update"
            />
            <div class="text-center" v-if="form === 'login' && !loading">
              <button class="forgotPassword" @click="forgetPassword">
                {{ $t("forgotPasswordLink") }}
              </button>
            </div>
            <div
              class="text-center mt-4"
              v-if="!loading && form !== 'choose-profile'"
            >
              <SubmitWithLoaderComponent
                :text="buttonText"
                :loading="btnLoading"
                :cfaStyle="true"
                @submit="submit"
              />
            </div>
            <BlockLoader :loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import FormComponent from "@/components/FormComponent.vue";
import { Modal } from "bootstrap";
import { loginManager } from "@/services/security/login-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { formManager } from "@/services/form/form-manager";
import { formValidator } from "@/services/form/form-validator";
import { useThemeStore } from "@/store/theme/theme";
import SubmitWithLoaderComponent from "@/components/utilities/SubmitWithLoaderComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "RegistrationLoginComponent",
  components: {
    FontAwesomeIcon,
    SubmitWithLoaderComponent,
    BlockLoader,
    FormComponent,
  },
  props: {},
  data() {
    return {
      models: [],
      title: "",
      size: "md",
      form: "",
      buttonText: "",
      loading: false,
      modal: null,
      btnLoading: false,
      isCompany: null,
      message: "",
      messageType: "",
    };
  },
  computed: {
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useThemeStore, {
      locale: (store) => store.locale,
    }),
  },
  mounted() {
    this.init();
  },
  emits: ["loginSuccess"],
  methods: {
    init() {
      if (loginManager.isToken()) {
        if (!this.user || (this.user && !Object.keys(this.user).length)) {
          loginManager.refreshConnection().then((success) => {
            if (success) {
              this.fetchUserInfos();
            } else {
              this.initModal();
            }
          });
        }
      } else {
        this.initModal();
      }
    },

    setTitle(title) {
      this.title = title;
    },

    initModal() {
      if (this.$refs.modal) {
        this.modal = new Modal(this.$refs.modal, {
          backdrop: "static",
          keyboard: false,
        });
        this.modal.show();
        this.setTitle(
          this.$t("registration.login") +
            " / " +
            this.$t("registration.signIn"),
        );
        this.buttonText = this.$t("registration.login");
      }
    },

    login() {
      this.form = "login";
      this.isCompany = null;
      this.title = this.$t("registration.login");
      this.size = "md";
      this.buttonText = this.$t("registration.login");
      this.models = [];
      this.getForm();
    },

    signIn(isCompany) {
      this.isCompany = isCompany;
      this.form = "sign-in";
      this.title = this.isCompany
        ? this.$t("registration.createCompanyAccount")
        : this.$t("registration.createApprenticeAccount");
      this.buttonText = this.$t("registration.signIn");
      this.size = "xl";
      if (!this.models || !Object.keys(this.models).length) {
        this.getForm();
      }
    },

    chooseProfile() {
      this.message = "";
      this.messageType = "";
      this.models = [];
      this.form = "choose-profile";
      this.title = this.$t("registration.signIn");
      this.buttonText = this.$t("registration.signIn");
      this.size = "md";
    },

    forgetPassword() {
      this.message = "";
      this.messageType = "";
      this.form = "forget-password";
      this.title = this.$t("forgotPasswordLink");
      this.buttonText = this.$t("reinitialize");
      this.size = "md";
      this.models = [];
      this.getForm();
    },

    getForm() {
      this.loading = true;
      loginManager.getForm(this.form).then((res) => {
        this.models = res;
        this.loading = false;
      });
    },

    validPasswords() {
      formValidator.validRepeatedPassword(
        this.models["password"],
        this.models["repeatedPassword"],
        [],
      );
    },

    submit() {
      let errors = formValidator.validForm(this.models, false, false);
      this.message = "";
      this.messageType = "";

      if (this.form === "sign-in" && !errors.length) {
        errors = formValidator.validRepeatedPassword(
          this.models["password"],
          this.models["repeatedPassword"],
          errors,
        );
      }

      if (!errors.length) {
        this.btnLoading = true;
        const result = formManager.processForm(this.models);
        if (this.form === "login") {
          loginManager.login(result.username, result.password).then((res) => {
            if (res.success) {
              this.fetchUserInfos().then(() => {
                this.loginSuccess();
              });
            } else if (res.code) {
              this.message = res.message;
              this.messageType = "danger";
              this.btnLoading = false;
            }
          });
        } else if (this.form === "forget-password") {
          loginManager.resetPassword(result.username).then(() => {
            this.login();
            this.btnLoading = false;
          });
        } else if (this.form === "sign-in") {
          loginManager.signIn(result, this.locale).then((res) => {
            if (res.success) {
              this.fetchUserInfos().then(() => {
                this.loginSuccess(true);
              });
            } else if (!res.success && res.code === 409) {
              this.login();
              this.btnLoading = false;
              this.message =
                "<strong>" +
                this.$t("registration.accountExist") +
                "</strong>" +
                "<br>" +
                this.$t("registration.accountExistSubTitle");
              this.messageType = "success";
            }
          });
        }
      }
    },

    update(model) {
      if (model?.vars?.name === "repeatedPassword") {
        this.validPasswords();
      }
    },

    loginSuccess(fill) {
      let fillUser = null;

      if (fill) {
        fillUser = this.isCompany ? "company" : "apprentice";
      }
      this.$emit("loginSuccess", fillUser);
      this.btnLoading = false;
      this.modal.hide();
    },

    ...mapActions(useUserStore, {
      fetchUserInfos: "fetchUserInfos",
    }),
  },
};
</script>

<style lang="scss" scoped>
.formContainer {
  min-height: 200px;
}

.forgotPassword {
  background: none;
  border: none;
  color: var(--primary-color);
  position: relative;
  top: -10px;
  font-weight: 700;
}

.btn {
  background: $blue !important;
  border-color: $blue !important;
  color: $white !important;
}

.unactived {
  background: $white !important;
  color: $blue !important;
  border-color: $blue !important;

  &:hover,
  &:focus {
    background: $blue !important;
    color: $white !important;
  }
}

.profile {
  position: relative;
  padding-top: 15px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 1px;
    width: 100px;
    background: $m-color_5;
    transform: translateX(-50%);
  }
}
</style>
