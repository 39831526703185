<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="widget widget-summary-1">
        <div class="widget-heading">
          <h2 class="textStyle">{{ $t("admin.home.title4") }}</h2>
        </div>
        <div class="widget-content stats">
          <div class="stats">
            <Splide
              :key="key"
              :has-track="false"
              :options="splideOptions"
              aria-label="Carousel de statistiques"
            >
              <SplideTrack>
                <SplideSlide v-for="(tab, index) in statisticTabs" :key="index">
                  <AdminNumberCardChart
                    :tab="tab"
                    @show="show"
                    @exportStatistic="exportStatistic"
                  />
                </SplideSlide>
              </SplideTrack>
              <div class="splide__arrows">
                <button class="splide__arrow splide__arrow--prev textStyle">
                  <FontAwesomeIcon icon="fa-light fa-chevron-right" />
                </button>
                <button class="splide__arrow splide__arrow--next textStyle">
                  <FontAwesomeIcon icon="fa-light fa-chevron-right" />
                </button>
              </div>
            </Splide>
          </div>
          <div class="text-end fst-italic">
            {{
              this.lastStatUpdate
                ? $t("admin.statistic.updated_on") + " " + this.lastStatUpdate
                : $t("admin.statistic.indicators_generated")
            }}
          </div>
          <BlockLoader :loading="statsLoading" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <TutorialBlockComponent />
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <NewsBlockComponent :loading="loading" :news="newsFiltered" />
    </div>
  </div>
  <IndicatorsModalComponent
    :indicator="indicator"
    :opened="!!indicator"
    @hidden="hidden"
  />
</template>

<script>
import { mapActions, mapState } from "pinia";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import NewsBlockComponent from "@/components/blocks/NewsBlockComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useThemeStore } from "@/store/theme/theme";
import { useMainStore } from "@/store/main/main";
import { useNewsStore } from "@/store/news/news";
import { usePlatformStore } from "@/store/platform/platform";
import { adminManager } from "@/services/admin/admin-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import AdminNumberCardChart from "@/components/charts/AdminNumberCardChart.vue";
import IndicatorsModalComponent from "@/components/modal/IndicatorsModalComponent.vue";
import TutorialBlockComponent from "@/components/blocks/TutorialBlockComponent.vue";

export default {
  name: "AdminHomeView",
  data() {
    return {
      splideOptions: {
        type: "slide",
        rewind: true,
        perPage: 1,
        width: "100%",
        arrows: true,
        pagination: false,
        autoplay: false,
        drag: false,
        start: 0,
      },
      indicator: null,
      key: 0,
      statisticTabs: [],
      lastStatUpdate: null,
      newsFiltered: [],
      loading: true,
      statsLoading: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useThemeStore, {
      isDark: (store) => store.isDark_mode,
    }),
    ...mapState(useMainStore, {
      windowWidth: (store) => store.windowWidth,
      sideBarOpened: (store) => store.sideBarOpened,
    }),
    ...mapState(useNewsStore, {
      news: (store) => store.news,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
    }),
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 992) {
        this.render();
      }
    },
    sideBarOpened() {},
  },
  methods: {
    initStats() {
      this.statsLoading = true;
      adminManager.list("statistic", false).then((res) => {
        this.statsLoading = false;
        this.statisticTabs = res.statistic;
        this.lastStatUpdate = res.lastUpdated;
      });
    },
    render() {
      setTimeout(() => {
        this.key++;
      }, 600);
    },

    init() {
      this.loading = true;
      this.fetchNews().then(() => {
        this.loading = false;
        this.newsFiltered = this.news.slice(0, 3);
      });
      this.initStats();
    },

    show(indicator) {
      this.indicator = indicator;
    },

    hidden() {
      this.indicator = null;
    },

    exportStatistic() {
      adminManager.exportStatistic();
    },

    ...mapActions(useNewsStore, { fetchNews: "fetchNews" }),
  },
  components: {
    TutorialBlockComponent,
    IndicatorsModalComponent,
    AdminNumberCardChart,
    BlockLoader,
    FontAwesomeIcon,
    NewsBlockComponent,
    Splide,
    SplideSlide,
    SplideTrack,
  },
};
</script>

<style lang="scss" scoped>
.stats {
  padding: 0 30px;
}

.splide__arrow {
  background: none;
  font-size: 24px;

  &--prev {
    left: -50px;
  }

  &--next {
    right: -50px;
  }
}

.splide__splide {
  width: 100%;
}

.stats {
  min-height: 390px;
}
</style>
