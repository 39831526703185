export default {
  registration: {
    name: "Nom",
    institution: "Établissement",
    component: "Composante",
    trainingCourse: "Promotion",
    group: "Groupe",
    pedagogicContact: "Contact pédagogique",
    pedagogicInformations: "Informations pédagogiques",
    fundingInformations: "Informations financières",
    dates: "Dates",
    from_to: "du {from} au {to}",
    hourlyVolume: "Volume horaire",
    degreePrepared: "Diplôme préparé",
    level: "Niveau",
    titleRncp: "Titre RNCP",
    degreeCode: "Code diplôme",
    calendar: "Calendrier",
    program: "Programme",
    apprenticeShipContract: "Contrat d'apprentissage",
    proContract: "Contrat de professionnalisation",
    rate: "Tarif",
    publicAnnualRate: "Coût annuel employeurs publics",
    hourlyRate: "Tarif horaire",
    followUp: "Suivi du dossier",
    devices: "Dispositifs",
    createdAt: "crée le {date}",
    help: "Aide",
    follow: "Suivi",
    inProgress: "En cours",
    validatedAt: "Validé le {date} par {validator}",
    steps: "Les étapes",
    statistics: "Statistiques",
    generalInformations: "Informations générales",
    civility: "Civilité",
    firstName: "Prénom",
    birthDate: "Date de naissance",
    age: "Âge",
    email: "Email",
    phone: "Téléphone",
    address: "Adresse",
    nationality: "Nationalité",
    male: "M.",
    female: "Mme.",
    years: "ans",
    apprentice: "Apprenti",
    tutor: "Tuteur",
    map: "Maitre d'apprentissage",
    function: "Fonction",
    contacts: "Contacts",
    previousSituation: "Situation antérieur",
    ineCode: "Code INE",
    preContractSituation: "Situation avant contrat",
    lastDegreeObtained: "Dernier diplôme obtenu",
    title: "Intitulé",
    lastDegreePrepared: "Dernier diplôme préparé",
    lastInstitution: "Dernier établissement",
    uaiCode: "Code UAI",
    department: "Département",
    socialReason: "Raison sociale",
    company: "Entreprise",
    activityCode: "Code d'activité (NAF)",
    totalWorkForce: "Effectif total",
    siret: "SIRET",
    employerType: "Type d'employeur",
    employerSubType: "Sous-type d'employeur",
    idcc: "IDCC",
    opco: "OPCO",
    hostCompany: "Entreprise d'accueil",
    administratorCompany: "Siège de l'entreprise",
    training: "Formation",
    startDate: "Date de début",
    endDate: "Date de fin",
    hourlyTrainingVolume: "Nombre d'heures",
    apprenticeMaster: "Maitre d'apprentissage",
    apprenticeMaster2: "Maitre d'apprentissage 2",
    degree: "Diplôme",
    hostCompanyContact: "Contact entreprise d'accueil",
    administratorCompanyContact: "Contact siège entreprise",
    pricePolicyInfo1:
      "Reste à charge uniquement si la prise en charge est inférieure ; la prise en charge plafonnée au tarif, même si elle est supérieure.",
    pricePolicyInfo2:
      "Reste à charge uniquement si la prise en charge est inférieure au tarif ; si supérieure, on applique le tarif en fonction de la prise en charge.",
    pricePolicyInfo3: "Le tarif est aligné sur la prise en charge.",
    signIn: "Créer un compte",
    login: "Se connecter",
    loginInfo:
      "Authentifiez-vous ou créez un compte pour accéder à la fiche d’inscription",
    needAccount: "Je n'ai pas de compte",
    noNeedAccount: "J'ai déjà un compte",
    anApprentice: "Un.e alternant.e",
    aCompany: "Une entreprise",
    iAm: "Je suis",
    createApprenticeAccount: "Créer un compte alternant.e",
    createCompanyAccount: "Créer un compte entreprise",
    accountExist: "Votre e-mail a été reconnu !",
    accountExistSubTitle:
      "Vous pouvez vous connecter directement à votre compte avec vos identifiants !",
    next: "Suivant",
    prev: "Précédent",
  },
};
