<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-7 col-xl-9">
        <TrainingInfosComponent
          :training="training"
          :loading="loading"
          :logo="logo"
        />

        <TrainingGroupsComponent :training="training" :loading="loading" />
      </div>
      <div class="col-lg-5 col-xl-3">
        <ActionsBlock :actions="actions" />

        <div class="widget">
          <div class="widget-heading align-items-center">
            <h2>Contacts</h2>
            <button class="btn btn-success btnStyle">
              <FontAwesomeIcon icon="fa-light fa-plus" />
            </button>
          </div>
          <div class="widget-content position-relative">
            <FormComponent v-if="models" :models="models" :hideSubmit="true" />
            <SubmitTypeComponent :model="submitModel" @submitForm="submit" />
            <BlockLoader :loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";
import crews from "@/model/training/crews";
import { trainingManager } from "@/services/training/training-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { pictureManager } from "@/services/utilities/picture-manager";
import FormComponent from "@/components/FormComponent.vue";
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";
import { formManager } from "@/services/form/form-manager";
import TrainingInfosComponent from "@/components/training/TrainingInfosComponent.vue";
import TrainingGroupsComponent from "@/components/training/TrainingGroupsComponent.vue";

export default {
  name: "TrainingDashboardView",
  components: {
    TrainingGroupsComponent,
    TrainingInfosComponent,
    SubmitTypeComponent,
    FormComponent,
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      crews: crews,
      training: {},
      filteredTriadsCrews: [],
      loading: false,
      models: null,
      logo: null,
      submitModel: {
        vars: {
          hideCancel: true,
        },
      },
      actions: [
        {
          actionType: "router",
          icon: "pen",
          type: "primary",
          action: "TrainingEdit",
          translation: "Modifier la formation",
          cfaStyle: true,
          params: { id: this.$route.params.id, alias: "training" },
        },
        {
          actionType: "button",
          icon: "download",
          type: "primary",
          action: "test",
          translation: "Exporter",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "download",
          type: "primary",
          action: "test",
          translation: "Export des inscriptions",
          cfaStyle: true,
        },
        {
          actionType: "router",
          icon: "eye",
          type: "primary",
          action: "RegistrationList",
          translation: "Liste des contrats",
          cfaStyle: true,
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
    this.setAppLoading(false);
  },
  methods: {
    init() {
      this.loading = true;
      this.getTraining();
      this.getContactForm();
    },

    getTraining() {
      trainingManager.get(this.$route.params.id).then((res) => {
        this.training = res;
        this.setLogoSrc();
        this.loading = false;
      });
    },

    submit() {
      const result = formManager.processForm(this.models);
      console.log(result);
    },

    getContactForm() {
      trainingManager.form("contact", this.$route.params.id).then((res) => {
        this.models = res;
      });
    },

    toggleTab(code) {
      this.setCurrentTabCode(code);
    },

    setCurrentTabCode(code) {
      this.currentTabCode = code;
      window.location.hash = this.currentTabCode;
    },

    setLogoSrc() {
      this.logo = pictureManager.setPicture(this.training.logoTraining);
    },

    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.settings {
  width: 35px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    font-size: 20px;
    color: $green;
  }
}

.settings + .settings {
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 12px;
    width: 1px;
    background: var(--primary-color);

    @include up-to-md {
      height: 0;
      width: 0;
    }
  }
}

.collapse-content {
  padding: 20px;
}
</style>
