<template>
  <div class="group">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="textStyle group__title">
        <span
          class="group__status"
          :class="{
            success: group.status === 3,
            warning: group.status === 2,
            pending: !group.status || group.status === 1,
          }"
        >
          <FontAwesomeIcon :icon="'fa-light fa-' + icon()" />
        </span>
        {{ group.startYear }}-{{ group.endYear }} | {{ group.name }}
      </h3>
      <div class="group__actions">
        <router-link
          :to="{
            name: 'TrainingPreRegistration',
            params: { group: group.cryptedId, tab: 0 },
          }"
          class="btn-icon"
          v-tooltip="{
            title: 'Voir la fiche de pré-inscription',
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-eye" />
        </router-link>
        <router-link
          :to="{
            name: 'TrainingGroupAddEdit',
            params: { groupId: group.id },
          }"
          class="btn-icon ms-2"
          v-tooltip="{
            title: this.$tc('global.edit.title', 1, {
              name: this.$tc('studea_manager.group.title', 1).toLowerCase(),
            }),
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-pen" />
        </router-link>
      </div>
    </div>
    <div class="group__registrations">
      <div class="row mb-1" v-if="group.code">
        <div class="col-5 fw-bold">Code</div>
        <div class="col-7">
          {{ group.code }}
        </div>
      </div>

      <div class="row mb-1" v-if="group.startDate && group.endDate">
        <div class="col-5 fw-bold">Dates</div>
        <div class="col-7">
          du {{ formatDate(group.startDate) }} au
          {{ formatDate(group.endDate) }}
        </div>
      </div>

      <div class="row mb-1" v-if="group.hourlyTrainingVolume">
        <div class="col-5 fw-bold">Nombre d'heures</div>
        <div class="col-7">{{ group.hourlyTrainingVolume }}h</div>
      </div>

      <div class="row mb-1" v-if="group.hourlyTeachingVolume">
        <div class="col-5 fw-bold">Nombre d'heures d'enseignement</div>
        <div class="col-7">{{ group.hourlyTeachingVolume }}h</div>
      </div>

      <div class="row mb-1" v-if="group.contractType">
        <div class="col-5 fw-bold">Public prévu</div>
        <div class="col-7">
          {{ group.contractType === 1 ? "Apprenti.e.s" : "Contrat.s pro.s" }}
        </div>
      </div>

      <div class="row mb-1" v-if="group.comment">
        <div class="col-5 fw-bold">Commentaire</div>
        <div class="col-7">
          <div class="group__comment">{{ group.comment }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "TrainingGroupComponent",
  props: {
    group: {
      type: Object,
      required: true,
    },
    trainingCourse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    icon() {
      switch (this.group.status) {
        case 2:
          return "info";
        case 3:
          return "check";
        default:
          return "timer";
      }
    },
  },
  components: { FontAwesomeIcon },
};
</script>

<style lang="scss" scoped>
.group {
  border: 1px solid $m_color_4;
  padding: 15px;
  border-radius: $radius;

  &__title {
    font-size: 16px;
    margin-bottom: 0;
  }

  &__registrations {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px dashed $m_color_4;
  }

  &__registration {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__registration + &__registration {
    margin-top: 5px;
  }

  &__comment {
    white-space: pre;
  }

  &__status {
    background: var(--registration-waiting);
    color: $white;
    width: 23px;
    height: 23px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 50%;
    margin-right: 5px;

    &.success {
      background: var(--registration-success);
    }

    &.warning {
      background: var(--registration-progress);
    }
  }
}
</style>
