<template>
  <div class="row">
    <div class="col-lg-6">
      <RegistrationListFieldComponent
        trad="socialReason"
        type="simple"
        :field="company?.companyName"
      />

      <RegistrationListFieldComponent
        trad="address"
        type="address"
        :field="company"
      />

      <RegistrationListFieldComponent
        trad="activityCode"
        type="simple"
        :field="company?.nafCode"
      />

      <RegistrationListFieldComponent
        trad="totalWorkForce"
        type="simple"
        :field="company?.totalWorkforce"
      />

      <RegistrationListFieldComponent
        trad="siret"
        type="simple"
        :field="company?.siret"
      />

      <RegistrationListFieldComponent
        trad="employerType"
        type="cerfaParam"
        :field="company?.employerType"
      />
    </div>

    <div class="col-lg-6">
      <RegistrationListFieldComponent
        trad="idcc"
        type="idcc"
        :field="company?.idcc"
      />

      <RegistrationListFieldComponent
        trad="opco"
        type="idcc"
        :field="company?.opco"
      />

      <RegistrationListFieldComponent
        trad="phone"
        type="simple"
        :field="company?.phoneNumber"
      />

      <RegistrationListFieldComponent
        trad="email"
        type="simple"
        :field="company?.email"
      />
    </div>
  </div>
</template>

<script>
import RegistrationListFieldComponent from "@/components/registration/RegistrationListFieldComponent.vue";

export default {
  name: "ContractCompanyContentComponent",
  components: { RegistrationListFieldComponent },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
