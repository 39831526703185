<template>
  <div class="widget widget-summary-1">
    <div class="widget-heading">
      <h5>{{ data.label ? data.label : $t("dashboard.indicator") }}</h5>
    </div>
    <div class="widget-content">
      <div v-for="(item, index) in statistic" :key="item.label" class="elem">
        <StatisticBlockComponent :data="item" @show="show" :index="index" />
      </div>
    </div>
  </div>
</template>

<script>
import StatisticBlockComponent from "@/components/blocks/statistic/StatisticBlockComponent.vue";

export default {
  name: "IndicatorBlock",
  components: { StatisticBlockComponent },
  emits: ["show"],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statistic: [],
    };
  },
  watch: {
    data() {
      this.statistic = this.data.indicators;
    },
  },
  mounted() {
    this.statistic = this.data.indicators;
  },
  methods: {
    show(indicator) {
      this.$emit("show", indicator);
    },
  },
};
</script>

<style lang="scss" scoped>
.elem + .elem {
  margin-top: 10px;
}
</style>
