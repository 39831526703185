<template>
  <div class="widget faq">
    <div class="widget-heading">
      <h2 class="textStyle">Promotions</h2>
    </div>
    <div class="widget-content faq-layouting position-relative">
      <div class="fq-tab-section">
        <div id="trainingCourseList" class="accordion">
          <div
            v-for="trainingCourse in training.activeTrainingCourses"
            :key="trainingCourse.id"
            class="card"
          >
            <div class="card-header" role="tab">
              <div
                :aria-controls="'collapse-' + trainingCourse.id"
                :aria-expanded="false"
                :data-bs-target="'#collapse-' + trainingCourse.id"
                class="mb-0"
                data-bs-toggle="collapse"
                role="menu"
              >
                <span class="faq-q-title textStyle">
                  {{ trainingCourse.displayName }}
                </span>
                <div
                  class="like-faq d-flex align-items-center"
                  @click="$event.stopPropagation()"
                >
                  <router-link
                    :to="{
                      name: 'studeaManagerTriadList',
                      params: { trainingCourseId: trainingCourse.id },
                    }"
                    class="settings"
                    v-tooltip="{
                      title: $tc('studea_manager.triad.title', 2),
                    }"
                  >
                    <FontAwesomeIcon class="icon" icon="fa-light fa-users" />
                  </router-link>

                  <router-link
                    :to="{
                      name: 'studeaManagerHome',
                      params: { trainingCourseId: trainingCourse.id },
                    }"
                    class="settings"
                    v-tooltip="{
                      title:
                        $tc('studea_manager.home.title', 1) +
                        $tc(
                          'studea_manager.trainingCourse.title',
                          1,
                        ).toLowerCase(),
                    }"
                  >
                    <FontAwesomeIcon class="icon" icon="fa-light fa-gear" />
                  </router-link>
                </div>
              </div>
            </div>
            <div
              :id="'collapse-' + trainingCourse.id"
              :aria-labelledby="'collapse-' + trainingCourse.id"
              class="collapse"
              :class="{
                show:
                  (currentTrainingCourse &&
                    trainingCourse.id === currentTrainingCourse.id) ||
                  (trainingCourse.triads &&
                    Object.keys(trainingCourse.triads).length &&
                    isSearching),
              }"
              data-bs-parent="#trainingCourseList"
            >
              <div class="groups">
                <div class="row">
                  <div
                    class="col-lg-6"
                    v-for="group in trainingCourse.groups"
                    :key="group.id"
                  >
                    <TrainingGroupComponent
                      :trainingCourse="trainingCourse"
                      :group="group"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlockLoader :loading="loading" />
    </div>
  </div>
</template>

<script>
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import TrainingGroupComponent from "@/components/training/TrainingGroupComponent.vue";

export default {
  name: "TrainingGroupsComponent",
  components: { TrainingGroupComponent, BlockLoader },
  props: {
    training: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentTab: null,
      currentTrainingCourse: null,
    };
  },
  watch: {
    training() {
      this.currentTrainingCourse =
        this.training &&
        this.training.activeTrainingCourses &&
        Object.keys(this.training.activeTrainingCourses).length
          ? Object.values(this.training.activeTrainingCourses)[0]
          : null;
    },
  },
  mounted() {
    this.currentTrainingCourse =
      this.training &&
      this.training.activeTrainingCourses &&
      Object.keys(this.training.activeTrainingCourses).length
        ? Object.values(this.training.activeTrainingCourses)[0]
        : null;
  },
  computed: {},
  methods: {
    toggleTab(trainingCourse) {
      this.setCurrentTabCode(this.label(trainingCourse));
    },

    isActive(index, trainingCourse) {
      return (
        (!this.currentTab && index === "1") ||
        this.currentTab === this.label(trainingCourse)
      );
    },

    label(trainingCourse) {
      return trainingCourse.startYear + "-" + trainingCourse.endYear;
    },

    setCurrentTabCode(code) {
      this.currentTab = code;
      window.location.hash = this.currentTab;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  .tabs {
    &__row {
      padding: 8px 0;
      display: flex;
      font-size: 14px;
      align-items: center;
      min-height: 40px;

      .field {
        width: 35%;
        font-weight: 600;
      }

      .text {
        width: 65%;
      }

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    &__triads {
      margin-top: 1rem;
      border: 1px dashed $m-color_6;
      border-radius: $radius;
      padding: 1rem;
      max-height: 400px;
      overflow: auto;
      height: 100%;

      .triad {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
      }

      .triad + .triad {
        border-top: 1px dashed $m-color_6;
      }
    }
  }

  .tabs__row + .tabs__row {
    border-top: 1px dashed $m-color_6;
  }
}

.widget {
  #trainingCourseList {
    display: flex;
    flex-direction: column;
  }

  .loading {
    margin-left: 20px;
    width: 15px;
    height: 15px;
  }

  .settings {
    width: 35px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      font-size: 20px;
      color: $green;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: var(--primary-color);

      @include up-to-md {
        height: 0;
        width: 0;
      }
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }

  .collapse {
    &.archive {
      background: $light-red;
    }
  }
}

.faq-q-title {
  @include up-to-md {
    justify-content: flex-start !important;
  }

  .green {
    @include up-to-md {
      margin-right: 5px;
    }
  }
}

.faq .faq-layouting .fq-tab-section .accordion .card {
  cursor: initial;
}

.like-faq {
  justify-content: space-between;
  @include up-to-md {
    margin-top: 15px;
    margin-left: 18px;
    justify-content: inherit;
  }
}

.groups {
  padding: 20px;
}
</style>
