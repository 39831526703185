class TabsManager {
  displayTab(tab, testing = false) {
    if (tab) {
      var el = window.bootstrap.Tab.getOrCreateInstance(
        document.getElementById("tab-" + tab.code + "-tab"),
      ); // Returns a Bootstrap tab instance
      if (!testing) {
        el.show();
      }
    }
  }
}

export const tabsManager = new TabsManager();
