<template>
  <div class="widget">
    <div class="widget-heading align-items-center">
      <div>
        <h2 class="textStyle h-auto">Alternant</h2>
        <p class="mb-0">
          {{ registration?.apprentice?.firstName }}
          {{ registration?.apprentice?.lastName }}
          {{ registration?.apprentice?.usageLastName }}
        </p>
      </div>
      <router-link
        v-if="registration?.group?.cryptedId"
        :to="{
          name: 'TrainingPreRegistration',
          params: { group: registration.group.cryptedId, tab: 0 },
        }"
        class="btn-icon"
      >
        <FontAwesomeIcon icon="fa-light fa-pen" />
      </router-link>
    </div>
    <div class="widget-content">
      <div class="row">
        <div class="col-lg-6">
          <h6>{{ $t("registration.generalInformations") }}</h6>
          <RegistrationListFieldComponent
            trad="civility"
            type="gender"
            :field="registration?.apprentice?.gender"
          />
          <RegistrationListFieldComponent
            trad="name"
            type="simple"
            :field="registration?.apprentice?.lastName"
          />
          <RegistrationListFieldComponent
            trad="firstName"
            type="simple"
            :field="registration?.apprentice?.firstName"
          />
          <RegistrationListFieldComponent
            trad="birthDate"
            type="date"
            :field="registration?.apprentice?.birthday"
          />
          <RegistrationListFieldComponent
            trad="age"
            type="simple"
            :field="registration?.apprentice?.age"
            :unit="$t('registration.years')"
          />
          <RegistrationListFieldComponent
            trad="email"
            type="simple"
            :field="registration?.apprentice?.email"
          />
          <RegistrationListFieldComponent
            trad="phone"
            type="simple"
            :field="registration?.apprentice?.phone"
          />
          <RegistrationListFieldComponent
            trad="address"
            type="address"
            :field="registration?.apprentice"
          />
          <RegistrationListFieldComponent
            trad="nationality"
            type="simple"
            :field="registration?.apprentice?.nationalityAsText"
          />
        </div>

        <div class="col-lg-6">
          <h6>{{ $t("registration.previousSituation") }}</h6>
          <RegistrationListFieldComponent
            trad="ineCode"
            type="simple"
            :field="registration?.apprentice?.ineCode"
          />
          <RegistrationListFieldComponent
            trad="preContractSituation"
            type="cerfaParam"
            :field="registration?.apprentice?.situationBeforeCode"
          />
          <RegistrationListFieldComponent
            trad="lastDegreeObtained"
            type="cerfaParam"
            :field="registration?.apprentice?.lastDegreeObtainedCategory"
          />
          <RegistrationListFieldComponent
            trad="title"
            type="simple"
            :field="registration?.apprentice?.lastDegreeObtainedTitle"
          />
          <RegistrationListFieldComponent
            trad="lastDegreePrepared"
            type="cerfaParam"
            :field="registration?.apprentice?.lastDegreePreparedCategory"
          />
          <RegistrationListFieldComponent
            trad="title"
            type="simple"
            :field="registration?.apprentice?.lastDegreePreparedTitle"
          />
          <RegistrationListFieldComponent
            trad="lastInstitution"
            type="simple"
            :field="registration?.apprentice?.lastDegreeObtainedInstitutionName"
          />
          <RegistrationListFieldComponent
            trad="department"
            type="department"
            :field="
              registration?.apprentice?.lastDegreePreparedInstitutionDepartment
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationListFieldComponent from "@/components/registration/RegistrationListFieldComponent.vue";

export default {
  name: "ContractApprenticeBlockComponent",
  components: { RegistrationListFieldComponent },
  props: {
    registration: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
