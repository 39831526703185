<template>
  <VueDraggableNext
    :animation="200"
    :delay="200"
    :delay-on-touch-only="true"
    :list="tab.registrationBlocks"
    handle=".block__grip"
    @change="sortBlock"
  >
    <div
      class="widget block"
      v-for="block in tab.registrationBlocks"
      :key="block.code"
    >
      <div class="widget-heading">
        <div class="block__start">
          <h2 class="textStyle">
            <FontAwesomeIcon
              v-if="block.icon"
              class="me-1"
              :icon="'fa-light fa-' + block.icon"
            />
            {{ block.name }}
          </h2>
        </div>
        <div class="d-flex align-items-center">
          <span
            class="dot"
            :class="{
              'bg-success': block.visible,
              'bg-danger': !block.visible,
            }"
          ></span>
          <button
            type="button"
            class="btn-icon btnEdit"
            @click="editBlock(block.id)"
          >
            <FontAwesomeIcon icon="fa-light fa-pen" />
          </button>
          <button type="button" class="btn-icon block__grip">
            <FontAwesomeIcon icon="fa-light fa-grip-lines-vertical" />
          </button>
        </div>
      </div>
      <div class="widget-content">
        <VueDraggableNext
          :animation="200"
          :delay="200"
          :delay-on-touch-only="true"
          :list="block.registrationFields"
          handle=".field__grip"
          @change="sortField"
        >
          <template v-for="field in block.registrationFields" :key="field.id">
            <RegistrationEditorFieldComponent
              :field="field"
              visibleFieldName="visible"
              labelFieldName="label"
              @edit="editField"
            />
          </template>
        </VueDraggableNext>
      </div>
    </div>
  </VueDraggableNext>
</template>

<script>
import RegistrationEditorFieldComponent from "@/components/registration/editor/RegistrationEditorFieldComponent.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "RegistrationEditorTabComponent",
  components: {
    FontAwesomeIcon,
    VueDraggableNext,
    RegistrationEditorFieldComponent,
  },
  props: {
    tab: {
      type: Object,
      required: true,
    },
  },
  emits: ["sort", "edit"],
  methods: {
    sortBlock(event) {
      this.$emit("sort", { type: "block", event: event });
    },

    sortField(event) {
      this.$emit("sort", { type: "field", event: event });
    },

    editField(id) {
      this.$emit("edit", { type: "field", id: id });
    },

    editBlock(id) {
      this.$emit("edit", { type: "block", id: id });
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  &__start {
    display: flex;
    align-items: center;
  }

  &__grip {
    color: $m-color_6;
    cursor: grab !important;
  }
}
</style>
