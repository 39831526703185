<template>
  <div class="registration">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <RegistrationInformationsComponent :registration="registration" />
        </div>
        <div class="col-lg-8">
          <div class="loader-container">
            <RegistrationTabsComponent :tabs="tabs" :key="current" />
            <template v-for="tab in tabs" :key="tab.code">
              <template v-if="tab.id === current">
                <template v-for="block in tab.blocks" :key="block.code">
                  <div class="widget">
                    <div class="widget-heading">
                      <h2 class="textStyle">
                        <FontAwesomeIcon
                          v-if="block.icon"
                          :icon="'fa-light fa-' + block.icon"
                          class="me-1"
                        />
                        {{ block.label }}
                      </h2>
                    </div>
                    <div class="widget-content">
                      <div
                        v-if="block.help"
                        class="alert alert-light-warning"
                        v-html="block.help"
                      ></div>
                      <RegistrationQuoteComponent
                        v-if="block.code === 'quote-quote'"
                      />
                      <RegistrationDocumentsComponent
                        v-else-if="block.code === 'quote-documents'"
                      />
                      <FormComponent
                        v-else-if="block.models"
                        :models="block.models"
                        :hideSubmit="true"
                        :keepHideValue="true"
                        :key="key"
                        @selected="selected"
                      />
                    </div>
                  </div>
                </template>
              </template>
            </template>
            <RegistrationControlsComponent
              :tabs="tabs"
              :current="current"
              @save="save"
              @next="next"
              @prev="prev"
            />
          </div>
          <BlockLoader :loading="loading" position="fixed" />
        </div>
        <div class="col-lg-2">
          <div class="widget">
            <div class="widget-heading">
              <h2>Actions à réaliser</h2>
            </div>
            <div class="widget-content"></div>
          </div>
          <RegistrationHelpComponent :tabs="tabs" :current="current" />
          <RegistrationStepsComponent :steps="steps" />
        </div>
      </div>
    </div>
  </div>
  <RegistrationLoginComponent @loginSuccess="loginSuccess" />
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useMainStore } from "@/store/main/main";
import FormComponent from "@/components/FormComponent.vue";
import RegistrationTabsComponent from "@/components/registration/RegistrationTabsComponent.vue";
import RegistrationQuoteComponent from "@/components/registration/RegistrationQuoteComponent.vue";
import RegistrationDocumentsComponent from "@/components/registration/RegistrationDocumentsComponent.vue";
import RegistrationControlsComponent from "@/components/registration/RegistrationControlsComponent.vue";
import RegistrationStepsComponent from "@/components/registration/RegistrationStepsComponent.vue";
import { registrationManager } from "@/services/registration/registration-manager";
import RegistrationHelpComponent from "@/components/registration/RegistrationHelpComponent.vue";
import { formValidator } from "@/services/form/form-validator";
import { formManager } from "@/services/form/form-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import RegistrationInformationsComponent from "@/components/registration/RegistrationInformationsComponent.vue";
import RegistrationLoginComponent from "@/components/registration/RegistrationLoginComponent.vue";
import { useUserStore } from "@/store/user/user";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "PreRegistrationSheetView",
  components: {
    RegistrationLoginComponent,
    RegistrationInformationsComponent,
    BlockLoader,
    FontAwesomeIcon,
    RegistrationHelpComponent,
    RegistrationStepsComponent,
    RegistrationControlsComponent,
    RegistrationDocumentsComponent,
    RegistrationQuoteComponent,
    RegistrationTabsComponent,
    FormComponent,
  },
  data() {
    return {
      tabs: [],
      steps: [],
      registration: {},
      current: parseInt(this.$route.params.tab),
      alerts: [],
      valid: true,
      loading: false,
      key: 0,
    };
  },
  watch: {
    $route() {
      this.current = parseInt(this.$route.params.tab);
    },
  },
  computed: {
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  mounted() {
    this.loading = true;
    this.init();
    this.setAppLoading(false);
  },
  methods: {
    init(fill = null) {
      this.getRegistration(fill);
    },

    setBreadcrumbTitle() {
      window.localStorage.setItem(
        "studea-pageTitle",
        JSON.stringify({
          name: "TrainingPreRegistration",
          title:
            this.$t("page.pre_registration") +
            " | " +
            this.registration?.group?.name,
        }),
      );
    },

    getRegistration(fill = null) {
      registrationManager
        .form(this.$route.params.group, this.$route.params.registration)
        .then((res) => {
          if (res && !res.hasError) {
            this.tabs = res.tabs;
            this.steps = res.steps;
            this.registration = res.registration;

            this.setBreadcrumbTitle();

            if (!this.getCurrentTab()) {
              this.current = this.tabs[0].id;
            }

            this.fillUser(fill);

            this.$router.push({
              name: "TrainingPreRegistration",
              params: { tab: this.current, group: this.$route.params.group },
            });
          }
          this.loading = false;
        });
    },

    fillUser(fill) {
      if (this.tabs[0].id === parseInt(this.$route.params.tab)) {
        const tab = this.tabs[0];
        let block;
        let fieldsToFill = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
        };

        switch (fill) {
          case "apprentice":
            block = tab.blocks.find(
              (block) => block.code === "apprentice-personal-informations",
            );
            break;
          case "company":
            block = tab.blocks.find(
              (block) => block.code === "apprentice-host-company",
            );
            fieldsToFill = {
              alreadyFoundCompany: true,
              temporaryCompanyContactLastName: this.user.lastName,
              temporaryCompanyContactFirstName: this.user.firstName,
              temporaryCompanyContactEmail: this.user.email,
            };
            break;
        }

        if (block) {
          Object.entries(fieldsToFill).forEach(([field, objectValue]) => {
            const target = block.models.find(
              (item) => item.vars.name === field,
            );

            if (target && objectValue) {
              target.vars.value = objectValue;
              console.log(target);
              formValidator.validModel(target);
            }
          });
          this.key++;
        }
      }
    },

    save(tab = null, formValidation = false) {
      this.valid = true;
      const ret = this.processStep(formValidation);
      this.loading = true;
      if (this.valid) {
        registrationManager
          .save(
            ret,
            this.$route.params.group,
            this.$route.params.tab,
            this.$route.params.registration,
          )
          .then((res) => {
            notificationManager.showNotification(
              "success",
              "Ok ça a marché BRAVO Edouard :D",
            );
            this.goToTab(
              tab ? tab : this.$route.params.tab,
              res.registrationCryptedId,
            );
            this.loading = false;
          });
      }
    },

    next(tab) {
      this.save(tab, true);
    },

    prev(tab) {
      this.save(tab);
    },

    goToTab(tab, registration = null) {
      const params = { tab: tab, group: this.$route.params.group };
      if (registration) {
        params["registration"] = registration;
      }
      this.$router.push({
        name: "TrainingPreRegistration",
        params: params,
      });
    },

    getCurrentTab() {
      return Object.values(this.tabs).find((tab) => tab.id === this.current);
    },

    processStep(formValidation = true) {
      let ret = [];
      const tab = this.getCurrentTab();

      if (tab && tab.blocks && Object.keys(tab.blocks).length) {
        Object.values(tab.blocks).forEach((block) => {
          if (
            this.valid &&
            block &&
            block.models &&
            Object.keys(block.models).length
          ) {
            const models = block.models;
            let errors = [];
            if (formValidation) {
              errors = formValidator.validForm(models);
              this.valid = !errors.length;
            }
            if (this.valid) {
              ret = { ...ret, ...formManager.processForm(models) };
            }
          }
        });
      }
      return ret;
    },

    selected(model) {
      const fieldName = model.vars.name;
      const fieldPrefix = fieldName.split("_")[0];
      let tabCode;
      let blockCode;
      switch (fieldName) {
        case "hostCompany_inseeCompany":
          tabCode = "tab-company";
          blockCode = "company-informations";
          break;
        case "administratorCompany_inseeCompany":
          tabCode = "tab-company";
          blockCode = "company-head-office";
          break;
      }

      const fieldToSet = {
        siretNumber: "siret",
        companyName: "socialReason",
        nafCode: "mainActivity",
        totalWorkforce: "numberOfEmployees",
        address1: "address",
        addressComplement: "addressComplement",
        addressCity: "addressCity",
      };

      if (tabCode && blockCode && model.vars.value) {
        const values = model.vars.value?.object;
        const tab = this.tabs.find((tab) => tab.code === tabCode);
        const block = tab.blocks.find((block) => block.code === blockCode);
        if (block && block.models && Object.keys(block.models).length) {
          Object.entries(fieldToSet).forEach(([field, objectName]) => {
            const target = block.models.find(
              (item) => item.vars.name === fieldPrefix + "_" + field,
            );
            let objectValue = values[objectName];

            if (field === "addressCity" && objectValue) {
              objectValue = {
                value: objectValue.id,
                data: objectValue.id,
                label: objectValue.name + " (" + objectValue.postCode + ")",
              };
            }

            if (target && objectValue) {
              target.vars.value = objectValue;
              formValidator.validModel(target);
            }
            if (target) {
              target.vars.disabled = true;
            }
          });
        }
      }
    },

    loginSuccess(fill) {
      this.loading = true;
      this.init(fill);
    },

    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.loader-container {
  min-height: 300px;
}
</style>
