<template>
  <div class="dropdown nav-item language-dropdown btn-group d-md-flex d-none">
    <a
      id="ddllang"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      class="btn dropdown-toggle btn-icon-only nav-link"
    >
      <img
        v-if="selectedLang && selectedLang.code"
        :src="'/assets/images/flags/' + selectedLang.code + '.png'"
        class="flag-width"
        alt="flag"
      />
    </a>
    <ul
      class="dropdown-menu"
      :class="{ isNotUser: !isUser }"
      aria-labelledby="ddllang"
    >
      <perfect-scrollbar>
        <li v-for="item in countryList" :key="item.code">
          <a
            href="javascript:;"
            class="dropdown-item d-flex align-items-center"
            :class="{ active: $i18n.locale === item.code }"
            @click.prevent="changeLanguage(item)"
          >
            <img
              :src="'/assets/images/flags/' + item.code + '.png'"
              class="flag-width"
              alt=""
            />
            <span>{{ $t("global.language." + item.name.toLowerCase()) }}</span>
          </a>
        </li>
      </perfect-scrollbar>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useThemeStore } from "@/store/theme/theme";

export default {
  name: "SelectLanguageComponent",
  props: {
    isUser: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedLang: {},
    };
  },
  computed: {
    ...mapState(useThemeStore, {
      countryList: (store) => store.countryList,
    }),
  },
  watch: {},
  mounted() {
    this.selectedLang = window.$appSetting.toggleLanguage();
  },
  methods: {
    changeLanguage(item) {
      this.toggleLocal(item.code);
      this.selectedLang = item;
      window.$appSetting.toggleLanguage(item);
      this.$i18n.locale = item.code;
      location.reload();
    },

    ...mapActions(useThemeStore, { toggleLocal: "toggleLocal" }),
  },
};
</script>

<style lang="scss" scoped></style>
