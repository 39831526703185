<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-7 col-xl-9">
        <TrainingCourseListBlockComponent :adminButton="true" />
      </div>
      <div class="col-lg-5 col-xl-3 order-1 order-lg-0">
        <div class="widget" v-if="isStudeaManager">
          <div class="widget-content border-none flex-column">
            <router-link
              v-tooltip="{
                title: $t('studea_manager.document.shared_document_list'),
              }"
              :to="{ name: 'studeaManagerSharedDocumentList' }"
              class="btn btn-success btnStyle w-100"
              v-access:[permissions]="{
                access: accessManager().DELETE,
                code: studeaManagerManager().STUDEA_MANAGER_DOCUMENTS_UPLOAD,
              }"
              >{{ $t("studea_manager.document.shared_document_list") }}
            </router-link>
            <router-link
              :to="{ name: 'trainingCourseDuplicateView' }"
              class="btn btn-success btnStyle w-100 mt-2"
              v-access:[permissions]="{
                access: accessManager().ALL,
                code: studeaManagerManager()
                  .STUDEA_MANAGER_DUPLICATE_QUESTIONNAIRE,
              }"
              >{{ $t("studea_manager.duplication") }}
            </router-link>
            <router-link
              v-if="questionnaireConfig.displayIncidentSheetButton"
              :to="{ name: 'studeaManagerIncidentSheetList' }"
              class="btn btn-success btnStyle w-100 mt-2"
              v-access:[permissions]="{
                access: accessManager().ALL,
                code: studeaManagerManager()
                  .STUDEA_MANAGER_QUESTIONNAIRES_INCIDENT,
              }"
              >{{ $t("studea_manager.incident_sheet.list") }}
            </router-link>
            <router-link
              v-access:[permissions]="{
                access: accessManager().VIEW,
                code: studeaManagerManager().STUDEA_MANAGER_DRIVER_LICENCE,
              }"
              :to="{ name: 'driverLicenceList' }"
              class="btn btn-success btnStyle w-100 mt-2"
              >{{ $t("dashboard.driverLicence.config") }}
            </router-link>
          </div>
        </div>
        <QuestionnaireToDoBlockComponent v-if="!isStudeaManager" />
        <div v-for="(statistic, index) in statistics" :key="index">
          <IndicatorBlock :data="statistic" @show="show" />
        </div>
      </div>
    </div>
  </div>
  <IndicatorsModalComponent
    :indicator="indicator"
    :opened="!!indicator"
    @hidden="hidden"
  />
</template>

<script>
import TrainingCourseListBlockComponent from "@/components/blocks/TrainingCourseListBlockComponent.vue";
import IndicatorBlock from "@/components/blocks/IndicatorBlock.vue";
import { mapActions, mapState } from "pinia";
import QuestionnaireToDoBlockComponent from "@/components/questionnaire/QuestionnaireToDoBlockComponent.vue";
import { colorManager } from "@/services/platform/color-manager";
import { accessManager } from "@/services/security/access-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";
import { userManager } from "@/services/security/user-manager";
import IndicatorsModalComponent from "@/components/modal/IndicatorsModalComponent.vue";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "TrainingCourseListView",
  components: {
    IndicatorsModalComponent,
    QuestionnaireToDoBlockComponent,
    IndicatorBlock,
    TrainingCourseListBlockComponent,
  },
  data() {
    return {
      isStudeaManager: false,
      statistics: [],
      loading: false,
      indicator: null,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
      user: (store) => store.user,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
    }),
    ...mapState(usePlatformStore, {
      questionnaireConfig: (store) => store.questionnaireConfig,
      design: (store) => store.design,
    }),
  },
  watch: {
    trainingCourses() {
      this.setIsStudeaManager();
      this.getUserPermissions();
    },
  },
  created() {
    this.getTrainingCourses();
  },
  mounted() {
    colorManager.setPlatformStyle(null, this.design);
    this.getStatistic();
    this.getUserPermissions();
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    getTrainingCourses() {
      const payload = sessionStorage.getItem("studea-trainingcourse-list")
        ? JSON.parse(sessionStorage.getItem("studea-trainingcourse-list"))
        : {};

      this.fetchTrainingCourseList(payload, this.design).then(() => {
        this.setAppLoading(false);
      });
    },

    show(indicator) {
      this.indicator = indicator;
    },

    hidden() {
      this.indicator = null;
    },

    setIsStudeaManager() {
      if (this.trainingCourses && Object.values(this.trainingCourses).length) {
        this.isStudeaManager =
          this.trainingCourses.filter((item) => item.isStudeaManager).length >
          1;
      }
    },
    getUserPermissions() {
      if (this.isStudeaManager && !Object.keys(this.permissions).length) {
        userManager.loadPermissions(null, true).then((response) => {
          if (response) {
            this.setUserPermissions(response);
          }
        });
      }
    },

    getStatistic() {
      studeaManagerManager.getStatistic().then((response) => {
        this.statistics = response;
      });
    },

    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseList: "fetchTrainingCourseList",
    }),
    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
    ...mapActions(useUserStore, { setUserPermissions: "setUserPermissions" }),
  },
};
</script>

<style lang="scss" scoped></style>
