<template>
  <div class="card-body">
    <div v-if="search" class="filter">
      <InputTypeComponent :model="searchModel" @update="filter" />
    </div>
    <div class="w-100">
      <div v-if="filteredTriads && Object.keys(filteredTriads).length">
        <div
          v-for="crew in filteredTriads"
          :key="crew.id"
          :class="{
            crew: filteredTriads && Object.values(filteredTriads).length > 1,
          }"
        >
          <div
            class="crew__title"
            v-if="
              filteredTriads &&
              Object.values(filteredTriads).length > 1 &&
              crew.triads &&
              Object.keys(crew.triads).length
            "
          >
            {{ crew.title }}
          </div>
          <div
            class="row"
            v-if="crew.triads && Object.keys(crew.triads).length"
          >
            <div
              v-for="triad in crew.triads.filter(
                (triad) => triad.active === true,
              )"
              :key="triad.id"
              :class="{
                'col-4': lateralTab,
                'col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-sm-6': !lateralTab,
              }"
            >
              <div
                v-if="triad.search === true"
                class="widget apprentice"
                :class="{
                  '-active': this.triad.id && this.triad.id === triad.id,
                  '-incidentSheet': triad.hasIncidentSheet,
                }"
                @click="dashboard(triad.id, trainingCourse.id)"
                :style="
                  'border: 2px solid ' +
                  setTriadCardBackgroundColor(triad) +
                  ';'
                "
              >
                <div
                  v-tooltip="{
                    title:
                      $t('Tutor') +
                      ' : ' +
                      (triad.tutor ? triad.tutor.firstName : '') +
                      ' ' +
                      (triad.tutor ? triad.tutor.lastName : '') +
                      '\n' +
                      $t('Apprentice master') +
                      ' : ' +
                      (triad.apprenticeMaster
                        ? triad.apprenticeMaster.firstName
                        : '') +
                      ' ' +
                      (triad.apprenticeMaster
                        ? triad.apprenticeMaster.lastName
                        : ''),
                  }"
                  class="d-flex align-items-center"
                >
                  <AvatarComponent
                    :disablePicture="true"
                    :user="triad.apprentice"
                    profile="apprentice"
                  />
                  <div class="name">
                    {{
                      triad.apprentice && triad.apprentice.firstName
                        ? triad.apprentice.firstName
                        : ""
                    }}
                    {{
                      triad.apprentice && triad.apprentice.lastName
                        ? triad.apprentice.lastName
                        : ""
                    }}
                  </div>
                </div>
                <div
                  v-if="triad.tutor && user.id === triad.tutor.id"
                  class="tutorIcon"
                  v-tooltip="{
                    title: $t('studea_manager.contract.also_tutor'),
                  }"
                >
                  T
                </div>
                <div
                  v-if="triad.hasIncidentSheet"
                  class="incidentSheet"
                  v-tooltip="{
                    title: $t('studea_manager.contract.incident_sheet'),
                  }"
                >
                  <FontAwesomeIcon icon="fa-light fa-warning" />
                </div>
                <div
                  v-if="triad.contractRuptureDate"
                  class="position-absolute top-1 end-0 pe-2"
                >
                  <FontAwesomeIcon
                    v-tooltip="{
                      title:
                        $t('studea_manager.contract.contract_rupture_date') +
                        format(triad.contractRuptureDate),
                    }"
                    class="text-danger"
                    icon="fa-light fa-link-slash"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>{{ $t("dashboard.no_triad") }}</div>
    </div>
  </div>
</template>

<script>
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "TriadUsersListComponent",
  components: { FontAwesomeIcon, InputTypeComponent, AvatarComponent },
  props: {
    lateralTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    preventClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    trainingCourse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchModel: {
        vars: {
          name: "searchTriad",
          id: "searchTriad",
          block_prefixes: ["", "text"],
          value: "",
          attr: {
            placeholder: "Filtrer",
          },
        },
      },
      filteredTriads: [],
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
    }),
  },
  watch: {
    "trainingCourse.crews"() {
      if (this.trainingCourse && this.trainingCourse.crews) {
        this.filteredTriads = this.trainingCourse.crews.map((crew) => ({
          ...crew,
          triads:
            crew && crew.triads
              ? crew.triads
                  .filter((triad) => triad.active !== 0)
                  .sort((a, b) => b.search - a.search)
              : [],
        }));
      }
    },
  },
  mounted() {
    if (this.trainingCourse && this.trainingCourse.crews) {
      this.filteredTriads = this.trainingCourse.crews.map((crew) => ({
        ...crew,
        triads:
          crew && crew.triads
            ? crew.triads
                .filter((triad) => triad.active !== 0)
                .sort((a, b) => b.search - a.search)
            : [],
      }));
    }
  },

  methods: {
    setTriadCardBackgroundColor(triad) {
      if (
        triad.activeContract &&
        triad.activeContract.contractType &&
        triad.activeContract.contractType.contractTypeColor
      ) {
        return triad.activeContract.contractType.contractTypeColor;
      }
    },

    dashboard(triadId, trainingCourseId) {
      useTriadStore()
        .goDashboard(triadId, trainingCourseId)
        .then(() => {
          this.$emit("triadLoaded");
        });
    },

    filter(keyword) {
      keyword = keyword ? keyword.toLowerCase() : "";
      this.filteredTriads = {};
      if (
        this.trainingCourse &&
        this.trainingCourse.crews &&
        Object.keys(this.trainingCourse.crews).length
      ) {
        Object.values(this.trainingCourse.crews).forEach((crew) => {
          if (crew && crew.triads && Object.keys(crew.triads).length) {
            const triads = Object.values(crew.triads)
              .filter((triad) => {
                return (
                  triad.active !== 0 &&
                  (triad.apprentice.firstName.toLowerCase().includes(keyword) ||
                    triad.apprentice.lastName.toLowerCase().includes(keyword))
                );
              })
              .sort((a, b) => b.search - a.search);

            this.filteredTriads[crew.id] = {
              id: crew.id,
              title: crew.title,
              triads,
            };
          }
        });
      }
    },

    format(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  &.apprentice {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: 65px;

    &.-incidentSheet {
      padding: 10px 45px 10px 10px;
    }

    @include mobile {
      width: 100%;
      margin-right: 0;
    }

    &.-active {
      border-color: $blue;
    }

    &:hover {
      background: $light-blue;
    }

    @include dark-theme {
      color: $white;

      &:hover {
        background: $m-color_9 !important;
      }
    }
  }

  .name {
    margin-left: 5px;
  }

  .card {
    order: 2;

    &.-first {
      order: 1;

      .card-header {
        background: $light-blue;

        @include dark-theme {
          background: $m-color_18;
          color: $white;
        }
      }
    }

    .card-body {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.filter {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #e0e6ed;
}

.crew {
  border-bottom: 1px dashed $m-color_6;
  margin-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
}

.tutorIcon {
  margin-left: 10px;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 6px;
  color: $primary;
  background-color: #f5f5fe;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.incidentSheet {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: $orange;
  margin-left: 10px;
  font-size: 18px;
}
</style>
