<template>
  <div class="widget">
    <div class="widget-content d-flex justify-content-between">
      <button
        @click="goPrevTab"
        :disabled="!prev"
        class="btn blockStyle btn-success"
      >
        <FontAwesomeIcon icon="fa-light fa-chevron-left" />
        {{ $t("registration.prev") }}
      </button>
      <button class="btn btn-success saveBtn btnStyle" @click="save">
        <FontAwesomeIcon icon="fa-light fa-save" class="me-2" />
        Enregistrer
      </button>
      <button
        @click="goNextTab"
        class="btn btn-success blockStyle"
        :disabled="!next"
      >
        {{ $t("registration.next") }}
        <FontAwesomeIcon icon="fa-light fa-chevron-right" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationControlsComponent",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
  emitted: ["save", "next", "prev"],
  data() {
    return {
      index: 0,
      prev: null,
      next: null,
      length: null,
    };
  },
  watch: {
    current() {
      this.init();
    },
    tabs() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setCurrent();
      this.setNext();
      this.setPrev();
    },
    setCurrent() {
      this.index = Object.values(this.tabs).findIndex(
        (tab) => tab.id === this.current,
      );
      this.length = Object.keys(this.tabs).length;
    },

    setNext() {
      this.next = this.findAvailable(this.index, "plus");
    },

    setPrev() {
      this.prev = this.findAvailable(this.index, "minus");
    },

    findAvailable(index, type) {
      let i = type === "plus" ? index + 1 : index - 1;
      if (!this.tabs[i] || i === this.length || i < 0) {
        return null;
      }
      if (this.tabs[i]?.disabled) {
        return this.findAvailable(i, type);
      }
      return this.tabs[i].id;
    },

    goNextTab() {
      if (this.next) {
        this.$emit("next", this.next);
      }
    },

    goPrevTab() {
      if (this.prev) {
        this.$emit("prev", this.prev);
      }
    },

    save() {
      this.$emit("save");
    },
  },
};
</script>

<style lang="scss" scoped>
.quote {
  h6 {
    margin: 15px 0;
  }

  &__part {
    border-bottom: 1px dashed $m_color_6;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
</style>
