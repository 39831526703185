<template>
  <label
    v-if="itemModel.vars.label"
    :class="
      'form-label' +
      (itemModel.vars.label_attr && itemModel.vars.label_attr.class
        ? ' ' + itemModel.vars.label_attr.class
        : '') +
      (itemModel.vars.required ? ' required' : '')
    "
  >
    {{ index ? index + ". " : "" }}{{ itemModel.vars.label
    }}{{ itemModel.vars.required ? "*" : "" }}
    <span
      v-if="itemModel.vars.help && !isMobile()"
      class="form-info ms-1"
      v-tooltip="{ title: itemModel.vars.help }"
    >
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
    </span>
  </label>
  <div v-if="isMobile() && itemModel.vars.help" class="form-info">
    <FontAwesomeIcon icon="fa-light fa-circle-question" />
    {{ itemModel.vars.help }}
  </div>
  <div class="row" v-for="(option, index) in itemModel.vars.value" :key="index">
    <div class="col-6">
      <input
        :id="'label-' + itemModel.vars.id + '-' + index"
        :name="'label-' + itemModel.vars.id + '-' + index"
        v-model="option.label"
        :readonly="itemModel.vars.attr && itemModel.vars.attr.readonly"
        :class="
          itemClass +
          ' ' +
          (itemModel.vars.attr && itemModel.vars.attr.class
            ? itemModel.vars.attr.class
            : '')
        "
        :disabled="itemModel.vars.disabled === true"
        :placeholder="$t('option_label')"
        :required="itemModel.vars.required === true"
        :type="itemModel.vars.block_prefixes[1]"
      />
    </div>
    <div class="col-4">
      <input
        :id="'value-' + itemModel.vars.id + '-' + index"
        :name="'value-' + itemModel.vars.id + '-' + index"
        v-model="option.value"
        :readonly="itemModel.vars.attr && itemModel.vars.attr.readonly"
        :class="
          itemClass +
          ' ' +
          (itemModel.vars.attr && itemModel.vars.attr.class
            ? itemModel.vars.attr.class
            : '')
        "
        :disabled="itemModel.vars.disabled === true"
        :placeholder="$t('option_value')"
        :required="itemModel.vars.required === true"
        :type="itemModel.vars.block_prefixes[1]"
      />
    </div>
    <div class="col-2 text-start">
      <button type="button" class="btn btn-danger" @click="removeOption(index)">
        <FontAwesomeIcon icon="fa-light fa-trash" />
      </button>
      <button
        type="button"
        class="btn btn-success btnStyle ms-2"
        v-if="index + 1 === Object.keys(model.vars.value).length"
        @click="addOption"
      >
        <FontAwesomeIcon icon="fa-light fa-plus" />
      </button>
    </div>
  </div>
  <div v-if="model.vars.error" class="invalid-feedback d-block">
    {{ model.vars.error }}
  </div>
</template>

<script>
import { formValidator } from "@/services/form/form-validator";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ArrayChoicesTypeComponent",
  watch: {
    "model.vars.error"() {
      let errors = [];
      if (this.model.vars.error) {
        errors = [this.model.vars.error];
      }
      this.getClass(errors);
    },
  },
  data() {
    return {
      itemModel: this.model,
      itemClass: "form-control",
    };
  },
  emits: ["input", "draft", "focus", "update", "focused", "save", "inputModel"],
  props: {
    model: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
      required: false,
      default: "fr",
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  mounted() {
    this.process();
  },
  methods: {
    getClass(errors = []) {
      if (this.model.vars.error || errors.length) {
        this.itemClass = "form-control is-invalid";
      } else if (
        !errors.length &&
        !this.model.vars.error &&
        this.model.vars.required
      ) {
        this.itemClass = "form-control is-valid";
      } else {
        this.itemClass = "form-control";
      }
    },
    blur() {
      this.valid(true);
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    input() {
      this.valid();
      this.$emit("update", this.itemModel.vars.value, this.index);
      this.$emit("input", this.itemModel.vars.value, this.index);
      this.$emit("inputModel", this.itemModel);
    },
    change() {
      this.$emit("draft", this.itemModel);
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    focus() {
      this.$emit("focus");
    },
    isMobile() {
      return window.innerWidth < 992;
    },
    valid(blur = false) {
      if (blur) {
        this.$emit("save");
      }
      const errors = formValidator.validModel(this.itemModel);
      this.getClass(errors);
    },

    process() {
      if (!Object.keys(this.model.vars.value).length) {
        this.addOption();
      }
    },

    addOption() {
      this.itemModel.vars.value.push({ label: "", value: "" });
    },

    removeOption(index) {
      this.itemModel.vars.value.splice(index, 1);
    },
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.row + .row {
  margin-top: 10px;
}
</style>
