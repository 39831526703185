<template :key="key">
  <div
    id="form-modal"
    ref="modal"
    aria-hidden="true"
    aria-labelledby="form-modal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title textStyle">
            {{ title ? title : $t("search") }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div v-if="Object.keys(models).length" class="modal-body">
          <FormComponent
            :disableFocusAnimation="true"
            :models="models"
            :keepHideValue="keepHideValue"
            :hideSubmit="hideSubmit"
            :type="type"
            :submitText="submitText"
            @formCancel="cancel"
            @formSubmit="submit"
            @toggle="toggle"
            @update="update"
          >
            <template v-if="$slots.formView" #formView>
              <slot name="formView"></slot>
            </template>
          </FormComponent>
          <div class="text-danger text-center" v-if="errorMessage">
            {{ $t(errorMessage) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent.vue";
import { Modal } from "bootstrap";
import { formManager } from "@/services/form/form-manager";

export default {
  name: "ModalFormComponent",
  data() {
    return {
      modelsItem: [],
      key: 0,
      errorMessage: null,
    };
  },
  props: {
    models: {
      type: [Array, Object],
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    storageKey: {
      type: String,
      required: false,
      default: "",
    },
    keepHideValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    submitText: {
      type: String,
      required: false,
      default: null,
    },
  },
  watch: {
    models() {
      this.key++;
      this.modelsItem = this.models;
      let modal;
      if (this.$refs.modal) {
        modal = new Modal(this.$refs.modal);
      }
      let params = null;
      if (Object.keys(this.modelsItem).length) {
        if (this.storageKey && window.sessionStorage.getItem(this.storageKey)) {
          params = JSON.parse(window.sessionStorage.getItem(this.storageKey));
          Object.entries(params).forEach((param) => {
            if (this.modelsItem[param[0]]) {
              this.modelsItem[param[0]].vars.value = param[1];
              if (
                formManager.isCheckboxType(this.modelsItem[param[0]]) &&
                formManager.isEntityType(this.modelsItem[param[0]]) &&
                this.modelsItem[param[0]].vars.choices.length
              ) {
                this.modelsItem[param[0]].vars.choices.forEach((choice) => {
                  if (param[1].includes(choice.data.id)) {
                    choice.checked = true;
                  }
                });
              }
              if (
                this.modelsItem[param[0]].vars.attr &&
                this.modelsItem[param[0]].vars.attr["data-ajax-load"]
              ) {
                formManager.fieldLoad(
                  this.modelsItem[param[0]],
                  null,
                  param[1],
                );
              }
            }
          });
        }
        if (modal) {
          modal.show();
        }
        this.$emit("opening");
      }
    },
  },
  mounted() {
    if (this.$refs.modal) {
      this.$refs.modal.addEventListener("hidden.bs.modal", this.hidden);
    }
  },
  methods: {
    hidden() {
      this.modelsItem = [];
      this.$emit("hidden");
    },

    toggle() {
      this.$emit("toggle");
    },

    update() {
      this.$emit("update");
    },

    submit() {
      let params = formManager.processForm(this.modelsItem, true);
      if (this.storageKey) {
        window.sessionStorage.setItem(this.storageKey, JSON.stringify(params));
      }
      if (this.checkDateValidation()) {
        params = formManager.getQuery(params);
        this.$emit("search", params);
        this.$emit("submit", this.modelsItem);
        if (this.$refs.modal) {
          const modal = Modal.getOrCreateInstance(this.$refs.modal);
          modal.hide();
        }
      } else {
        this.errorMessage = "error_date";
      }
    },

    checkDateValidation() {
      if (
        this.modelsItem.from &&
        this.modelsItem.to &&
        this.modelsItem.from.vars.value &&
        this.modelsItem.to.vars.value
      ) {
        return (
          new Date(this.modelsItem.from.vars.value) <=
          new Date(this.modelsItem.to.vars.value)
        );
      } else return true;
    },

    cancel() {
      this.errorMessage = null;
      this.modelsItem = [];
      if (this.$refs.modal) {
        const modal = Modal.getOrCreateInstance(this.$refs.modal);
        modal.hide();
      }
      this.$emit("cancel");
    },
  },
  components: { FormComponent },
};
</script>

<style lang="scss" scoped></style>
