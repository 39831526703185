<template>
  <div class="field">
    <div class="field__start">
      <div class="field__label">
        <span
          class="field__icon"
          :class="{ '-visible': field[visibleFieldName] }"
          v-if="field.icon"
        >
          <FontAwesomeIcon :icon="'fa-light fa-' + field.icon" />
        </span>
        {{ field[labelFieldName] }}
      </div>
    </div>
    <div class="field__actions">
      <FontAwesomeIcon
        v-if="field.required"
        class="text-danger me-2"
        icon="fa-solid fa-circle-info"
      />
      <span
        class="dot"
        :class="{
          'bg-success': field[visibleFieldName],
          'bg-danger': !field[visibleFieldName],
        }"
      ></span>
      <button type="button" @click="edit()" class="btn-icon btnEdit">
        <FontAwesomeIcon icon="fa-light fa-pen" />
      </button>
      <button type="button" class="field__grip btn-icon">
        <FontAwesomeIcon icon="fa-light fa-grip-lines-vertical" />
      </button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "RegistrationEditorFieldComponent",
  components: { FontAwesomeIcon },
  props: {
    field: {
      type: Object,
      required: true,
    },
    labelFieldName: {
      type: String,
      required: true,
    },
    visibleFieldName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  emits: ["edit"],
  mounted() {
    this.init();
  },
  methods: {
    init() {},

    edit() {
      this.$emit("edit", this.field.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;

  &:hover {
    background: $m_color_1;
  }

  &__start {
    display: flex;
    align-items: center;
  }

  &__grip {
    cursor: grab !important;
    color: $m_color_6;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__label {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    border-radius: 50%;
    border: 3px solid $white;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    justify-content: center;
    min-width: 30px;
    width: 30px;
    min-height: 30px;
    height: 30px;
    margin-right: 10px;
    position: relative;
    z-index: 1;
    background: $red;
    font-size: 12px;

    svg {
      color: $white;
    }

    &.-visible {
      background: $green;
    }
  }
}

.field + .field {
  border-top: 1px dashed $m_color_6;
}
</style>
