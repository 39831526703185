<template>
  <template v-for="choice in choices" :key="choice.value">
    <div
      v-if="!filtered || (choice.checked && filtered)"
      class="checkbox-primary custom-control custom-checkbox"
    >
      <input
        :id="'checkbox-' + itemModel.vars.name + '-' + choice.value"
        v-model="choice.checked"
        :class="
          (itemModel.vars.error
            ? 'custom-control-input is-invalid'
            : 'custom-control-input') +
          (itemModel.vars.attr && itemModel.vars.attr.class
            ? ' ' + itemModel.vars.attr.class
            : '')
        "
        :disabled="itemModel.vars.disabled || choice.disabled"
        :readonly="
          (itemModel.vars.attr && itemModel.vars.attr.readonly) || readonly
        "
        :value="choice.value"
        type="checkbox"
        @change="setChecked"
        @focus="focus"
      />
      <label
        :for="'checkbox-' + itemModel.vars.name + '-' + choice.value"
        :class="'custom-control-label ps-2' + (choice.badge ? ' badged' : '')"
      >
        <span>{{ choice.label }}</span>
        <span v-if="choice.badge" class="badge bg-success mb-1">{{
          choice.badge
        }}</span>
        <span
          class="ms-2"
          v-if="
            model.vars.attr['data-icons'] &&
            choice.value &&
            iconToDisplay(parseInt(choice.value)) &&
            iconToDisplay(parseInt(choice.value)).length
          "
        >
          <span
            v-for="(item, index) in iconToDisplay(parseInt(choice.value))"
            :key="item.id"
            :class="index !== 0 ? 'ms-2' : ''"
          >
            <FontAwesomeIcon
              :icon="'fa-light fa-' + item.icon"
              :class="getColor(item.icon)"
            />
          </span>
        </span>
      </label>
    </div>
  </template>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "CheckboxInputComponent",
  components: { FontAwesomeIcon },
  data() {
    return {
      itemModel: this.model,
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    filtered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["setChecked", "focus"],
  methods: {
    setChecked() {
      this.$emit("setChecked");
    },

    focus() {
      this.$emit("focus");
    },

    iconToDisplay(id) {
      return this.model.vars.attr["data-icons"].filter(
        (element) => element.id === id,
      );
    },

    getColor(icon) {
      const textDanger = ["link-slash"];
      let color;
      if (textDanger.includes(icon)) {
        color = "text-danger";
      }
      return color;
    },
  },
};
</script>

<style scoped lang="scss">
.badged {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.is-invalid + label {
  &:before {
    border-color: $red;
  }
}
</style>
