<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8">
        <div class="widget p-3 d-flex">
          <button
            class="btn-icon me-2 backBtn"
            v-tooltip="{ title: $t('back') }"
            @click="$router.back()"
          >
            <FontAwesomeIcon icon="fa-light fa-arrow-left" />
          </button>
          <h2 class="textStyle">{{ pageTitle }}</h2>
        </div>
        <template v-if="tabs && Object.keys(tabs).length">
          <RegistrationTabsComponent
            :tabs="tabs"
            :edition="true"
            :current="current"
            @goToTab="goToTab"
            @edit="editTab"
            @sort="sort"
          />
          <RegistrationEditorTabComponent
            v-if="current"
            :tab="current"
            @sort="sort"
            @edit="edit"
          />
        </template>
      </div>
      <div class="col-lg-4">
        <div class="sticky">
          <div class="widget">
            <div class="widget-heading justify-content-start">
              <h2 class="textStyle">{{ $t("registration.steps") }}</h2>
            </div>
            <div class="widget-content">
              <VueDraggableNext
                :animation="200"
                :delay="200"
                :delay-on-touch-only="true"
                :list="steps"
                handle=".field__grip"
                @change="sortStep"
              >
                <RegistrationEditorFieldComponent
                  v-for="step in steps"
                  :key="step.id"
                  :field="step"
                  labelFieldName="name"
                  visibleFieldName="active"
                  @edit="editStep"
                />
              </VueDraggableNext>
            </div>
          </div>
        </div>
      </div>
      <BlockLoader :loading="loading" position="fixed" />
    </div>
  </div>
  <ModalFormComponent
    :title="modalTitle"
    :models="models"
    :keepHideValue="true"
    @submit="submit"
    @hidden="cancel"
    @cancel="cancel"
  />
</template>

<script>
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { registrationManager } from "@/services/registration/registration-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { stringManager } from "@/services/utilities/string-manager";
import RegistrationTabsComponent from "@/components/registration/RegistrationTabsComponent.vue";
import RegistrationEditorTabComponent from "@/components/registration/editor/RegistrationEditorTabComponent.vue";
import RegistrationEditorFieldComponent from "@/components/registration/editor/RegistrationEditorFieldComponent.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "PreRegistrationEditView",
  components: {
    VueDraggableNext,
    RegistrationEditorFieldComponent,
    RegistrationEditorTabComponent,
    RegistrationTabsComponent,
    BlockLoader,
    ModalFormComponent,
  },
  data() {
    return {
      tabs: [],
      steps: [],
      pageTitle: "",
      current: null,
      models: [],
      modalTitle: "",
      modalType: "",
      modalId: null,
      loading: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setAppLoading(false);
      this.getDatas();
    },

    goToTab(id) {
      this.current = this.tabs.find((tab) => tab.id === id);
    },

    sortStep(event) {
      this.sort({ event: event, type: "step" });
    },

    sort(payload) {
      const alias = "registration-" + payload.type;
      if (payload.event.moved) {
        this.loading = true;
        const id = payload.event.moved.element.id;
        const position = payload.event.moved.newIndex;
        adminManager.sort(alias, id, position).then(() => {
          this.init();
        });
      }
    },

    editTab(id) {
      this.edit({ type: "tab", id: id });
    },

    editStep(id) {
      this.edit({ type: "step", id: id });
    },

    edit(payload) {
      const alias = "registration-" + payload.type;
      const tradPrefix = stringManager.toCamelCase(alias);
      this.modalTitle = this.$tc("global.edit.title", 1, {
        name: this.$tc("admin." + tradPrefix + ".title", 1).toLowerCase(),
      });
      this.modalType = payload.type;
      this.form(payload.id, alias);
    },

    form(id, alias) {
      this.modalId = id;
      adminManager.form(alias, id, {}, true).then((res) => {
        this.models = res;
      });
    },

    getDatas() {
      this.loading = true;
      registrationManager.getVersion(this.$route.params.id).then((res) => {
        this.tabs = res.registrationTabs;
        this.steps = res.registrationSteps;
        this.loading = false;
        this.pageTitle =
          res.name +
          " (" +
          dateFormatter.format(res.versionDate, "short") +
          ")";
        this.current = this.tabs[this.current ? this.current.position : 0];
      });
    },

    submit(models) {
      const alias = "registration-" + this.modalType;
      const tradPrefix = stringManager.toCamelCase(alias);
      const formData = formManager.processForm(models);
      this.loading = true;
      adminManager.put(alias, true, formData, this.modalId).then((res) => {
        if (res && !res.errors) {
          this.init();
          notificationManager.showNotification(
            "success",
            this.$tc("global.edit.success", 1, {
              name: this.$tc("admin." + tradPrefix + ".title", 1),
            }),
          );
        }
      });
    },

    cancel() {
      this.models = [];
      this.modalId = null;
    },
    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.row {
  min-height: 300px;
}
</style>
