import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";

const URL_REGISTRATION = "/app/registration/";
const URL_VERSION = "/app/admin/registration-data-version/";
const URL_VERSION_ACTIVE = "/app/admin/registration-data-version/active/";

class RegistrationManager {
  REGISTRATION_DATA_VERSION_CERFA_PARAM = 1;
  REGISTRATION_DATA_VERSION_FIELD_CONFIG = 2;
  REGISTRATION_DATA_VERSION_STEP = 3;

  form(groupCryptedId, registrationCryptedId) {
    return apiConnection
      .get(
        URL_REGISTRATION +
          groupCryptedId +
          "/form" +
          (registrationCryptedId ? "/" + registrationCryptedId : ""),
      )
      .then((response) => response);
  }

  search() {
    return apiConnection
      .get(URL_REGISTRATION + "search")
      .then((response) => response);
  }

  getVersion(id) {
    return apiConnection
      .get(URL_VERSION + "get/" + id)
      .then((response) => response);
  }

  list(params) {
    return apiConnection
      .get(URL_REGISTRATION + "list", params)
      .then((response) => response);
  }

  getRegistration(id) {
    return apiConnection
      .get(URL_REGISTRATION + "get/" + id)
      .then((response) => response);
  }

  version(params, id) {
    let url = URL_VERSION + "post";
    if (id) {
      url = URL_VERSION + "put" + "/" + id;
    }
    return (
      id ? apiConnection.put(url, params) : apiConnection.post(url, params)
    ).then((res) => {
      if (res && !res.hasError) {
        notificationManager.showNotification(
          "success",
          i18n.global.tc("global." + (id ? "edit" : "add") + ".success", 2, {
            name: i18n.global.tc("admin.registrationDataVersion.title", 1),
          }),
        );
      } else {
        notificationManager.showNotification(
          "error",
          i18n.global.t("signed_error"),
        );
      }
    });
  }

  active(id) {
    return apiConnection.put(URL_VERSION_ACTIVE + id).then((res) => {
      if (res && res.success) {
        notificationManager.showNotification(
          "success",
          i18n.global.tc("global.edit.success", 2, {
            name: i18n.global.tc("admin.registrationDataVersion.title", 1),
          }),
        );
      } else {
        notificationManager.showNotification(
          "error",
          i18n.global.t("signed_error"),
        );
      }
    });
  }

  save(params, groupCryptedId, tabId, registrationCryptedId) {
    let url =
      URL_REGISTRATION +
      groupCryptedId +
      (registrationCryptedId ? "/put/" : "/post/") +
      tabId +
      (registrationCryptedId ? "/" + registrationCryptedId : "");
    return (
      registrationCryptedId
        ? apiConnection.put(url, params)
        : apiConnection.post(url, params)
    ).then((res) => res);
  }
}

export const registrationManager = new RegistrationManager();
