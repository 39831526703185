<template>
  <div class="list">
    <div class="list__row" v-for="item in list" :key="item.id">
      <div class="list__title">
        {{ item.name }}
        {{
          $tc("registration.createdAt", {
            date: dateFormatter().format(item.versionDate, "short"),
          })
        }}
      </div>
      <div class="list__actions">
        <button
          class="dot"
          :class="{
            'bg-success': item.active,
            'bg-danger': !item.active,
          }"
          @click="active(item.id)"
          v-tooltip="{
            title: $tc('global.active.title', 2, {
              name: this.$tc(
                'admin.registrationDataVersion.title',
                1,
              ).toLowerCase(),
            }),
          }"
        ></button>
        <button
          class="btn-icon editBtn"
          @click="addEdit(item.id)"
          v-tooltip="{
            title: this.$tc('global.edit.title', 2, {
              name: this.$tc(
                'admin.registrationDataVersion.title',
                1,
              ).toLowerCase(),
            }),
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-pen" />
        </button>
        <router-link
          :to="{
            name: 'adminModuleRegistrationSheetEdit',
            params: { id: item.id },
          }"
          class="ms-1 list__button"
          v-tooltip="{
            title: this.$t('admin.registrationDataConfiguration'),
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-cog" />
        </router-link>
        <button
          class="btn-icon ms-1 removeBtn"
          @click="remove(item.id)"
          v-tooltip="{
            title: $tc('global.delete.title', 2, {
              name: this.$tc(
                'admin.registrationDataVersion.title',
                1,
              ).toLowerCase(),
            }),
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-trash" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "RegistrationDashboardVersionListComponent",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  emits: ["addEdit", "active", "remove"],
  methods: {
    dateFormatter() {
      return dateFormatter;
    },

    addEdit(id = null) {
      this.$emit("addEdit", id);
    },

    remove(id = null) {
      this.$emit("remove", id);
    },

    active(id) {
      this.$emit("active", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__title {
    @include up-to-md {
      width: 100%;
      text-align: left;
    }
  }

  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__row {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    @include up-to-md {
      display: block;
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__actions {
    text-align: right;
    display: flex;
    align-items: center;

    @include up-to-md {
      margin-left: 20px;
    }
  }
}

.dot {
  border: none;
}
</style>
