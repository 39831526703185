<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">{{ $t("registration.devices") }}</h2>
    </div>
    <div class="widget-content position-relative">
      <div class="panel-body simple-pills tabs">
        <ul id="deviceTab" class="nav nav-pills" role="tablist">
          <li
            class="nav-item"
            role="presentation"
            v-for="device in devices"
            :key="device.id"
          >
            <a
              :id="'tab-' + device.id + '-tab'"
              :ref="'ref-' + device.id"
              :aria-controls="'tab-' + device.id"
              :aria-selected="deviceId && deviceId === device.id"
              :class="
                'btnTab nav-link mb-2 text-center bgStyle h-100' +
                (deviceId && deviceId === device.id ? ' active' : '')
              "
              :href="'#tab-' + device.id"
              data-bs-toggle="pill"
              role="tab"
              @click="toggleTab(device.id)"
            >
              <span class="d-flex">
                {{ device.name }}
                <button
                  type="button"
                  class="btnEdit btn-icon ms-1"
                  @click="addEditDevice($event, device.id)"
                  v-tooltip="{
                    title: this.$tc('global.edit.title', 1, {
                      name: this.$tc(
                        'admin.registrationDevice.title',
                        1,
                      ).toLowerCase(),
                    }),
                  }"
                >
                  <FontAwesomeIcon icon="fa-light fa-pen" />
                </button>
                <button
                  type="button"
                  class="btnRemove btn-icon"
                  @click="removeDevice($event, device.id)"
                  v-tooltip="{
                    title: this.$tc('global.delete.title', 1, {
                      name: this.$tc(
                        'admin.registrationDevice.title',
                        1,
                      ).toLowerCase(),
                    }),
                  }"
                >
                  <FontAwesomeIcon icon="fa-light fa-trash" />
                </button>
              </span>
            </a>
          </li>
          <li class="nav-item">
            <button
              class="btnAdd nav-link mb-2 text-center h-100"
              type="button"
              @click="addEditDevice($event)"
              v-tooltip="{
                title: this.$tc('global.add.title', 1, {
                  name: this.$tc(
                    'admin.registrationDevice.title',
                    1,
                  ).toLowerCase(),
                }),
              }"
            >
              <FontAwesomeIcon class="iconStyle" icon="fa-light fa-plus" />
            </button>
          </li>
        </ul>
        <div id="deviceTabContent" class="tab-content">
          <div
            v-for="device in devices"
            :key="device.id"
            :id="'tab-' + device.id"
            :aria-labelledby="'tab-' + device.id + '-tab'"
            :class="
              'tab-pane fade' +
              (deviceId && deviceId === device.id ? ' show active' : '')
            "
            role="tabpanel"
          >
            <div class="d-flex align-items-center justify-content-between mb-2">
              <h2>Versions</h2>
              <button
                class="btnAddVersion btn-icon"
                type="button"
                @click="addEdit()"
                v-tooltip="{
                  title: this.$tc('global.add.title', 2, {
                    name: this.$tc(
                      'admin.registrationDataVersion.title',
                      1,
                    ).toLowerCase(),
                  }),
                }"
              >
                <FontAwesomeIcon class="iconStyle" icon="fa-light fa-plus" />
              </button>
            </div>
            <div
              v-if="
                !device.registrationDataVersions ||
                !Object.keys(device.registrationDataVersions).length
              "
            >
              {{
                this.$tc("global.empty.title", 0, {
                  name1: this.$tc(
                    "admin.registrationDataVersion.title",
                    1,
                  ).toLowerCase(),
                  name2: this.$tc(
                    "admin.registrationDevice.title",
                    1,
                  ).toLowerCase(),
                })
              }}
            </div>
            <RegistrationDashboardVersionListComponent
              :list="device.registrationDataVersions"
              @active="active"
              @addEdit="addEdit"
              @remove="remove"
            />
          </div>
        </div>
      </div>
      <BlockLoader :loading="loading" />
    </div>
  </div>
</template>

<script>
import RegistrationDashboardVersionListComponent from "@/components/registration/dashboard/RegistrationDashboardVersionListComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "RegistrationDashboardDeviceTabsComponent",
  components: {
    FontAwesomeIcon,
    BlockLoader,
    RegistrationDashboardVersionListComponent,
  },
  props: {
    devices: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    deviceId: {
      type: [Number, null],
      required: false,
      default: null,
    },
  },
  emits: [
    "active",
    "toggleTab",
    "addEditDevice",
    "addEdit",
    "remove",
    "removeDevice",
  ],
  methods: {
    active(payload) {
      this.$emit("active", payload);
    },

    toggleTab(code) {
      this.$emit("toggleTab", code);
    },

    addEditDevice(e, id = null) {
      e.stopPropagation();
      e.preventDefault();
      this.$emit("addEditDevice", id);
    },

    addEdit(payload) {
      this.$emit("addEdit", payload);
    },

    remove(payload) {
      this.$emit("remove", payload);
    },

    removeDevice(e, payload) {
      e.stopPropagation();
      e.preventDefault();
      this.$emit("removeDevice", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-icon {
  svg {
    font-size: 16px !important;
  }
}

.active {
  .btn-icon {
    svg {
      color: $white;
    }
  }
}
</style>
